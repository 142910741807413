import React, { useContext, useEffect, useState } from 'react'
import { AccountContext } from '../components/Account/Account'

import BTRLogo from '../images/BTRHireClientTransparent.png'
import HomePage from './HomePage'
import Login from '../components/Account/Login'
import SetUpPassword from '../components/Account/SetUpPassword'
import ResetPassword from '../components/Account/ResetPassword'
import ForgotPassword from '../components/Account/ForgotPassword'
import { Card } from '@mui/material'

function LoginPage() {
	const { getSession, loginStage, setLoginStage } = useContext(AccountContext)
	const [user, setUser] = useState()

	useEffect(() => {
		getSession()
			.then(() => {
				setLoginStage('LoggedIn')
			})
			.catch((e) => {
				console.error('error', e)
			})
		// eslint-disable-next-line
	}, [getSession])

	if (loginStage === 'LoggedIn') {
		return <HomePage setLoginStage={setLoginStage} />
	}

	return (
		<Card
			style={{
				width: 450,
				padding: '10px 0 30px 0',
				background: 'white',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
				boxSizing: 'border-box',
			}}
		>
			<div>
				<img src={BTRLogo} alt='' style={{ width: 350 }} />

				{loginStage === 'LoggedOut' && <Login setUser={setUser} />}

				{loginStage === 'SetUpPassword' && <SetUpPassword user={user} />}

				{loginStage === 'ForceResetPassword' && (
					<ResetPassword
						user={user}
						statusMessage='Your password must be reset. A verification code has been sent to your email. If this code has expired, please click the Forgot Password? button on the main page to receive a new code.'
					/>
				)}

				{loginStage === 'ResetPassword' && (
					<ResetPassword user={user} statusMessage='A verification code has been sent to your email.' />
				)}

				{loginStage === 'ForgotPassword' && <ForgotPassword setUser={setUser} />}
			</div>
		</Card>
	)
}

export default LoginPage
