import { TextField, Select, MenuItem, FormControl, FormHelperText, Typography, Paper } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import React, { useContext, useState } from 'react'

import { API } from 'aws-amplify'
import { accessCodeByCodeString } from '../../../graphql/queries'
import { TestContext } from '../TestContext'
import { createDBAccessCode, updateDBClient } from '../../../lib/database'
import FormButtons from '../../FormButtons'

function CreateCodeMenu(props) {
	const client = useSelector((state) => state.client)

	const { test } = useContext(TestContext)

	const [codeStatus, setCodeStatus] = useState('open')
	const [code, setCode] = useState('')
	const [allowedUses, setAllowedUses] = useState('')

	const [errMessage, setErrMessage] = useState('')
	const [status, setStatus] = useState('')

	const dispatch = useDispatch()

	// Searches the codes in the database to see if the new access code is already in use
	async function checkForDuplicate() {
		const codesData = await API.graphql({
			query: accessCodeByCodeString,
			variables: { code: code },
			limit: 10,
		})

		return codesData.data.accessCodeByCodeString.items.length > 0
	}

	async function verifyValidCode() {
		const numAllowed = parseInt(allowedUses)

		if (code.length === 0) {
			setErrMessage("Missing 'Code' field.")
			setStatus('FAILURE-CODE')
			return false
		}

		if (allowedUses.length === 0) {
			setErrMessage("Missing 'Allowed Uses' field.")
			setStatus('FAILURE-ALLOWEDUSES')
			return false
		}

		if (
			isNaN(numAllowed) ||
			numAllowed <= 0 ||
			allowedUses.includes('.') ||
			/[a-zA-z]/.test(allowedUses) ||
			/[$-/:-?{-~!"^_`[\]]/.test(allowedUses) ||
			allowedUses.includes(' ')
		) {
			setErrMessage("'Allowed Uses' field must be an integer greater than 0.")
			setStatus('FAILURE-ALLOWEDUSES')
			return false
		}

		if ((await checkForDuplicate()) === true) {
			setErrMessage('A code with that access code already exists.')
			setStatus('FAILURE-DUPLICATECODE')
			return false
		}

		/*	Commenting this section out right now as we have changed to allow them to create however many they want but
			will notify when they go over their allotted uses.
		*/
		// Check to make sure user has enough remaining uses to create the new code
		// if (client.role !== 'MASTER') {
		// 	if (client.remainingCodeUses - numAllowed < 0) {
		// 		setStatus('FAILURE-ALLOWEDUSES')
		// 		setErrMessage('You do not have the required code uses to create this code.')
		// 		return false
		// 	}
		// }

		return true
	}

	async function submitNewCode() {
		const numAllowed = parseInt(allowedUses)

		if ((await verifyValidCode()) === false) return

		const newCode = {
			code: code,
			numAllowed: numAllowed,
			timesUsed: 0,
			testID: test.id,
			status: codeStatus,
			createdBy: {
				id: client.id,
				name: client.name,
			},
		}

		try {
			const createdCode = await createDBAccessCode(newCode, dispatch)

			// Update the information
			const updatedClient = {
				id: client.id,
				// remainingCodeUses: client.remainingCodeUses - numAllowed, /* Commenting this out right now for same reason as above comment */
				codeList: [...client.codeList, createdCode.id],
			}

			await updateDBClient(updatedClient, dispatch)
		} catch (err) {
			setStatus('FAILURE')
			setErrMessage('Failed in uploading to server.')
			console.error(err)
			return
		}

		setStatus('SUCCESS')
		setErrMessage('')
	}

	return (
		<Paper className='form-container' elevation={16}>
			<div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginBottom: 30 }}>
				<span style={{ fontWeight: 'var(--bold)', color: '#001940', fontSize: 18 }}>Create Code</span>
			</div>

			<div style={{ display: 'flex', flexDirection: 'column', gap: 15 }}>
				<TextField
					style={{ width: 300, height: 30 }}
					value={code}
					error={status.includes('CODE')}
					helperText='Code'
					data-cy='code-input'
					InputLabelProps={{
						style: {
							fontSize: 14,
						},
					}}
					onChange={(e) => {
						setCode(e.target.value)
						if (status !== '') {
							setErrMessage('')
							setStatus('')
						}
					}}
				/>

				<TextField
					style={{ width: 300, marginTop: 15 }}
					helperText='Number of Allowed Uses'
					data-cy='allowed-uses-input'
					value={allowedUses}
					error={status.includes('ALLOWEDUSES')}
					InputLabelProps={{
						style: {
							fontSize: 14,
						},
					}}
					onChange={(e) => {
						setAllowedUses(e.target.value)
						if (status !== '') {
							setErrMessage('')
							setStatus('')
						}
					}}
				/>

				<FormControl sx={{ m: 1 }} error={status.includes('CSTATUS')}>
					<Select
						value={codeStatus}
						data-cy='status-input'
						style={{
							width: 300,
							textAlign: 'start',
							fontSize: 14,
							fontWeight: 'var(--bold)',
							color: codeStatus === 'open' ? 'rgb(50, 168, 70)' : 'rgb(209, 51, 19)',
						}}
						onChange={(e) => {
							setCodeStatus(e.target.value)
							if (status !== '') {
								setErrMessage('')
								setStatus('')
							}
						}}
					>
						<MenuItem
							value={'open'}
							style={{ color: 'rgb(50, 168, 70)', fontWeight: 'var(--bold)' }}
							data-cy='status-open-option'
						>
							Open
						</MenuItem>
						<MenuItem
							value={'closed'}
							style={{ color: 'rgb(209, 51, 19)', fontWeight: 'var(--bold)' }}
							data-cy='status-closed-option'
						>
							Closed
						</MenuItem>
					</Select>
					<FormHelperText> Status </FormHelperText>
				</FormControl>
			</div>

			<FormButtons onSubmit={() => submitNewCode()} onCancel={() => props.onClose()} />

			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					flexDirection: 'column',
					maxWidth: 300,
					marginBottom: 20,
				}}
			>
				{status.includes('FAILURE') > 0 && (
					<Typography style={{ fontSize: 14, color: 'rgb(209, 51, 19)', paddingTop: '20px' }}>{errMessage}</Typography>
				)}
				{status === 'SUCCESS' && (
					<Typography style={{ fontSize: 14, color: 'green', paddingTop: '20px', maxWidth: 300 }}>Success!</Typography>
				)}
			</div>
		</Paper>
	)
}

export default CreateCodeMenu
