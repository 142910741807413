import React, { useContext, useEffect } from 'react'
import { CandidatesContext } from '../Candidates/CandidatesContext'
import NewCandidateTable from '../CandidateTable'
import styles from './Overview.module.css'
import { Paper } from '@mui/material'

function RecentCandidatesTable() {
	const { rows, setRows, origRows } = useContext(CandidatesContext)

	// Rows are not filtered in this component so we can just set rows as a copy of origRows
	useEffect(() => {
		// origRows updated
		setRows([...origRows])
		// eslint-disable-next-line
	}, [origRows])

	return (
		<Paper className={styles['container']}>
			<span className={styles['label']} style={{ marginBottom: 2 }}>
				Recent Candidates
			</span>
			<span className={styles['container-description']} style={{ marginBottom: 20 }}>
				Candidates from the past 7 days
			</span>
			<NewCandidateTable rows={rows} origRows={origRows} setRows={setRows} />
		</Paper>
	)
}

export default RecentCandidatesTable
