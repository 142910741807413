import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import ErrorPage from '../ErrorPage'
import ErrorOutline from '@mui/icons-material/ErrorOutline'
import { TestContext } from './TestContext'
import Navigation from './Navigation'
import Overview from './Overview/Overview'
import Candidates from './Candidates/Candidates'
import { CandidatesContextWrapper } from './Candidates/CandidatesContext'
import ManageAccess from './ManageAccess/ManageAccess'
import ExecutiveReport from './ExecutiveReport/ExecutiveReport'
import TestSelector from './TestSelector/TestSelector'

function TestSection() {
	const testList = useSelector((state) => state.testList)
	const { test, setTest, tab } = useContext(TestContext)

	if (!testList || !testList[0]) {
		return (
			<ErrorPage
				message="It looks like you don't have access to any tests yet. Please check back when we have created a test for you."
				icon={<ErrorOutline style={{ height: '8vh', width: '8vh', marginTop: '5vh' }} />}
			/>
		)
	}

	return (
		<div style={{ width: '95%', height: '95%', display: 'flex', flexDirection: 'column' }}>
			<div style={{ display: 'flex', gap: 40 }}>
				<TestSelector items={testList} labelAttribute={'testName'} currItem={test} setCurrItem={setTest} />
				<Navigation />
			</div>

			<div
				data-cy='test-section-content-container'
				style={{
					height: '100%',
					width: '100%',
					display: 'flex',
					flexDirection: 'column',
					marginTop: 25,
					overflowY: 'auto',
					overflowX: 'hidden',
					gap: 25,
					paddingRight: 15,
					position: 'relative',
					paddingBottom: 10,
					boxSizing: 'border-box',
				}}
			>
				{tab === 'Overview' && (
					<CandidatesContextWrapper
						filterFunction={(candidate) => {
							const currTime = new Date().getTime()
							const candidateApplicationTime = new Date(candidate.createdAt).getTime()
							return candidateApplicationTime > currTime - 604800000
						}}
					>
						<Overview />
					</CandidatesContextWrapper>
				)}
				{tab === 'Candidates' && (
					<CandidatesContextWrapper
						filterFunction={() => {
							return true
						}}
					>
						<Candidates />
					</CandidatesContextWrapper>
				)}
				{tab === 'Manage Access' && <ManageAccess />}
				{tab === 'Executive Report' && (
					<CandidatesContextWrapper
						filterFunction={(candidate) => {
							return candidate.shortlisted
						}}
					>
						<ExecutiveReport />
					</CandidatesContextWrapper>
				)}
			</div>
		</div>
	)
}

export default TestSection
