import React, { useEffect, useState } from 'react'
import { Button, LinearProgress, Paper, Typography } from '@mui/material'

import ReplayIcon from '@mui/icons-material/Replay'
import VolumeUpIcon from '@mui/icons-material/VolumeUp'
import Play from '@mui/icons-material/PlayCircle'
import Pause from '@mui/icons-material/PauseCircle'
import CloseIcon from '@mui/icons-material/Close'

import { getVoiceRecordingFile } from '../../../../functions/DBFunctions'
import { convertToPlayerTimeString } from '../../../../functions/CalculationFunctions'
import { CircularProgress } from '@mui/material'

function VoiceRecordingView(props) {
	const [playing, setPlaying] = useState(false)
	const [player, setPlayer] = useState()
	const [volume, setVolume] = useState(0.5)
	const [progress, setProgress] = useState(0)
	const [currPlayTime, setCurrPlayTime] = useState('00:00 / 00:00')
	const [error, setError] = useState(false)
	const [loading, setLoading] = useState(true)
	const [xOffset, setXOffset] = useState(0)

	useEffect(() => {
		if (!player && !playing) {
			getVoiceRecordingFile(props.userID + 'VoiceRecording').then((result) => {
				if (result === 'ERROR') {
					setError(true)
				} else {
					setPlayer(new Audio(result))
				}
				setLoading(false)
			})
		}

		return () => {
			if (player && player.currentTime > 0) {
				player.pause()
			}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [player])

	useEffect(() => {
		if (playing) {
			const interval = setInterval(() => {
				setProgress((player.currentTime / player.duration) * 100)
				setCurrPlayTime(
					convertToPlayerTimeString(player.currentTime * 1000) + ' / ' + convertToPlayerTimeString(player.duration * 1000)
				)

				if (player.currentTime === player.duration) {
					setPlaying(false)
				}
			}, 100)
			return () => {
				clearInterval(interval) // Stops from ticking when the component unmounts
			}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [playing])

	if (loading) {
		return (
			<Paper
				elevation={12}
				style={{
					height: 180,
					width: 500,
					background: 'white',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<CircularProgress style={{ height: 50, width: 50, color: '#001940' }} />
			</Paper>
		)
	}

	if (error) {
		return (
			<Paper style={{ height: 180, width: 500, background: 'white' }} elevation={12}>
				<div style={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
					<Button
						style={{ color: '#001940', margin: '10px 5px 0 0' }}
						onClick={() => {
							props.setShowVoice(false)
						}}
					>
						<CloseIcon style={{ height: 30, width: 30 }} />
					</Button>
				</div>

				<Typography style={{ fontSize: 18, marginTop: 25 }}>A voice recording does not exist for this applicant.</Typography>
			</Paper>
		)
	}

	return (
		<Paper style={{ height: 180, width: 500, background: 'white' }} elevation={12}>
			<div style={{ display: 'flex', justifyContent: 'end' }}>
				<Button
					style={{ color: '#001940', margin: '10px 10px 0 0' }}
					onClick={() => {
						props.setShowVoice(false)
					}}
				>
					<CloseIcon style={{ height: 30, width: 30 }} />
				</Button>
			</div>

			<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
				<Button
					style={{ height: 40, background: 'white', color: '#001940' }}
					onClick={() => {
						player.pause()
						player.currentTime = 0
						setCurrPlayTime('00:00 / ' + convertToPlayerTimeString(player.duration * 1000))
						setProgress(0)
						setPlaying(false)
					}}
				>
					<ReplayIcon style={{ height: 40, width: 40 }} />
				</Button>

				{playing === false ? (
					<Button
						data-cy='play-recording-button'
						style={{ height: 40, background: 'white', color: '#001940' }}
						onClick={() => {
							setPlaying(true)
							player.play()
						}}
					>
						<Play style={{ height: 40, width: 40 }} />
					</Button>
				) : (
					<Button
						data-cy='pause-recording-button'
						style={{ height: 40, background: 'white', color: '#001940' }}
						onClick={() => {
							setPlaying(false)
							player.pause()
						}}
					>
						<Pause style={{ height: 40, width: 40 }} />
					</Button>
				)}

				<LinearProgress
					style={{ width: 200, height: 20 }}
					variant='determinate'
					ref={(el) => {
						if (!el) return
						setXOffset(el.getBoundingClientRect().x)
					}}
					value={progress}
					onClick={(e) => {
						let position = e.pageX - xOffset
						if (position < 0) position = 0
						if (position > 200) position = 200

						player.currentTime = (position / 200) * player.duration
						setProgress((player.currentTime / player.duration) * 100)
						setCurrPlayTime(
							convertToPlayerTimeString(player.currentTime * 1000) + ' / ' + convertToPlayerTimeString(player.duration * 1000)
						)
					}}
				/>

				<Button style={{ color: 'black', fontSize: 20 }} disabled>
					{currPlayTime}
				</Button>
			</div>

			<div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', marginTop: 20 }}>
				<VolumeUpIcon style={{ color: '#001940', height: 30, width: 30 }} />

				<input
					type='range'
					min={0}
					max={1}
					step={0.02}
					value={volume}
					style={{ accentColor: '#001940' }}
					onChange={(event) => {
						setVolume(event.target.valueAsNumber)
						player.volume = event.target.valueAsNumber
					}}
				/>
			</div>
		</Paper>
	)
}

export default VoiceRecordingView
