import { Typography } from '@mui/material'

function ErrorPage(props) {
	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
				height: '100%',
				width: '100%',
				background: 'white',
			}}
		>
			<Typography style={{ width: '50%', fontSize: 20 }}>{props.message}</Typography>
			{props.icon}
		</div>
	)
}

export default ErrorPage
