import './BannerMenuButton.css'

function BannerMenuButton(props) {
	let className = 'menu-button'
	if (props.customclasses) {
		className += ' ' + props.customclasses
	}

	return (
		<button className={className} {...props}>
			{props.children}
		</button>
	)
}

export default BannerMenuButton
