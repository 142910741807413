import { styled } from '@mui/material/styles'
import { Button } from '@mui/material'

export const DarkButton = styled(Button)`
	background-color: #001940;
	color: white;
	text-transform: none;
	font-size: 14px;

	&:hover {
		background-color: rgba(0, 25, 64, 0.9); /* Adjust the opacity value as desired */
	}

	&:disabled {
		opacity: 0.5;
		background-color: #001940;
		color: white;
	}
`
