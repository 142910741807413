import React, { useContext, useRef, useState } from 'react'

import { onTabPress } from '../../../../AdminSection/TestBuilder/Functions'
import { useDispatch, useSelector } from 'react-redux'
import { CandidatesContext } from '../../CandidatesContext'
import { updateDBUser } from '../../../../../lib/database'
import FormButtons from '../../../../FormButtons'

function AddCommentMenu({ onClose }) {
	const client = useSelector((state) => state.client)

	const { candidate } = useContext(CandidatesContext)

	const [comment, setComment] = useState('')
	const commentRef = useRef()

	const dispatch = useDispatch()

	async function onSubmit() {
		const newComment = {
			createdBy: {
				id: client.id,
				name: client.name,
			},
			comment: comment,
			date: new Date().toISOString(),
		}

		const updatedUser = { id: candidate.userID, testID: candidate.testID }
		if (candidate.clientComments) {
			updatedUser.clientComments = [...candidate.clientComments, newComment]
		} else {
			updatedUser.clientComments = [newComment]
		}

		await updateDBUser(updatedUser, dispatch)

		onClose()
	}

	return (
		<div
			style={{
				padding: '20px 40px',
				background: 'white',
				borderRadius: 10,
				position: 'fixed',
				top: '50%',
				left: '50%',
				boxShadow: 'rgb(0, 0, 0, 0.3) 0 0 2px 2px',
				display: 'flex',
				flexDirection: 'column',
				transform: 'translate(-50%, -45%)',
			}}
		>
			<div style={{ display: 'flex', justifyContent: 'space-between' }}>
				<span style={{ fontWeight: 'var(--bold)', color: '#001940', fontSize: 16 }}>{client.name}</span>
				{/* <BannerMenuButton onClick={() => onClose()}>
					<CloseIcon style={{ height: 25, width: 25, color: '#001940' }} />
				</BannerMenuButton> */}
			</div>

			<textarea
				style={{
					width: 350,
					height: 100,
					resize: 'none',
					marginTop: 20,
					fontFamily: 'Poppins',
					padding: 5,
					boxShadow: 'none',
				}}
				maxLength={500}
				ref={commentRef}
				autoFocus
				onKeyDown={(e) => {
					if (e.key === 'Tab') {
						onTabPress(commentRef, e)
					}
				}}
				value={comment}
				onChange={(e) => setComment(e.target.value)}
			/>

			<FormButtons onCancel={onClose} onSubmit={onSubmit} />
		</div>
	)
}

export default AddCommentMenu
