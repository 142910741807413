import React, { useContext, useEffect } from 'react'
import Breakdown from '../Candidates/Breakdown/Breakdown'
import ResponsesView from '../Candidates/Breakdown/ResponsesView'
import { CandidatesContext } from '../Candidates/CandidatesContext'
import NewCandidateTable from '../CandidateTable'
import styles from './ExecutiveReport.module.css'
import ReportGraph from './ReportGraph'
import { Paper } from '@mui/material'

function ExecutiveReport() {
	const { view, rows, setRows, origRows } = useContext(CandidatesContext)

	// Rows are not filtered in this component so we can just set rows as a copy of origRows
	useEffect(() => {
		setRows([...origRows])
		// eslint-disable-next-line
	}, [origRows])

	return (
		<>
			{view === 'table' && (
				<>
					<Paper
						style={{
							background: 'white',
							display: 'flex',
							padding: 20,
							flexDirection: 'column',
							alignItems: 'flex-start',
						}}
					>
						<span className={styles['container-label']}>Shortlisted Graph</span>
						<p className={styles['container-description']} style={{ paddingBottom: 20 }}>
							Qualified candidates should fall within the blue area.
						</p>
						<ReportGraph shortlistedCandidates={rows} />
					</Paper>

					<Paper
						style={{
							background: 'white',
							display: 'flex',
							padding: 20,
							flexDirection: 'column',
							alignItems: 'flex-start',
							maxHeight: '65vh',
						}}
					>
						<span className={styles['container-label']} style={{ paddingBottom: 20 }}>
							Shortlisted Table
						</span>
						<NewCandidateTable rows={rows} origRows={origRows} setRows={setRows} />
					</Paper>
				</>
			)}

			{view === 'candidate' && <Breakdown />}

			{view === 'responses' && <ResponsesView />}
		</>
	)
}

export default ExecutiveReport
