import { Typography, TextField, Divider, Slider, Box } from '@mui/material'
import { useEffect, useRef, useState } from 'react'

import Keywords from '../ScoringTags/Keywords'
import { onTabPress } from '../Functions'

function LastQuestionPage(props) {
	const [minutesAllowed, setMinutesAllowed] = useState(10)
	const ref = useRef()

	function findDefaultValue() {
		for (const question of props.testInfo.questions) {
			if (question.section === 'LastQuestion') {
				return question.questionText
			}
		}
		return ''
	}

	function updateQuestionText(value) {
		let newState = Object.assign({}, props.testInfo)
		for (let question of newState.questions) {
			if (question.section === 'LastQuestion') {
				question.questionText = value
				break
			}
		}
		props.setTestInfo(newState)
	}

	function updateTimeAllowed(minutes) {
		let newState = Object.assign({}, props.testInfo)
		for (let question of newState.questions) {
			if (question.section === 'LastQuestion') {
				question.helperText = '' + 60 * 1000 * minutes
				break
			}
		}

		props.setTestInfo(newState)
	}

	useEffect(() => {
		for (const question of props.testInfo.questions) {
			if (question.section === 'LastQuestion') {
				setMinutesAllowed(parseInt(question.helperText) / 60 / 1000)
				break
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<div style={{ display: 'flex', height: '100%', width: '100%', alignItems: 'center', flexDirection: 'column' }}>
			<div style={{ width: 600, display: 'flex', flexDirection: 'column', marginTop: 30 }}>
				<Typography textAlign='left'>
					This is the last question the candidate will see on the test. It is meant to assess the individual's ability to
					concisely present their thoughts in a timed situation. We continue to assess their written communication skills under a
					pressurized environment.
				</Typography>
				<Divider sx={{ margin: '30px 0', width: '100%' }} />
				<TextField
					variant='outlined'
					inputProps={{ style: { fontSize: 14 } }}
					InputLabelProps={{ style: { fontSize: 14 } }}
					label='Question Text'
					multiline
					style={{ width: 600 }}
					defaultValue={findDefaultValue()}
					onBlur={(e) => {
						updateQuestionText(e.target.value)
					}}
					ref={ref}
					onKeyDown={(e) => {
						if (e.key === 'Tab') {
							onTabPress(ref, e)
						}
					}}
				/>
				<Box display='flex' justifyContent='flex-start' alignItems='center' marginTop={2}>
					<Typography style={{ textAlign: 'start', fontSize: 14 }}>Minutes Allowed: </Typography>
					<Typography style={{ textAlign: 'start', fontSize: 14, fontWeight: 'bold', marginLeft: 20 }}>
						{minutesAllowed}
					</Typography>
					<Slider
						min={5}
						max={20}
						step={1}
						// valueLabelDisplay='on'
						style={{ accentColor: '#001940', width: 200, marginLeft: 20 }}
						value={minutesAllowed}
						aria-label='minutes-allowed-label'
						onChange={(e) => {
							setMinutesAllowed(e.target.value)
						}}
						onBlur={(e) => {
							updateTimeAllowed(e.target.value)
						}}
					/>
				</Box>
				<Keywords testInfo={props.testInfo} setTestInfo={props.setTestInfo} questionID='LastQuestion' />
			</div>
		</div>
	)
}

export default LastQuestionPage
