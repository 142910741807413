import { useContext, useEffect, useState, useRef } from 'react'

import './NavBar.css'
import { AccountContext } from '../Account/Account'
import NavBarTab from './NavBarTab'

// import People from '../People'
import Admin from '../AdminSection/Admin'
// import ExecutiveReportView from '../ExecutiveReportSection/ExecutiveReportView'
import { TestContextWrapper } from '../TestSection/TestContext'
import TestSection from '../TestSection/TestSection'

import LogoutIcon from '@mui/icons-material/Logout'
import AssignmentIcon from '@mui/icons-material/Assignment'
// import PeopleIcon from '@mui/icons-material/People'
// import LockIcon from '@mui/icons-material/Lock'
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'
import BannerMenuButton from '../MenuButton/BannerMenuButton'
import Dashboard from '../Dashboard/Dashboard'

import BTRLogo from '../../images/BTRTransparentDark.png'

const iconStyle = {
	width: 25,
	height: 25,
}

const tabs = [
	{ index: 1, label: 'Dashboard', element: <Dashboard />, icon: <img alt='' src={BTRLogo} style={{ height: 23, width: 25 }} /> },
	// { index: 2, label: 'Candidates', element: <People />, icon: <PeopleIcon style={iconStyle} /> },
	// { index: 3, label: 'Reports', element: <ExecutiveReportView />, icon: <AssignmentIcon style={iconStyle} /> },
	{
		index: 2,
		label: 'Tests',
		element: (
			<TestContextWrapper>
				<TestSection />
			</TestContextWrapper>
		),
		icon: <AssignmentIcon style={iconStyle} data-cy='tests-section' />,
	},
	{ index: 3, label: 'Admin', element: <Admin />, icon: <AdminPanelSettingsIcon style={iconStyle} />, requiredRole: 'MASTER' },
]

// Keeps track of how many times the mouse has entered and left the navbar so that we can decide if we need to expand it.
// For example, if the user enters the navbar, clicks a new tab, and then leaves, we no longer have to expand the bar.
// But if the user enters the navbar, is unsure of which tab they want, and mouse doesn't leave the navbar, then we can reveal the labels.
let hovering = false

function NavBar(props) {
	const [elementIndex, setElementIndex] = useState(1)
	const [showLabel, setShowLabel] = useState(false)
	const { logout } = useContext(AccountContext)
	const timeoutRef = useRef(null)

	function getStyle(index) {
		let style = {
			display: 'flex',
			justifyContent: 'start',
			color: 'white',
			padding: '10px 10px 10px 20px',
			borderRadius: '0 6px 6px 0',
			height: 50,
		}

		if (elementIndex === index) style.background = '#244B87'

		if (props.disabled) {
			delete style.background
			style.color = 'gray'
		}

		return style
	}

	function updateActiveTab(index, element) {
		setElementIndex(index)
		props.setCurrElement(element)
	}

	useEffect(() => {
		return () => {
			if (timeoutRef.current) {
				clearTimeout(timeoutRef.current)
			}
		}
	}, [])

	return (
		<div
			className='NavigationBar'
			style={showLabel ? { borderRadius: '6px' } : { width: 70 }}
			onMouseEnter={() => {
				hovering = true
				timeoutRef.current = setTimeout(() => {
					// User's mouse has entered the navbar but not left it for over 1 second.
					if (hovering) {
						console.log('here')
						setShowLabel(true)
					}
				}, 1000)
				// setShowLabel(true)
			}}
			onMouseLeave={() => {
				hovering = false
				setShowLabel(false)
			}}
		>
			<div style={{ display: 'flex', flexDirection: 'column', marginTop: 20, gap: 10 }}>
				{tabs.map((tab) => {
					// Hide certain tabs, ie Admin, if user does not have required access level
					if (tab.requiredRole && tab.requiredRole !== props.role) return <div key={tab.index} />

					return (
						<NavBarTab
							disabled={props.disabled}
							key={tab.index}
							onClick={() => {
								updateActiveTab(tab.index, tab.element)
								setShowLabel(false)
							}}
							style={getStyle(tab.index)}
							icon={tab.icon}
							label={tab.label}
							showLabel={showLabel}
						/>
					)
				})}
			</div>

			<BannerMenuButton
				style={{
					color: 'white',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					textTransform: 'none',
					fontSize: 16,
					margin: '20px 20px 20px 15px',
					paddingRight: 5,
					position: 'absolute',
					bottom: 5,
				}}
				data-cy='logout-button'
				onClick={() => {
					logout()
				}}
			>
				<LogoutIcon style={{ color: 'white', width: 25, height: 25 }} />
				{showLabel && <div className='button-text'> Logout </div>}
			</BannerMenuButton>
		</div>
	)
}

export default NavBar
