import { useState, useEffect } from 'react'

import { Button, TextField } from '@mui/material'
import { DarkButton } from '../../../Button/DarkButton'
import { addScoringTag, deleteScoringTag } from '../Functions'

import CloseIcon from '@mui/icons-material/Close'

function createKeywordsTag(keywords) {
	const keywordsTag = {
		tag: 'HitWords',
		maxScore: 0,
		dataMapping: 'PositionRequirements',
		data: '',
	}

	// Add keywords if they are not empty
	for (const keyword of keywords) {
		if (keyword.length > 0) {
			if (keywordsTag.data.length !== 0) {
				keywordsTag.data += ','
			}
			keywordsTag.data += keyword
			keywordsTag.maxScore += 0.5
		}
	}

	return keywordsTag
}

// Adds keyword to list of keywords at "index"
function addKeyword(keyword, index, oldKeywords, setKeywords) {
	let newKeywords = [...oldKeywords]

	newKeywords[index] = keyword
	setKeywords(newKeywords)
}

// Deletes keyword from list of keywords at "index"
function deleteKeyword(index, oldKeywords, setKeywords) {
	let newKeywords = []

	// Copy all keywords except the one you just deleted
	for (let i = 0; i < oldKeywords.length; i++) {
		if (index !== i) {
			newKeywords.push(oldKeywords[i])
		}
	}

	setKeywords(newKeywords)
}

function Keywords(props) {
	const [keywords, setKeywords] = useState([])

	// Gets default value when loading page
	useEffect(() => {
		// Searches for question
		for (const question of props.testInfo.questions) {
			if (question.id === props.questionID) {
				// Searchs for HitWords scoring tag
				for (const scoringTag of question.scoringTags) {
					if (scoringTag.tag === 'HitWords') {
						setKeywords(scoringTag.data.split(','))
						return
					}
				}

				// Did not find the tag. Reset keywords to empty array
				setKeywords([])
				return
			}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.questionID])

	// Updates the scoring tag when keywords changes
	useEffect(() => {
		// Delete old keywordsTag
		deleteScoringTag(props.questionID, 'HitWords', props.testInfo, props.setTestInfo)

		const keywordsTag = createKeywordsTag(keywords)

		// If there was a non empty keyword, add the new keywordsTag
		if (keywordsTag.data.length > 0) {
			addScoringTag(props.questionID, keywordsTag, props.testInfo, props.setTestInfo)
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [keywords])

	return (
		<div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'start', marginTop: 10 }}>
			{keywords.map((keyword, index) => {
				return (
					<div key={index} style={{ display: 'flex', alignItems: 'center' }}>
						<TextField
							size='small'
							label={`Keyword ${index + 1}`}
							inputProps={{ style: { fontSize: 14 } }}
							InputLabelProps={{ style: { fontSize: 14 } }}
							value={keyword}
							onChange={(e) => {
								addKeyword(e.target.value, index, keywords, setKeywords)
							}}
						/>
						<Button
							style={{ marginTop: 10 }}
							onClick={() => {
								deleteKeyword(index, keywords, setKeywords)
							}}
						>
							<CloseIcon style={{ height: 25, width: 25, color: 'rgb(60, 60, 60)' }} />
						</Button>
					</div>
				)
			})}

			{keywords.length < 5 && (
				<DarkButton
					style={{ marginTop: 16 }}
					onClick={() => {
						setKeywords([...keywords, ''])
					}}
				>
					Add Keyword
				</DarkButton>
			)}
		</div>
	)
}

export default Keywords
