import { TableCell, TableBody, TableRow, Paper, Table, TableHead, TableContainer, Card } from '@mui/material'
import { parseAWSDateTimeWithYear } from '../../functions/CalculationFunctions'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Typography } from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'
import { CircularProgress } from '@mui/material'
import { API } from 'aws-amplify'

import { updateClient } from '../../graphql/mutations'
import { getClient } from '../../graphql/queries'
import { changeClientList } from '../../actions'

function createData(testName, dateCreated, id, AWSDate) {
	return { testName, dateCreated, id, AWSDate }
}

function createTableData(tests) {
	let rows = []

	for (const test of tests) {
		rows.push(createData(test.testName, parseAWSDateTimeWithYear(test.createdAt), test.id, test.createdAt))
	}

	rows.sort((a, b) => {
		if (a.createdAt < b.createdAt) return -1
		else return 1
	})

	return rows
}

function getRelevantClients(clientList, testID) {
	let relClients = []

	for (const client of clientList) {
		if (!client.testList.includes(testID)) relClients.push(client)
	}

	return relClients
}

function AssignTest() {
	const [rows, setRows] = useState([])
	const [showCandidates, setShowCandidates] = useState(false)
	const [currTestID, setCurrTestID] = useState(null)
	const [loading, setLoading] = useState(false)
	const [relevantClients, setRelevantClients] = useState([]) // Clients that do not already have 'currTestID' in their testList

	const clientList = useSelector((state) => state.clientList)
	const tests = useSelector((state) => state.testList)
	const dispatch = useDispatch()

	useEffect(() => {
		setRows(createTableData(tests))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<div style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center' }}>
			<div style={{ width: '70%', marginTop: 20, overflow: 'auto' }}>
				<TableContainer component={Paper}>
					<Table sx={{ width: '100%' }} aria-label='simple table'>
						<TableHead>
							<TableRow>
								<TableCell align='center'>
									<b> Name </b>
								</TableCell>
								<TableCell align='center'>
									<b> Date Created </b>
								</TableCell>
								<TableCell align='center'> </TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{rows.map((row, index) => (
								<TableRow key={index}>
									<TableCell align='center' sx={{ padding: '5px 0' }}>
										{row.testName}
									</TableCell>
									<TableCell align='center' sx={{ padding: '5px 0' }}>
										{row.dateCreated}
									</TableCell>
									<TableCell align='center' sx={{ padding: '5px 0' }}>
										<Button
											variant='text'
											sx={{ color: '#001940', textTransform: 'none' }}
											// style={{ background: '#001940', color: 'white', textTransform: 'none' }}
											onClick={() => {
												setCurrTestID(row.id)
												setRelevantClients(getRelevantClients(clientList, row.id))
												setShowCandidates(true)
											}}
										>
											Assign
										</Button>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</div>

			{showCandidates && (
				<Card
					elevation={5}
					style={{
						maxHeight: 500,
						width: 600,
						position: 'fixed',
						transform: 'translate(-50%, -50%)',
						left: 'calc(50% + 175px)',
						top: '50%',
						// boxShadow: '0 0 2px 2px gray',
						background: 'white',
						overflow: 'auto',
					}}
				>
					<div style={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
						<Button
							style={{ color: '#001940', margin: '10px 5px 0 0' }}
							onClick={() => {
								setCurrTestID(null)
								setShowCandidates(false)
								setRelevantClients([])
							}}
						>
							<CloseIcon style={{ height: 30, width: 30 }} />
						</Button>
					</div>

					<div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: 20 }}>
						{relevantClients.length > 0 ? (
							relevantClients.map((client) => {
								return (
									<Button
										key={client.id}
										style={{
											background: '#001940',
											color: 'white',
											textTransform: 'none',
											width: '50%',
											marginBottom: 10,
										}}
										onClick={async () => {
											setLoading(true)

											try {
												const clientData = await API.graphql({
													query: getClient,
													variables: { id: client.id },
													authMode: 'AMAZON_COGNITO_USER_POOLS',
												})
												const updatedClient = clientData.data.getClient

												delete updatedClient._deleted
												delete updatedClient.updatedAt
												delete updatedClient._lastChangedAt
												delete updatedClient.createdAt
												delete updatedClient.Test

												updatedClient.testList.push(currTestID)

												// Update client in database
												await API.graphql({
													query: updateClient,
													variables: { input: updatedClient },
													authMode: 'AMAZON_COGNITO_USER_POOLS',
												})

												// Sync change in Redux
												let newClientList = [...clientList]
												for (let client of newClientList) {
													if (client.id === updatedClient.id) {
														client.testList = [...updatedClient.testList]
													}
												}
												dispatch(changeClientList(newClientList))
												setRelevantClients(getRelevantClients(newClientList, currTestID))
											} catch (e) {
												console.error('Error adding test to user.', e)
											}

											setLoading(false)
										}}
										disabled={loading}
									>
										{loading ? <CircularProgress style={{ color: 'white', height: 20, width: 20 }} /> : client.name}
									</Button>
								)
							})
						) : (
							// No Relevant clients
							<Typography style={{ marginTop: 10, marginBottom: 20 }}>
								There are no clients that do not already have access to this test.
							</Typography>
						)}
					</div>
				</Card>
			)}
		</div>
	)
}

export default AssignTest
