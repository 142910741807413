import React, { useState, useContext } from 'react'
import { TextField, Button, Typography } from '@mui/material'

import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { AccountContext } from './Account'
import styles from './Account.module.css'
import BlueButton from '../Button/BlueButton'

const helperTextStyle = { fontSize: 14 }

function Login({ setUser }) {
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [showPasswordType, setShowPasswordType] = useState('password')
	const [loginError, setLoginError] = useState(false)

	const { authenticate, setLoginStage } = useContext(AccountContext)

	const onLogin = (email, password) => {
		authenticate(email, password, setUser)
			.then(() => {
				setLoginStage('LoggedIn')
			})
			.catch((err) => {
				console.error(err)
				setLoginError(true)
			})
	}

	return (
		<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
			<Typography className={styles['instruction-text']} style={{ width: 310 }}>
				Welcome to the BTR Hire Client portal. Please login below.
			</Typography>
			<TextField
				className={styles['text-input']}
				data-cy='username-input'
				value={email}
				error={loginError}
				onChange={(e) => {
					setEmail(e.target.value)
					if (loginError) {
						setLoginError(false)
					}
				}}
				helperText='Email'
				FormHelperTextProps={{ style: helperTextStyle }}
			/>
			<TextField
				className={styles['text-input']}
				data-cy='password-input'
				value={password}
				error={loginError}
				InputProps={{
					endAdornment: (
						<Button
							style={{ color: 'rgb(40, 40, 40)' }}
							onClick={() => {
								setShowPasswordType(showPasswordType === 'password' ? '' : 'password')
							}}
						>
							{showPasswordType === 'password' ? <VisibilityIcon /> : <VisibilityOffIcon />}
						</Button>
					),
				}}
				type={showPasswordType}
				onChange={(e) => {
					setPassword(e.target.value)
					if (loginError) {
						setLoginError(false)
					}
				}}
				onKeyDown={(e) => {
					if (e.key === 'Enter') {
						onLogin(email, password)
					}
				}}
				helperText='Password'
				FormHelperTextProps={{ style: helperTextStyle }}
			/>

			{loginError ? (
				<Typography style={{ color: 'red', fontSize: 14, paddingBottom: 20 }}>Invalid username/password.</Typography>
			) : (
				<div />
			)}

			<div style={{ display: 'flex', flexDirection: 'column' }}>
				<BlueButton
					className={styles['submit-button']}
					data-cy='login-button'
					onClick={() => {
						onLogin(email, password)
					}}
				>
					Login
				</BlueButton>
				<Button
					variant='text'
					className={styles['small-button']}
					onClick={() => {
						setLoginStage('ForgotPassword')
					}}
				>
					Forgot Password?
				</Button>
			</div>
		</div>
	)
}

export default Login
