// Adds a 'th', 'st', 'nd', or 'rd' to a number
export function addEndingToNumber(number) {
	let rem = number % 10
	let numString = number.toString()

	if (rem === 1) {
		numString += 'st'
	} else if (rem === 2) {
		numString += 'nd'
	} else if (rem === 3) {
		numString += 'rd'
	} else {
		numString += 'th'
	}

	return numString
}

export function getDetailedSectionName(section) {
	switch (section) {
		case 'CaseStudy':
			return 'Case Study'
		case 'KnowledgeAssessment':
			return 'Knowledge Assessment'
		case 'YourExperience':
			return 'Real Estate Experience'
		case 'LastQuestion':
			return 'Timed Question'
		case 'AboutYourself':
			return 'Initial Question'
		default:
			return section
	}
}
