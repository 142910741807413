import React, { useState } from 'react'
import CodeTable from './CodeTable'
import Info from './Info'
import CreateCodeMenu from './CreateCodeMenu'

function ManageAccess() {
	const [showCreateMenu, setShowCreateMenu] = useState(false)

	return (
		<>
			<Info />
			<CodeTable setShowCreateMenu={setShowCreateMenu} />
			{showCreateMenu && (
				<CreateCodeMenu
					onClose={() => {
						setShowCreateMenu(false)
					}}
				/>
			)}
		</>
	)
}

export default ManageAccess
