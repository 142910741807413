import { TextField } from '@mui/material'
import { useEffect, useState } from 'react'
import { addScoringTag, deleteScoringTag } from '../Functions'

function verifyValueIsNumber(value) {
	if (!/^[0-9]*$/.test(value)) {
		return false
	}

	return true
}

function TargetYears(props) {
	const [years, setYears] = useState('')

	// Checks if a value has been set already
	useEffect(() => {
		// Searches for question
		for (const question of props.testInfo.questions) {
			if (question.id === props.questionID) {
				// Searchs for HitWords scoring tag
				for (const scoringTag of question.scoringTags) {
					if (scoringTag.tag === 'TargetYears') {
						setYears(scoringTag.data)
						return
					}
				}

				// Did not find the tag. Reset keywords to empty array
				setYears('')
				return
			}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.questionID])

	return (
		<TextField
			style={{ width: 160 }}
			label='Enter Target Years'
			variant='outlined'
			size='small'
			inputProps={{ style: { fontSize: 14 }, maxLength: 2 }}
			InputLabelProps={{ style: { fontSize: 14 } }}
			value={years}
			onChange={(e) => {
				if (!verifyValueIsNumber(e.target.value)) {
					return
				}

				setYears(e.target.value)

				deleteScoringTag(props.questionID, 'TargetYears', props.testInfo, props.setTestInfo)

				// Just want to delete the tag in the case that it is blank
				if (e.target.value.length === 0) return

				const newTag = {
					tag: 'TargetYears',
					data: e.target.value.toString(),
					dataMapping: 'PositionRequirements',
					maxScore: 3,
				}

				addScoringTag(props.questionID, newTag, props.testInfo, props.setTestInfo)
			}}
		/>
	)
}

export default TargetYears
