import { combineReducers } from 'redux'

const changeClient = (state = {}, action) => {
	switch (action.type) {
		case 'CHANGECLIENT':
			return action.payload
		case 'updateClient':
			const updatedClient = action.payload
			const newState = { ...state }

			for (const key in updatedClient) {
				newState[key] = updatedClient[key]
			}

			return newState

		default:
			return state
	}
}

const changeApplicantLists = (state = {}, action) => {
	switch (action.type) {
		case 'CHANGEAPPLICANTLISTS':
			return action.payload
		case 'updateUser':
			const updatedUser = action.payload
			const newState = { ...state }

			if (newState[updatedUser.testID]) {
				for (const candidate of newState[updatedUser.testID]) {
					if (candidate.id === updatedUser.id) {
						for (const key in updatedUser) {
							candidate[key] = updatedUser[key]
						}
					}
				}
			}
			return newState

		default:
			return state
	}
}

const changeTestList = (state = [], action) => {
	switch (action.type) {
		case 'CHANGETESTLIST':
			return action.payload
		default:
			return state
	}
}

const changeJobsInfo = (state = [], action) => {
	switch (action.type) {
		case 'CHANGEJOBSINFO':
			return action.payload
		default:
			return state
	}
}

const changeLoadedData = (state = false, action) => {
	switch (action.type) {
		case 'CHANGELOADEDDATA':
			return action.payload
		default:
			return state
	}
}

const changeAllUserResponses = (state = {}, action) => {
	switch (action.type) {
		case 'CHANGEALLUSERRESPONSES':
			return action.payload
		default:
			return state
	}
}

const changeQuestionLists = (state = {}, action) => {
	switch (action.type) {
		case 'CHANGEQUESTIONLISTS':
			return action.payload
		default:
			return state
	}
}

const changeAccessCodes = (state = {}, action) => {
	const newState = { ...state }

	switch (action.type) {
		case 'CHANGEACCESSCODES':
			return action.payload

		case 'updateAccessCode':
			const updatedCode = action.payload

			for (const codeID in newState) {
				if (codeID === updatedCode.id) {
					for (const key in updatedCode) {
						newState[codeID][key] = updatedCode[key]
					}
					break
				}
			}

			return newState

		case 'addCode':
			const newCode = action.payload
			newState[newCode.id] = newCode
			return newState

		default:
			return state
	}
}

const changeScoringTags = (state = [], action) => {
	switch (action.type) {
		case 'CHANGESCORINGTAGS':
			return action.payload
		default:
			return state
	}
}

const changeScoringTagResponses = (state = [], action) => {
	switch (action.type) {
		case 'CHANGESCORINGTAGRESPONSES':
			return action.payload
		default:
			return state
	}
}

const changeQuestionAnswersLists = (state = {}, action) => {
	switch (action.type) {
		case 'CHANGEQUESTIONANSWERSLISTS':
			return action.payload
		default:
			return state
	}
}

const changeClientList = (state = [], action) => {
	switch (action.type) {
		case 'CHANGECLIENTLIST':
			return action.payload
		default:
			return state
	}
}

const changeScoringTagResponsesByUserID = (state = {}, action) => {
	switch (action.type) {
		case 'CHANGESCORINGTAGRESPONSESBYUSERID':
			return action.payload
		default:
			return state
	}
}

const changeErrorLogs = (state = [], action) => {
	switch (action.type) {
		case 'CHANGEERRORLOGS':
			return action.payload
		case 'deleteErrorLog':
			const newState = [...state]
			const logID = action.payload

			for (let i = 0; i < newState.length; i++) {
				if (newState[i].id === logID) {
					newState.splice(i, 1)
					break
				}
			}

			return newState
		default:
			return state
	}
}

const allReducers = combineReducers({
	client: changeClient,
	applicantLists: changeApplicantLists,
	testList: changeTestList,
	clientList: changeClientList,
	jobsInfo: changeJobsInfo,
	loadedData: changeLoadedData,
	allUserResponses: changeAllUserResponses,
	questionLists: changeQuestionLists,
	accessCodes: changeAccessCodes,
	scoringTags: changeScoringTags,
	scoringTagResponses: changeScoringTagResponses,
	scoringTagResponsesByUserID: changeScoringTagResponsesByUserID,
	questionAnswers: changeQuestionAnswersLists,
	errorLogs: changeErrorLogs,
})

export default allReducers
