import React, { useState } from 'react'
import uuid from 'react-uuid'
import { Typography, Button, Stack, TextField, Card, Box, Divider } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import DeleteIcon from '@mui/icons-material/Delete'
import YesNoToggle from './YesNoToggle'
import { DarkButton } from '../../../../Button/DarkButton'

const otherOptionText = 'Other - Please feel free to specify'

const FormBuilder = ({ questions, setQuestions }) => {
	const addQuestion = () => {
		setQuestions([
			...questions,
			{
				id: uuid(),
				type: 'MultipleChoice',
				questionText: '',
				questionAnswers: [],
			},
		])
	}

	const removeQuestion = (index) => {
		const updatedQuestions = [...questions]
		updatedQuestions.splice(index, 1)
		setQuestions(updatedQuestions)
	}

	const updateQuestion = (newQuestion, questionIndex) => {
		const updatedQuestions = [...questions]
		updatedQuestions[questionIndex] = newQuestion
		setQuestions(updatedQuestions)
	}

	return (
		<>
			<Stack direction='column' mt={4} gap={4}>
				{questions.map((question, index) => (
					<Question
						question={question}
						index={index}
						removeQuestion={() => removeQuestion(index)}
						updateQuestion={updateQuestion}
					/>
				))}
			</Stack>

			<DarkButton sx={{ textTransform: 'none', marginTop: '30px' }} variant='contained' onClick={addQuestion}>
				Add Question
			</DarkButton>
		</>
	)
}

export default FormBuilder

const Question = ({ question, index, removeQuestion, updateQuestion }) => {
	const [allowMultiple, setAllowMultiple] = useState(false)

	const handleToggle = (_, newValue) => {
		if (newValue === null) return

		const newQuestion = { ...question }

		if (newValue === true) {
			newQuestion.type = 'Checkbox'
		} else {
			newQuestion.type = 'MultipleChoice'
		}

		updateQuestion(newQuestion, index)
		setAllowMultiple(newValue)
	}

	const updateQuestionText = (e) => {
		const newQuestion = { ...question }
		newQuestion.questionText = e.target.value
		updateQuestion(newQuestion, index)
	}

	const addQuestionAnswer = () => {
		const newQuestion = { ...question }
		const otherOptionIndex = newQuestion.questionAnswers.findIndex((answer) => answer === otherOptionText)

		if (otherOptionIndex >= 0) {
			newQuestion.questionAnswers.splice(otherOptionIndex, 1, '', otherOptionText)
		} else {
			newQuestion.questionAnswers.push('')
		}
		updateQuestion(newQuestion, index)
	}

	const addOtherQuestionAnswer = () => {
		const newQuestion = { ...question }
		newQuestion.questionAnswers.push(otherOptionText)
		updateQuestion(newQuestion, index)
	}

	const deleteQuestionAnswer = (answerIndex) => {
		const newQuestion = { ...question }
		newQuestion.questionAnswers.splice(answerIndex, 1)
		updateQuestion(newQuestion, index)
	}

	const updateQuestionAnswer = (newValue, answerIndex) => {
		const newQuestion = { ...question }
		newQuestion.questionAnswers[answerIndex] = newValue
		updateQuestion(newQuestion, index)
	}

	return (
		<Card sx={{ padding: '20px', display: 'flex', alignItems: 'start', flexDirection: 'column' }} elevation={3}>
			<Stack direction='column' alignItems='start'>
				<Box display='flex' justifyContent='space-between' width='100%' alignItems='center' mb={2}>
					<Typography fontWeight='bold' color='rgba(40, 40, 40, 0.9)' fontSize={14}>
						Question {index + 1}
					</Typography>
					<Button onClick={removeQuestion}>
						<DeleteIcon style={{ color: 'rgb(40, 40, 40, 0.8)' }} />
					</Button>
				</Box>

				<TextField
					multiline
					sx={{ width: '500px' }}
					size='small'
					label='Question'
					inputProps={{ style: { fontSize: 14 } }}
					InputLabelProps={{ style: { fontSize: 14 } }}
					defaultValue={question.questionText}
					onBlur={updateQuestionText}
				/>

				<Typography mt={2} fontWeight='bold' color='rgba(40, 40, 40, 0.9)' fontSize={14}>
					Options
				</Typography>
				{question.questionAnswers.map((questionAnswer, answerIndex) => (
					<QuestionAnswer
						key={answerIndex + questionAnswer}
						questionAnswer={questionAnswer}
						answerIndex={answerIndex}
						updateQuestionAnswer={updateQuestionAnswer}
						deleteQuestionAnswer={deleteQuestionAnswer}
					/>
				))}
			</Stack>

			<Box display='flex' gap={2} width='100%' mt={6} alignItems='center'>
				<DarkButton sx={{ textTransform: 'none' }} variant='contained' onClick={addQuestionAnswer}>
					Add Option
				</DarkButton>
				<DarkButton
					sx={{ textTransform: 'none' }}
					variant='contained'
					onClick={addOtherQuestionAnswer}
					disabled={question.questionAnswers.some((answer) => answer === otherOptionText)}
				>
					Add Other
				</DarkButton>
			</Box>

			<Divider variant='middle' style={{ width: '100%', margin: '30px 0' }} />
			<Box display='flex' gap={2} width='100%' alignItems='center'>
				<YesNoToggle
					toggleValue={allowMultiple}
					handleToggle={handleToggle}
					label={<Typography>Allow multiple selections?</Typography>}
				/>
			</Box>
		</Card>
	)
}

const QuestionAnswer = ({ questionAnswer, answerIndex, deleteQuestionAnswer, updateQuestionAnswer }) => {
	return (
		<Stack mt={2} alignItems='center' direction='row' width='100%'>
			<TextField
				sx={{
					width: '100%',
					'& .MuiInputBase-input.Mui-disabled': {
						WebkitTextFillColor: 'rgb(40, 40, 40, 0.6)',
					},
				}}
				size='small'
				label={`Option ${answerIndex + 1}`}
				inputProps={{ style: { fontSize: 14 } }}
				InputLabelProps={{ style: { fontSize: 14 } }}
				defaultValue={questionAnswer}
				disabled={questionAnswer === otherOptionText}
				onBlur={(e) => {
					updateQuestionAnswer(e.target.value, answerIndex)
				}}
			/>
			<Button
				style={{ padding: 10, minWidth: 0, marginLeft: 2 }}
				onClick={() => {
					deleteQuestionAnswer(answerIndex)
				}}
			>
				<CloseIcon style={{ color: 'rgb(40, 40, 40, 0.9)' }} />
			</Button>
		</Stack>
	)
}
