import React, { useEffect } from 'react'
import { Modal, Typography, Card, CircularProgress } from '@mui/material'
import html2canvas from 'html2canvas'
import { jsPDF } from 'jspdf'

function PDFProgressModal({ progressModalOpen, setProgressModalOpen, candidate, test }) {
	useEffect(() => {
		async function generatePDF() {
			const pdf = new jsPDF('portrait', 'mm', 'letter')
			const pageHeightMm = pdf.internal.pageSize.getHeight()
			// Add a CSS class name to each block of text
			const textBlocks = document.querySelectorAll('.pdf-item')
			let currentY = 0
			const promises = []

			for (let i = 0; i < textBlocks.length; i++) {
				const textBlock = textBlocks[i]
				const textBlockHeightMm = (textBlock.offsetHeight * 25.4) / 96 // Convert px to mm
				promises.push(
					new Promise((resolve, reject) => {
						try {
							html2canvas(textBlock).then((textCanvas) => {
								const imgData = textCanvas.toDataURL('image/png')
								resolve([imgData, textBlockHeightMm])
							})
						} catch (e) {
							reject(e)
						}
					})
				)
			}
			await Promise.all(promises).then((results) => {
				for (let i = 0; i < results.length; i++) {
					const [imgData, textBlockHeightMm] = results[i]
					if (currentY + textBlockHeightMm > pageHeightMm) {
						// If the text won't fit on the current page, add a new page
						pdf.addPage()
						currentY = 0
					}
					pdf.addImage(imgData, 'PNG', 0, currentY)
					currentY += textBlockHeightMm
				}
			})
			// Replaces the period in the names
			pdf.save(`${candidate.name.replace(/\./g, '')} - ${test.testName.replace(/\./g, '')}`)
			setProgressModalOpen(false)
		}

		if (progressModalOpen) {
			setTimeout(() => generatePDF(), 300)
		}
		console.log('here')

		// eslint-disable-next-line
	}, [progressModalOpen])

	return (
		<Modal open={progressModalOpen} onClose={() => setProgressModalOpen(false)} aria-labelledby='modal-pdf-progress'>
			<Card
				style={{
					width: 400,
					padding: '12px 16px',
					position: 'absolute',
					left: '50%',
					top: '50%',
					transform: 'translate(-50%, -50%)',
				}}
			>
				<Typography variant='h6' component='h2'>
					Generating PDF
				</Typography>
				<Typography id='modal-modal-description' sx={{ mt: 2 }}>
					We are generating your PDF. It may take some time to complete. This frame will automatically close when the PDF has been
					downloaded.
				</Typography>
				<div style={{ display: 'flex', justifyContent: 'center', margin: '20px 0' }}>
					<CircularProgress />
				</div>
			</Card>
		</Modal>
	)
}

export default PDFProgressModal
