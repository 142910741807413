import React, { useEffect, useState } from 'react'
import styles from './Dashboard.module.css'
import ArticleIcon from '@mui/icons-material/ArticleOutlined'
import { useSelector } from 'react-redux'
import { Card } from '@mui/material'

function TestsTab() {
	const tests = useSelector((state) => state.testList)
	const applicantLists = useSelector((state) => state.applicantLists)
	const codeList = useSelector((state) => state.accessCodes)
	const [testInfo, setTestInfo] = useState({ total: 0, averageApps: 0, active: 0, inactive: 0 })

	useEffect(() => {
		const newInfo = { total: 0, averageApps: 0, active: 0, inactive: 0 }

		if (!tests) return

		newInfo.total = tests.length

		// Finds average Apps
		if (applicantLists) {
			let totalApps = 0
			let numTests = 0

			for (const key in applicantLists) {
				if (applicantLists[key].length) {
					numTests++
					totalApps += applicantLists[key].length
				}
			}

			if (numTests !== 0) {
				const avg = totalApps / numTests
				newInfo.averageApps = Math.round((avg + Number.EPSILON) * 100) / 100 // Rounds to two decimal places
			}
		}

		for (const test of tests) {
			const id = test.id

			for (const key in codeList) {
				const code = codeList[key]
				if (code.testID === id && code.status === 'open' && code.timesUsed < code.numAllowed) {
					newInfo.active++
					break
				}
			}
		}

		newInfo.inactive = newInfo.total - newInfo.active

		// Finds active tests

		setTestInfo(newInfo)
		// eslint-disable-next-line
	}, [])

	return (
		<Card className={styles['item-container']}>
			<div className={styles['item-title']}>
				<ArticleIcon style={{ color: '#001940', height: 30, width: 30 }} />
				Tests
			</div>

			<div className={styles['candidates-grid']}>
				<div className={styles['candidates-metric']}>
					<span className={styles['candidates-metric-title']}> Total </span>
					<span className={styles['candidates-metric-amount']}> {testInfo.total} </span>
				</div>
				<div className={styles['candidates-metric']}>
					<span className={styles['candidates-metric-title']}> Average # </span>
					<span className={styles['candidates-metric-amount']}> {testInfo.averageApps} </span>
				</div>
				<div className={styles['candidates-metric']}>
					<span className={styles['candidates-metric-title']}> Active </span>
					<span className={`${styles['candidates-metric-amount']} ${styles['show-green-dot']}`}> {testInfo.active} </span>
				</div>
				<div className={styles['candidates-metric']}>
					<span className={styles['candidates-metric-title']}> Closed </span>
					<span className={styles['candidates-metric-amount']}> {testInfo.inactive} </span>
				</div>
			</div>
		</Card>
	)
}

export default TestsTab
