import React, { createContext, useEffect, useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import { getAccessCodesForTest } from '../../lib/codes'
import { getScoringTagsForTest, getCandidateListForTest, calculateAverageCSScores } from '../../lib/test'
import { calculateMaxScoringTags } from '../../lib/tags'

const TestContext = createContext()

const tabs = ['Overview', 'Candidates', 'Manage Access', 'Executive Report']

function TestContextWrapper(props) {
	const testList = useSelector((state) => state.testList)
	const applicantLists = useSelector((state) => state.applicantLists)
	const accessCodes = useSelector((state) => state.accessCodes)
	const questionLists = useSelector((state) => state.questionLists)
	const scoringTags = useSelector((state) => state.scoringTags)
	const scoringTagResponsesByUserID = useSelector((state) => state.scoringTagResponsesByUserID)
	const allUserResponses = useSelector((state) => state.allUserResponses)

	const [test, setTest] = useState(testList && testList[0] ? testList[0] : null)
	const [candidateList, setCandidateList] = useState([])
	const [averageCSScores, setAverageCSScores] = useState({})
	const [codeList, setCodeList] = useState([])
	const [tab, setTab] = useState(tabs[0])

	/*  Stores the list of filters that are applied the Candidates tab.
	 *  This will be edited by the FilterBar component.
	 *  We store it in this context because we want the filter to stay the same between tests.
	 *  The logic is that if you applied the filter to one test, you probably want to apply them
	 *  to all tests. */
	const [candidatesFilterList, setCandidatesFilterList] = useState([])

	const maxTagScores = useRef({})

	// Initialize state
	useEffect(() => {
		if (!testList || !testList[0]) return

		setTest(testList[0])

		// eslint-disable-next-line
	}, [])

	useEffect(() => {
		if (!test) return
		const newAverageCSScores = calculateAverageCSScores(questionLists[test.id], candidateList, allUserResponses)

		setAverageCSScores(newAverageCSScores)

		// eslint-disable-next-line
	}, [test, allUserResponses, candidateList])

	useEffect(() => {
		if (!test) return

		maxTagScores.current = calculateMaxScoringTags(getScoringTagsForTest(questionLists[test.id], scoringTags))

		// eslint-disable-next-line
	}, [test])

	useEffect(() => {
		if (!test) return
		const newList = getCandidateListForTest(applicantLists, test, scoringTagResponsesByUserID, scoringTags, maxTagScores.current)
		setCandidateList(newList)
		// eslint-disable-next-line
	}, [test, applicantLists])

	useEffect(() => {
		if (!test) return
		setCodeList(getAccessCodesForTest(accessCodes, test.id))
	}, [test, accessCodes])

	return (
		<TestContext.Provider
			value={{
				test,
				setTest,
				candidateList,
				tab,
				setTab,
				codeList,
				maxTagScores: maxTagScores.current,
				candidatesFilterList,
				setCandidatesFilterList,
				averageCSScores,
			}}
		>
			{props.children}
		</TestContext.Provider>
	)
}

export { TestContextWrapper, TestContext }
