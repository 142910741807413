import BannerMenuButton from '../MenuButton/BannerMenuButton'
import './NavBar.css'

function NavBarTab(props) {
	return (
		<BannerMenuButton onClick={() => props.onClick()} style={props.style} disabled={props.disabled}>
			{props.icon}
			{props.showLabel && <span className='button-text'> {props.label} </span>}
		</BannerMenuButton>
	)
}

export default NavBarTab
