import {
	convertToReadablePhoneNumber,
	convertToHMSTimeString,
	parseAWSDateTimeWithYear,
	convertToMMSSTimeString,
} from '../functions/CalculationFunctions'

export function getAllCandidateTableData(candidateList, totalScore) {
	const tableData = []

	for (const candidate of candidateList) {
		tableData.push(getCandidateTableData(candidate, totalScore))
	}

	// Adds correct rank to each candidate
	tableData.sort((a, b) => {
		return b.scoreValue - a.scoreValue
	})
	for (let i = 0; i < tableData.length; i++) {
		tableData[i].rank = i + 1
	}

	return tableData
}

function getCandidateTableData(candidate, totalScore) {
	return {
		userID: candidate.id,
		testID: candidate.testID,
		shortlisted: candidate.shortlisted,
		name: candidate.firstName + ' ' + candidate.lastName,
		scoreValue: candidate.weightedScore,
		autoScore: Math.round((candidate.weightedScore / totalScore) * 100),
		adjustedScore: Math.round((candidate.score / totalScore) * 100),
		phone: convertToReadablePhoneNumber(candidate.phone),
		testTimeMS: candidate.testTime,
		testTime: convertToHMSTimeString(candidate.testTime),
		caseStudyTimeMS: candidate.caseStudyTime,
		caseStudyTime: convertToHMSTimeString(candidate.caseStudyTime),
		location: candidate.city + ', ' + candidate.province,
		createdAt: candidate.createdAt,
		dateSubmitted: parseAWSDateTimeWithYear(candidate.createdAt),
		rank: 0,
		codeUsed: candidate.codeUsed,
		clientComments: candidate.clientComments,
	}
}

export function getCandidateWeightedScoreValue(tagResps, scoringTags, maxTagScores, origScore, customScoringWeights) {
	// if (origScore === null || origScore === undefined || isNaN(origScore)) console.error('origScore is null!')
	const candidateTagTotals = getCandidateTagTotals(tagResps, scoringTags)
	return calculateWeightedScore(candidateTagTotals, maxTagScores, origScore, customScoringWeights)
}

// TODO: Should look into storing scoringTags in an object for quicker lookup maybe?
export function getCandidateTagTotals(tagResps, scoringTags) {
	const tagTotals = {}

	if (!tagResps) return tagTotals

	for (const resp of tagResps) {
		let dataMapping = ''

		// Find relevant dataMapping for specific response
		for (const tag of scoringTags) {
			if (resp.scoringTagID === tag.id) {
				dataMapping = tag.dataMapping
			}
		}

		if (dataMapping === '' || dataMapping === 'TechnologySkills') continue

		if (tagTotals[dataMapping] === undefined) {
			tagTotals[dataMapping] = 0
		}

		tagTotals[dataMapping] += resp.score
	}

	if (!tagTotals) console.error('tagTotals is null!')

	return tagTotals
}

export function calculateWeightedScore(userTagTotals, maxTagScores, origScore, customScoringWeights) {
	if (!customScoringWeights) {
		return origScore
	}

	// Calculates their score without any scoring tags
	let weightedScore = origScore
	for (const key in userTagTotals) {
		weightedScore -= userTagTotals[key]
	}

	// Calculates the max score from scoring tags
	let scoringTagMaxScore = 0
	for (const key in maxTagScores) {
		scoringTagMaxScore += maxTagScores[key]
	}

	// Calculates what percentage of the overall score each category makes up in the original weighting scheme
	const origWeights = {}
	for (const key in maxTagScores) {
		origWeights[key] = Math.round((maxTagScores[key] / scoringTagMaxScore) * 1000) / 1000 // Rounds to 3 decimal places (or 1 decimal place in 10.5% format)
	}

	const newUserTagTotals = {}
	for (const key in customScoringWeights) {
		if (!userTagTotals[key]) continue
		newUserTagTotals[key] = (userTagTotals[key] * customScoringWeights[key]) / origWeights[key]
		weightedScore += newUserTagTotals[key]
	}

	// if (!weightedScore) console.error('Weighted score is null/NAN!', weightedScore)

	return weightedScore
}

export function getDateStringOfCandidate(createdAt) {
	return new Date(createdAt).getMonth() + 1 + '/' + new Date(createdAt).getDate()
}

/* ############ RESPONSES SECTION ##############*/

function createData(question, response, score, notes, time, order, maxScore) {
	let timeString = convertToMMSSTimeString(time)

	return { question, response, score, notes, timeString, order, maxScore }
}

// Combines CheckBox responses into one response
function combineResponses(responses) {
	let combinedResponses = []
	let found

	for (let response of responses) {
		found = false

		for (let combinedResponse of combinedResponses) {
			// Check if a response for this question has already been made
			if (combinedResponse.questionID === response.questionID) {
				// If a response is already present, then combine them
				found = true
				combinedResponse.response += ', ' + response.response
				combinedResponse.score += response.score
				break
			}
		}

		if (!found) {
			combinedResponses.push({ ...response })
		}
	}

	return combinedResponses
}

function findResponse(responses, questionID) {
	for (const response of responses) {
		if (response.questionID === questionID) {
			return {
				response: response.response,
				notes: response.notes,
				score: response.score,
				timeTaken: response.timeTaken,
			}
		}
	}

	return { response: '', notes: '', score: 0, timeTaken: 0 }
}

// Returns arrays of responses to questions, organized by the section the question is in
export function createSortedResponses(responses, questionLists, testID) {
	let sortedResponses = {}
	const sections = ['AboutYourself', 'YourExperience', 'KnowledgeAssessment', 'CaseStudy', 'LastQuestion']
	const combinedResponses = combineResponses(responses)

	// Initialize sortedResponses
	for (const section of sections) sortedResponses[section] = []

	for (const question of questionLists[testID]) {
		if (!sections.includes(question.section)) continue

		// Find response for question and add it to sortedResponses, organized by what section the response is in
		const response = findResponse(combinedResponses, question.id)
		sortedResponses[question.section].push(
			createData(
				question.questionText,
				response.response,
				response.score,
				response.notes,
				response.timeTaken,
				question.order,
				question.maxScore
			)
		)
	}

	// Sorts responses to be in same order they appear in test
	for (let key in sortedResponses) {
		sortedResponses[key].sort((a, b) => a.order - b.order)
	}

	return sortedResponses
}
