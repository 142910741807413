import React, { useContext } from 'react'
import RecentCandidatesTable from './RecentCandidatesTable'
import ScoreTimeContainer from './ScoreTimeContainer'
import QualifiedCandidatesGraph from './QualifiedCandidatesGraph'
import Timeline from './Timeline'
import { CandidatesContext } from '../Candidates/CandidatesContext'
import Breakdown from '../Candidates/Breakdown/Breakdown'
import ResponsesView from '../Candidates/Breakdown/ResponsesView'
import SelfIdentificationResponses from './SelfIdentificationResponses'

function Overview() {
	const { view } = useContext(CandidatesContext)

	return (
		<>
			{view === 'table' && (
				<>
					<div style={{ display: 'flex', gap: 40, maxWidth: '100%' }}>
						<ScoreTimeContainer />
						<QualifiedCandidatesGraph />
					</div>
					<RecentCandidatesTable />
					<Timeline />
					<SelfIdentificationResponses />
				</>
			)}
			{view === 'candidate' && <Breakdown />}

			{view === 'responses' && <ResponsesView />}
		</>
	)
}

export default Overview
