import {
	changeApplicantLists,
	changeClient,
	changeJobsInfo,
	changeTestList,
	changeClientList,
	changeLoadedData,
	changeAllUserResponses,
	changeQuestionLists,
	changeQuestionAnswersLists,
	changeAccessCodes,
	changeScoringTags,
	changeScoringTagResponses,
	changeScoringTagResponsesByUserID,
} from '../actions'

export function resetStore(dispatch) {
	dispatch(changeClient({}))
	dispatch(changeApplicantLists({}))
	dispatch(changeTestList([]))
	dispatch(changeClientList([]))
	dispatch(changeJobsInfo([]))
	dispatch(changeLoadedData(false))
	dispatch(changeAllUserResponses({}))
	dispatch(changeQuestionLists({}))
	dispatch(changeQuestionAnswersLists({}))
	dispatch(changeAccessCodes({}))
	dispatch(changeScoringTags([]))
	dispatch(changeScoringTagResponses([]))
	dispatch(changeScoringTagResponsesByUserID({}))
}

export function updateJobsData(applicantLists, testList, dispatch) {
	let jobs = []

	for (const test of testList) {
		const job = {
			name: test.testName,
			numApplicants: applicantLists[test.id] ? applicantLists[test.id].length : 0,
			uniAvgScore: calcAverageScore(applicantLists[test.id], test.totalScore),
			currAvgScore: calcAverageScore(applicantLists[test.id], test.totalScore),
			highScore: calcHighScore(applicantLists[test.id], test.totalScore),
			applicantList: applicantLists[test.id],
			totalScore: test.totalScore,
		}

		jobs.push(job)
	}

	dispatch(changeJobsInfo(jobs))
}

function calcAverageScore(applicantList, maxScore) {
	let total = 0

	if (applicantList.length === 0) {
		return 0
	}

	for (const applicant of applicantList) {
		total += applicant.score
	}

	return parseInt((total / applicantList.length / maxScore) * 100)
}

function calcHighScore(applicantList, maxScore) {
	let highScore = -1

	if (applicantList.length === 0) return 0

	for (let applicant of applicantList) {
		if (highScore < applicant.score) {
			highScore = applicant.score
		}
	}

	return parseInt((highScore / maxScore) * 100)
}
