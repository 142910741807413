import React, { useContext } from 'react'
import styles from '../Candidates.module.css'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import MenuButton from '../../../MenuButton/BannerMenuButton'
import { CandidatesContext } from '../CandidatesContext'
import { Card } from '@mui/material'

function Menu() {
	const { setView, rows, index, changeIndex } = useContext(CandidatesContext)

	return (
		<Card className={styles['list-nav']}>
			<MenuButton style={{ color: 'black' }} onClick={() => setView('table')}>
				<ChevronLeftIcon style={{ height: 25, width: 25 }} /> Back
			</MenuButton>
			<div style={{ transform: 'translateX(-50%)' }}>
				<button onClick={() => changeIndex('decrease')}>
					<ChevronLeftIcon style={{ height: 25, width: 25, opacity: 0.75 }} />
				</button>
				<span style={{ width: 100 }}>
					{index + 1} of {rows.length}
				</span>
				<button onClick={() => changeIndex('increase')}>
					<ChevronRightIcon style={{ height: 25, width: 25, opacity: 0.75 }} />
				</button>
			</div>
			<div />
		</Card>
	)
}

export default Menu
