import React, { useState } from 'react'
import { TextField, Select, MenuItem, FormControl, FormHelperText, Typography, Card } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import { useSelector } from 'react-redux'

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'

import { API } from 'aws-amplify'
import { createClient } from '../../graphql/mutations'
import { DarkButton } from '../Button/DarkButton'

function getTestName(testID, testList) {
	for (let test of testList) {
		if (test.id === testID) {
			return test.testName
		}
	}

	return ''
}

function getUserOptions(applicantLists, testList) {
	let users = []

	for (const testID in applicantLists) {
		for (let applicant of applicantLists[testID]) {
			users.push({
				label: applicant.firstName + ' ' + applicant.lastName + ', ' + applicant.id + '  (' + getTestName(testID, testList) + ')',
				id: applicant.id,
			})
		}
	}

	return users
}

function getTestOptions(testList) {
	let names = []

	for (let test of testList) {
		names.push({
			label: test.testName,
			id: test.id,
		})
	}

	return names
}

function getCodeOptions(accessCodes, testList) {
	let codes = []

	for (let id in accessCodes) {
		let code = accessCodes[id]
		codes.push({
			label: code.code + '  (' + getTestName(code.testID, testList) + ')',
			id: code.id,
		})
	}

	return codes
}

function CreateClient() {
	const applicantLists = useSelector((state) => state.applicantLists)
	const testList = useSelector((state) => state.testList)
	const accessCodes = useSelector((state) => state.accessCodes)

	const userOptions = getUserOptions(applicantLists, testList)
	const testOptions = getTestOptions(testList)
	const codeOptions = getCodeOptions(accessCodes, testList)

	const [role, setRole] = useState('Level 1')
	const [name, setName] = useState('')
	const [selectedUsers, setSelectedUsers] = useState([])
	const [selectedTests, setSelectedTests] = useState([])
	const [selectedCodes, setSelectedCodes] = useState([])

	const [errMessage, setErrMessage] = useState('')
	const [status, setStatus] = useState('')

	return (
		<div style={{ width: '90%', height: '80%', display: 'flex', flexDirection: 'row', margin: '5% 0 0 5%' }}>
			<Card
				elevation={5}
				style={{
					overflow: 'auto',
					height: 'fit-content',
					width: 500,
					display: 'flex',
					alignItems: 'start',
					flexDirection: 'column',
					paddingLeft: 40,
					paddingTop: 40,
					boxSizing: 'border-box',
				}}
			>
				<TextField
					style={{ width: 400, height: 30, paddingBottom: 50 }}
					helperText='Name'
					value={name}
					error={status.includes('NAME')}
					onChange={(e) => {
						setName(e.target.value)
						if (status !== '') {
							setErrMessage('')
							setStatus('')
						}
					}}
					inputProps={{ maxLength: 30 }}
				/>

				<Autocomplete
					multiple
					id='combo-box-demo'
					options={userOptions}
					sx={{ width: 400 }}
					renderInput={(params) => (
						<TextField {...params} helperText='List of Users Client Can See' error={status.includes('USERLIST')} />
					)}
					value={selectedUsers}
					isOptionEqualToValue={(option, value) => {
						if (option.id === value.id) return true
						return false
					}}
					onChange={(e, newValue) => {
						setSelectedUsers(newValue)
						if (status !== '') {
							setErrMessage('')
							setStatus('')
						}
					}}
				/>

				<div style={{ paddingTop: 20 }}>
					<Autocomplete
						multiple
						id='combo-box-demo'
						options={testOptions}
						sx={{ width: 400 }}
						renderInput={(params) => (
							<TextField {...params} helperText='List of Tests Client Can See' error={status.includes('TESTLIST')} />
						)}
						value={selectedTests}
						isOptionEqualToValue={(option, value) => {
							if (option.id === value.id) return true
							return false
						}}
						onChange={(e, newValue) => {
							setSelectedTests(newValue)
							if (status !== '') {
								setErrMessage('')
								setStatus('')
							}
						}}
					/>
				</div>

				<div style={{ paddingTop: 20 }}>
					<Autocomplete
						multiple
						id='combo-box-demo'
						options={codeOptions}
						sx={{ width: 400 }}
						renderInput={(params) => (
							<TextField {...params} helperText='List of Codes Client Can Manage' error={status.includes('CODELIST')} />
						)}
						value={selectedCodes}
						isOptionEqualToValue={(option, value) => {
							if (option.id === value.id) return true
							return false
						}}
						onChange={(e, newValue) => {
							setSelectedCodes(newValue)
							if (status !== '') {
								setErrMessage('')
								setStatus('')
							}
						}}
					/>
				</div>

				<div style={{ paddingTop: 20 }}>
					<FormControl sx={{ m: 1 }} error={status.includes('ROLE')}>
						<Select
							value={role}
							style={{ width: 400, textAlign: 'start', fontSize: 16 }}
							onChange={(e) => {
								setRole(e.target.value)
								if (status !== '') {
									setErrMessage('')
									setStatus('')
								}
							}}
						>
							<MenuItem value={'Hiring Manager'}> Hiring Manager </MenuItem>
							<MenuItem value={'Level 1'}> Level 1 </MenuItem>
						</Select>
						<FormHelperText> Role </FormHelperText>
					</FormControl>
				</div>

				<div style={{ display: 'flex', width: '90%', justifyContent: 'center', paddingBottom: 40, paddingTop: 20 }}>
					<DarkButton
						sx={{ padding: '6px 10px' }}
						onClick={async () => {
							if (name.length === 0) {
								setStatus('FAILURE-NAME')
								setErrMessage("Missing 'Name' field.")
								return
							}

							const newClient = {
								name: name,
								role: role,
								userList: selectedUsers.map((a) => a.id),
								testList: selectedTests.map((a) => a.id),
								codeList: selectedCodes.map((a) => a.id),
								clientList: [],
							}

							try {
								await API.graphql({
									query: createClient,
									variables: { input: newClient },
									authMode: 'AMAZON_COGNITO_USER_POOLS',
								})
							} catch (err) {
								setStatus('FAILURE')
								setErrMessage('Failed in uploading to server.')
								console.log(err)
								return
							}

							setStatus('SUCCESS')
							setErrMessage('')
						}}
					>
						Add Client
					</DarkButton>
				</div>
			</Card>

			<div
				style={{
					display: 'flex',
					height: 450,
					width: '60%',
					justifyContent: 'center',
					alignItems: 'center',
					flexDirection: 'column',
				}}
			>
				<Typography style={{ fontSize: 18, color: '#cc0000', paddingBottom: 10 }}> {errMessage} </Typography>
				{status === 'SUCCESS' && <CheckCircleOutlineIcon style={{ height: 70, width: 70, color: '#32a846' }} />}
				{status.includes('FAILURE') && <CancelOutlinedIcon style={{ height: 50, width: 50, color: '#cc0000' }} />}
			</div>
		</div>
	)
}

export default CreateClient
