export const customCreateTest = /* GraphQL */ `
	mutation CreateTest($input: CreateTestInput!, $condition: ModelTestConditionInput) {
		createTest(input: $input, condition: $condition) {
			id
			testName
			logoPath
			casestudyFiles
			dataMappingTags
			clientID
			totalScore
			customScoringWeights
			selfIdentificationQuestions {
				id
				type
				questionText
				questionAnswers
			}
			createdAt
			updatedAt
		}
	}
`
