import React, { useEffect, useState } from 'react'
import { ScatterChart, CartesianGrid, XAxis, YAxis, ZAxis, Tooltip, Scatter, ReferenceArea, ResponsiveContainer } from 'recharts'
import { convertToHMSTimeString, convertToHMTimeString } from '../../../lib/date-time'
import { Paper } from '@mui/material'

// const data = [
// 	{ score: 62, time: 12704390, name: 'Hammad Zafar' },
// 	{ score: 64, time: 4806846, name: 'Ethan Dancer' },
// 	{ score: 58, time: 6291520, name: 'Philippe Michalik' },
// 	{ score: 69, time: 912098, name: 'Susan Min' },
// ]

const CustomTooltip = ({ active, payload }) => {
	if (active && payload && payload.length) {
		return (
			<Paper
				style={{
					background: 'white',
					padding: '20px 30px',
					textAlign: 'left',
					display: 'grid',
					gridTemplateColumns: '60px 1fr',
					gap: 5,
				}}
				elevation={5}
			>
				<b>Name: </b>
				<span>{`${payload[0].payload.name}`}</span>
				<b>Score: </b>
				<span className='label'>{`${payload[0].value}%`}</span>
				<b>Time: </b>
				<span className='label'>{`${convertToHMSTimeString(payload[1].value)}`}</span>
			</Paper>
		)
	}

	return null
}

function ReportGraph({ shortlistedCandidates }) {
	const [data, setData] = useState([])

	// console.log(shortlistedCandidates)

	useEffect(() => {
		const dataLoader = []

		for (const candidate of shortlistedCandidates) {
			dataLoader.push({
				name: candidate.name,
				score: candidate.autoScore,
				time: candidate.testTimeMS,
			})
		}
		setData(dataLoader)
	}, [shortlistedCandidates])

	return (
		<ResponsiveContainer height={300}>
			<ScatterChart margin={{ top: 20, right: 20, bottom: 10, left: 10 }}>
				<CartesianGrid strokeDasharray='3 3' />
				<ReferenceArea x1={65} x2={100} y1={0} y2={5400000} fill='lightblue' />
				<XAxis dataKey='score' name='Score' unit='%' type='number' ticks={[0, 20, 40, 60, 80, 100]} tickLine={false} />
				<YAxis
					dataKey='time'
					name='Time'
					tickFormatter={convertToHMTimeString}
					ticks={[0, 3600000, 7200000, 10800000]}
					domain={[0, 12000000]}
					allowDataOverflow={true}
					reversed
					tickLine={false}
				/>
				<ZAxis range={[200, 200]} />
				<Tooltip content={<CustomTooltip />} />
				<Scatter name='A school' data={data} fill='#32a846' shape='star' />
			</ScatterChart>
		</ResponsiveContainer>
	)
}

export default ReportGraph
