import { useSelector } from 'react-redux'
import { TableCell, TableBody, TableRow, Paper, Table, TableHead, TableContainer, CircularProgress } from '@mui/material'
import { useEffect, useState } from 'react'
import { parseAWSDateTimeWithYear } from '../../functions/CalculationFunctions'
import { Button, TextField, Typography } from '@mui/material'
import {
	deleteAccessCodeFromDatabase,
	deleteApplicantFromDatabase,
	deleteQuestionFromDatabase,
	deleteTestFromDatabase,
} from '../../functions/DBFunctions'

import CloseIcon from '@mui/icons-material/Close'

async function findRelevantData(
	testID,
	accessCodes,
	questions,
	questionAnswers,
	scoringTags,
	applicants,
	userResponses,
	scoringTagResponses
) {
	console.log('Deleting test with testID ', testID)

	let status = 'SUCCESS'

	const totals = {
		questions: 0,
		questionAnswers: 0,
		scoringTags: 0,
		accessCodes: 0,
		applicants: 0,
		userResponses: 0,
		scoringTagResponses: 0,
	}

	// Deletes all access codes for the test
	for (const key in accessCodes) {
		if (accessCodes[key].testID === testID) {
			try {
				await deleteAccessCodeFromDatabase(key)
				totals.accessCodes++
			} catch (err) {
				console.error(' !!! FAILED TO DELETE ACCESS CODE WITH ID ', key, ' !!!', err)
				status = 'ERROR'
			}
		}
	}

	for (const question of questions[testID]) {
		const tagsToDelete = []
		const questionAnswersToDelete = []

		totals.questions++

		// Gets all question answers for a question
		if (questionAnswers[question.id]) {
			for (const questionAnswer of questionAnswers[question.id]) {
				questionAnswersToDelete.push(questionAnswer.id)
				totals.questionAnswers++
			}
		}

		// Gets all scoring tags for a question
		for (const scoringTag of scoringTags) {
			if (scoringTag.questionID === question.id) {
				tagsToDelete.push(scoringTag.id)
				totals.scoringTags++
			}
		}

		// Deletes question and all its question answers and scoring tags
		try {
			await deleteQuestionFromDatabase(question.id, tagsToDelete, questionAnswersToDelete)
		} catch (err) {
			console.error('Failed to delete question with id ', question.id, err)
			status = 'ERROR'
		}
	}

	for (const applicant of applicants) {
		if (applicant.testID && applicant.testID !== testID) {
			// Should be the same since it's in the array passed in, but double checking
			console.error(' !!! WARNING: WRONG APPLICANT WAS IN THE LIST !!!')
			continue
		}

		const questionResponsesToDelete = []
		const tagResponsesToDelete = []

		// console.log("Deleting applicant with id ", applicant.id)
		totals.applicants++

		if (userResponses[applicant.id]) {
			// Add all the question responses by the user to delete
			for (const response of userResponses[applicant.id]) {
				totals.userResponses++
				if (response.userID && response.userID === applicant.id) {
					// Should be the same since it's in this array, but just double checking
					questionResponsesToDelete.push(response.id)
				} else {
					console.error(' !!! WARNING: WRONG RESPONSE WAS IN THE LIST !!!')
				}
			}
		}

		// Add all the tagResponses by the user that we want to delete
		for (const tagResponse of scoringTagResponses) {
			if (tagResponse.userID && tagResponse.userID === applicant.id) {
				totals.scoringTagResponses++
				tagResponsesToDelete.push(tagResponse.id)
			}
		}

		try {
			await deleteApplicantFromDatabase(applicant.id, questionResponsesToDelete, tagResponsesToDelete)
			console.log('Successfully deleted applicant with id ', applicant.id)
		} catch (err) {
			console.error('Failed to delete applicant with id ', applicant.id, err)
			status = 'ERROR'
		}
	}

	if (status === 'SUCCESS') {
		try {
			deleteTestFromDatabase(testID)
		} catch (err) {
			status = 'ERROR'
			console.error('Failed to delete test.', err)
		}
	}

	console.table(totals)
	console.log({ status })

	return status
}

function DeleteTest() {
	const testList = useSelector((state) => state.testList)
	const applicantLists = useSelector((state) => state.applicantLists)
	const accessCodes = useSelector((state) => state.accessCodes)
	const scoringTags = useSelector((state) => state.scoringTags)
	const scoringTagResponses = useSelector((state) => state.scoringTagResponses)
	const questionLists = useSelector((state) => state.questionLists)
	const questionAnswers = useSelector((state) => state.questionAnswers)
	const allUserResponses = useSelector((state) => state.allUserResponses)
	const [rows, setRows] = useState([])
	const [currRow, setCurrRow] = useState({})

	const [showConfirm, setShowConfirm] = useState(false)
	const [disableDelete, setDisableDelete] = useState(true)

	const [confirmText, setConfirmText] = useState('')
	const [secretKeyText, setSecretKeyText] = useState('')

	const [loading, setLoading] = useState(false)

	useEffect(() => {
		const newRows = []

		for (const test of testList) {
			const { testName, id, createdAt } = test

			newRows.push({
				testName,
				id,
				numApplicants: applicantLists[id]?.length,
				dateCreated: parseAWSDateTimeWithYear(createdAt),
			})
		}

		setRows(newRows)

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [testList])

	useEffect(() => {
		if (secretKeyText === 'T9HC1' && confirmText === 'permanently delete') {
			setDisableDelete(false)
		} else {
			setDisableDelete(true)
		}
	}, [secretKeyText, confirmText])

	return (
		<div>
			<TableContainer component={Paper}>
				<Table sx={{ width: '100%' }} aria-label='simple table'>
					<TableHead>
						<TableRow>
							<TableCell align='center'>
								{' '}
								<b>Test Name</b>{' '}
							</TableCell>
							<TableCell align='center'>
								{' '}
								<b>Test ID</b>{' '}
							</TableCell>
							<TableCell align='center'>
								{' '}
								<b> Number of Applicants </b>{' '}
							</TableCell>
							<TableCell align='center'>
								{' '}
								<b> Date Created </b>
							</TableCell>
							<TableCell />
						</TableRow>
					</TableHead>
					<TableBody>
						{rows.map((row) => (
							<TableRow key={row.id}>
								<TableCell align='center' sx={{ padding: '5px 0' }}>
									{' '}
									{row.testName}{' '}
								</TableCell>
								<TableCell align='center' sx={{ padding: '5px 0' }}>
									{row.id}
								</TableCell>
								<TableCell align='center' sx={{ padding: '5px 0' }}>
									{row.numApplicants}
								</TableCell>
								<TableCell align='center' sx={{ padding: '5px 0' }}>
									{row.dateCreated}
								</TableCell>
								<TableCell align='left' sx={{ padding: '5px 0' }}>
									<Button
										style={{ textTransform: 'none', color: '#001940' }}
										onClick={() => {
											setCurrRow(row)
											setShowConfirm(true)
										}}
									>
										Delete
									</Button>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>

			{showConfirm && (
				<div
					style={{
						position: 'fixed',
						width: 500,
						background: 'white',
						boxShadow: '0 0 2px 2px gray',
						top: '50%',
						left: '50%',
						transform: 'translate(-35%, -50%)',
					}}
				>
					<Button
						style={{ margin: '20px 10% 0 85%', width: '5%', background: 'transparent', color: '#001940' }}
						onClick={() => {
							setShowConfirm(false)
							setCurrRow({})
						}}
					>
						<CloseIcon style={{ height: 25, width: 25 }} />
					</Button>

					<Typography style={{ fontSize: 16, width: '90%', margin: '0 0 0 20px' }}>
						Are you sure you want to delete <b>{currRow.testName}</b>? Deleting the test will delete all the users that applied
						to it, all their responses, all the access codes for the test, and all the questions, question answers, and scoring
						tags.
					</Typography>

					<Typography style={{ fontSize: 16, width: '90%', margin: '20px 0 0 20px' }}>
						Type "permanently delete" in the first field and the secret delete code in the second field to delete the test.
					</Typography>

					<TextField
						style={{ width: '90%', margin: '20px 0 0 20px' }}
						label='permanently delete'
						onChange={(e) => setConfirmText(e.target.value)}
						value={confirmText}
					/>

					<TextField
						style={{ width: '90%', margin: '20px 0 0 20px' }}
						label='secret key'
						onChange={(e) => setSecretKeyText(e.target.value)}
						value={secretKeyText}
					/>

					<Button
						style={{
							margin: '20px auto 20px auto',
							width: '20%',
							height: '40px',
							background: '#001940',
							color: 'white',
							textTransform: 'none',
							opacity: disableDelete ? 0.5 : 1,
						}}
						disabled={disableDelete || loading}
						onClick={async () => {
							if (disableDelete) return
							setLoading(true)

							const result = await findRelevantData(
								currRow.id,
								accessCodes,
								questionLists,
								questionAnswers,
								scoringTags,
								applicantLists[currRow.id],
								allUserResponses,
								scoringTagResponses
							)

							setLoading(false)

							if (result === 'SUCCESS') {
								alert('Test was successfully deleted. Please log out and log in again.')
							} else {
								alert('There was an error deleting the test.')
							}
						}}
					>
						{loading ? <CircularProgress style={{ height: 30, width: 30 }} /> : 'Delete'}
					</Button>
				</div>
			)}
		</div>
	)
}

export default DeleteTest
