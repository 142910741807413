import React, { useContext } from 'react'
import CandidateNumbersGraph from '../../Dashboard/CandidateNumbersGraph'
import { TestContext } from '../TestContext'
import styles from './Overview.module.css'
import { Card } from '@mui/material'

function Timeline() {
	const { candidateList } = useContext(TestContext)

	return (
		<Card className={styles['container']} style={{ width: '60%', minHeight: 350 }}>
			<span className={styles['label']}>Candidate Timeline</span>
			<span className={styles['container-description']}>Breaks down the number of candidates by time period.</span>

			<CandidateNumbersGraph candidates={candidateList} />
		</Card>
	)
}

export default Timeline
