import React, { useState, useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Card, CircularProgress } from '@mui/material'

import {
	loadClientInfo,
	loadTests,
	loadUserResponses,
	loadTestQuestions,
	loadScoringTags,
	loadScoringTagResponses,
	loadApplicantsByIDs,
	loadMasterData,
	loadLevel1Data,
} from '../functions/DBFunctions'
import { changeLoadedData } from '../actions'
import { AccountContext } from '../components/Account/Account'

import NavBar from '../components/NavBar/NavBar'
import ErrorPage from '../components/ErrorPage'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import Dashboard from '../components/Dashboard/Dashboard'
import { throttle } from '../functions/CalculationFunctions'

async function loadHiringManagerData(applicantIDs, dispatch) {
	await loadApplicantsByIDs(applicantIDs, dispatch).then(async (applicantLists) => {
		let applicants = []
		let testIDs = []

		for (let testID in applicantLists) {
			testIDs.push(testID)
			for (let applicant of applicantLists[testID]) {
				applicants.push(applicant)
			}
		}

		await loadUserResponses(applicantLists, dispatch)
		await loadScoringTagResponses(applicants, dispatch)
		await loadTestQuestions(testIDs, dispatch).then(async (questionLists) => {
			await loadScoringTags(questionLists, dispatch)
		})
		await loadTests(testIDs, dispatch)
	})
}

function HomePage(props) {
	const [currElement, setCurrElement] = useState(<Dashboard />)
	const { getClientID, logout } = useContext(AccountContext)
	const dispatch = useDispatch()

	const loadedData = useSelector((state) => state.loadedData)
	const client = useSelector((state) => state.client)
	const [role, setRole] = useState('')
	const [loading, setLoading] = useState(true)

	const [errorLoading, setErrorLoading] = useState(false)
	// const logoutTimeoutRef = useRef()

	useEffect(() => {
		if (!loadedData) {
			getClientID().then((clientID) => {
				loadClientInfo(clientID, dispatch).then(async (clientInfo) => {
					setRole(clientInfo.role)

					if (clientInfo.role === 'Hiring Manager') {
						loadHiringManagerData(clientInfo.userList, dispatch).then(() => {
							setLoading(false)
							dispatch(changeLoadedData(true))
						})
					} else if (clientInfo.role === 'Level 1') {
						try {
							const result = await loadLevel1Data(clientInfo, dispatch)

							setLoading(false)

							if (result === 'SUCCESS') {
								dispatch(changeLoadedData(true))
							} else {
								throw new Error()
							}
						} catch (err) {
							setErrorLoading(true)
						}
					} else if (clientInfo.role === 'MASTER') {
						try {
							const result = await loadMasterData(dispatch)

							setLoading(false)

							if (result === 'SUCCESS') {
								dispatch(changeLoadedData(true))
							} else {
								throw new Error()
							}
						} catch (err) {
							setErrorLoading(true)
						}
					}
				})
			})
		} else {
			setRole(client.role)
			setLoading(false)
		}

		// eslint-disable-next-line
	}, [])

	// Logs user out if no events within 10 minutes
	useEffect(() => {
		const lastMovementTime = localStorage.getItem('lastMovement')

		if (lastMovementTime && new Date().getTime() - lastMovementTime > 600000) {
			console.log('Logging out due to lastMovementTime')
			logout()
			return
		}

		const logoutIfIdle = throttle(() => {
			localStorage.setItem('lastMovement', new Date().getTime())
			// if (logoutTimeoutRef.current) {
			// 	// console.log('clearing timeout')
			// 	clearTimeout(logoutTimeoutRef.current)
			// }
			// logoutTimeoutRef.current = setTimeout(() => {
			// 	console.log('Logging out due to mouse mover')
			// 	logout()
			// }, 600000)
			// }, 10000)
		}, 1000)

		window.addEventListener('mousemove', logoutIfIdle)

		return () => {
			window.removeEventListener('mousemove', logoutIfIdle)
		}

		// eslint-disable-next-line
	}, [])

	return (
		<div className='App'>
			<div className='main-container'>
				<NavBar
					setCurrElement={setCurrElement}
					setShowLogin={props.setShowLogin}
					setLoggedIn={props.setLoggedIn}
					role={role}
					disabled={errorLoading || loading}
				/>

				<Card className='MainViewPort'>
					{errorLoading ? (
						<ErrorPage
							message='Woops! There was an error loading your data. Try logging out and back in again and checking your internet connection. 
							If this is a recurring issue, please email support@btrhire.com.'
							icon={<ErrorOutlineIcon style={{ height: 120, width: 120, color: '#001940', marginTop: 40 }} />}
						/>
					) : loading ? (
						<CircularProgress style={{ height: 150, width: 150, color: '#001940' }} />
					) : (
						currElement
					)}
				</Card>
			</div>
		</div>
	)
}

export default HomePage
