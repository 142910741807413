import React, { useState } from 'react'
import { Button } from '@mui/material'

// import CreateAccessCode from './CreateAccessCode'
import CreateClient from './CreateClient'
import TestBuilder from './TestBuilder/TestBuilder'
import AssignTest from './AssignTest'
import DeleteTest from './DeleteTest'
import ErrorLogs from './ErrorLogs/ErrorLogs'

function getStyle(index, selectedIndex) {
	const style = { color: 'white', textTransform: 'none', fontSize: 16, marginTop: 15 }
	if (index === selectedIndex) {
		style.background = '#244B87'
	}

	return style
}

function Admin() {
	const [selectedIndex, setSelectedIndex] = useState(3)
	const [currElement, setCurrElement] = useState(<TestBuilder />)

	return (
		<div style={{ height: '100%', width: '100%', background: 'white', display: 'flex', flexDirection: 'row' }}>
			<div
				style={{
					height: '100%',
					minWidth: '150px',
					background: '#001940',
					display: 'flex',
					flexDirection: 'column',
					overflowY: 'auto',
				}}
			>
				<Button
					style={getStyle(3, selectedIndex)}
					onClick={() => {
						setSelectedIndex(3)
						setCurrElement(<TestBuilder />)
					}}
				>
					Test Builder
				</Button>

				<Button
					style={getStyle(2, selectedIndex)}
					onClick={() => {
						setSelectedIndex(2)
						setCurrElement(<CreateClient />)
					}}
				>
					Client
				</Button>

				<Button
					style={getStyle(4, selectedIndex)}
					onClick={() => {
						setSelectedIndex(4)
						setCurrElement(<AssignTest />)
					}}
				>
					Assign Tests
				</Button>

				<Button
					style={getStyle(5, selectedIndex)}
					onClick={() => {
						setSelectedIndex(5)
						setCurrElement(<DeleteTest />)
					}}
				>
					Delete Tests
				</Button>

				<Button
					style={getStyle(6, selectedIndex)}
					onClick={() => {
						setSelectedIndex(6)
						setCurrElement(<ErrorLogs />)
					}}
				>
					Error Logs
				</Button>
			</div>

			<div style={{ height: '100%', flex: '1 1 auto' }}>{currElement}</div>
		</div>
	)
}

export default Admin
