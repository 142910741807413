import React, { useEffect } from 'react'
import { Typography } from '@mui/material'
import Slider from './Slider'
import styles from './Slider.module.css'

function findIndexToUpdate(group, item, mustSubtract) {
	let indexToUpdate = -1

	// Always want to bring values close together
	if (mustSubtract) {
		// Subtracting from another value, so we want to find the closest

		let minDiff = 1000
		for (let i = 0; i < group.length; i++) {
			if (group[i].label === item.label || group[i].locked) continue

			const currDiff = Math.abs(group[i].value - item.value)
			if (currDiff < minDiff && group[i].value > 0) {
				indexToUpdate = i
				minDiff = currDiff
			}
		}
	} else {
		// Adding to another value, so we want to find furthest away

		let maxDiff = -1
		for (let i = 0; i < group.length; i++) {
			if (group[i].label === item.label || group[i].locked) continue

			const currDiff = Math.abs(group[i].value - item.value)
			if (currDiff > maxDiff) {
				indexToUpdate = i
				maxDiff = currDiff
			}
		}
	}

	return indexToUpdate
}

function SliderGroup({ group, setGroup, label, title }) {
	// Adds locked and lastEdited attributes to the group items
	useEffect(() => {
		let index = 0
		for (const item of group) {
			index++
			item.locked = false
			item.lastEdited = index % group.length
		}

		// eslint-disable-next-line
	}, [])

	return (
		<div className={styles['slider-group-container']}>
			{title && (
				<Typography fontSize='18px' fontWeight='600' mb={1} textAlign='left'>
					{title}
				</Typography>
			)}
			{label && (
				<Typography fontSize='16px' textAlign='left' mb={4}>
					{label}
				</Typography>
			)}
			<ul className={styles['slider-list']}>
				{group.map((item, index) => {
					return (
						<Slider
							item={item}
							id={item.label}
							setLocked={() => {
								const newGroup = [...group]
								newGroup[index].locked = !newGroup[index].locked
								setGroup(newGroup)
							}}
							setValue={(newValue) => {
								const newGroup = [...group]

								if (item.locked) return

								// If we are adding a value to one input, we mustSubtract a value from another
								const mustSubtract = newValue > newGroup[index].value

								while (newGroup[index].value !== newValue) {
									const indexToUpdate = findIndexToUpdate(newGroup, item, mustSubtract)

									if (indexToUpdate !== -1) {
										if (mustSubtract) {
											newGroup[indexToUpdate].value--
											newGroup[index].value++
										} else {
											newGroup[indexToUpdate].value++
											newGroup[index].value--
										}
									} else {
										return
									}
								}
								setGroup(newGroup)
							}}
						/>
					)
				})}
			</ul>
		</div>
	)
}

export default SliderGroup
