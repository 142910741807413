import SliderGroup from '../WeightSlider/SliderGroup'
import { Typography, Divider } from '@mui/material'

import { calculateAutoScoringTagWeights } from '../../../../functions/CalculationFunctions'

function ScoringWeightsPage({ testInfo, setTestInfo }) {
	const initialWeights = testInfo.customScoringWeights
		? testInfo.customScoringWeights
		: calculateAutoScoringTagWeights(testInfo.questions)

	return (
		<div
			style={{
				display: 'flex',
				height: '100%',
				width: '100%',
				alignItems: 'center',
				justifyContent: 'start',
				flexDirection: 'column',
			}}
		>
			<div style={{ width: '600px', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', padding: '30px 0 4vh' }}>
				<Typography textAlign='left'>
					Create a custom scoring breakdown for your test by adjusting the sliders below. These values can be updated after the
					test is created and candidates have applied.
				</Typography>
				<Divider sx={{ width: 600, margin: '30px 0' }} />
				<SliderGroup group={initialWeights} setGroup={(newGroup) => setTestInfo({ ...testInfo, customScoringWeights: newGroup })} />
			</div>
		</div>
	)
}

export default ScoringWeightsPage
