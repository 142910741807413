import { createAccessCode, updateAccessCode, updateClient, updateUser, deleteErrorLog } from '../graphql/mutations'
import { reduxAddAccessCode, reduxDeleteErrorLog, reduxUpdateAccessCode, reduxUpdateClient, reduxUpdateUser } from '../actions'

import { API } from 'aws-amplify'

function deleteInvalidAWSAttributes(item) {
	delete item._deleted
	delete item.updatedAt
	delete item._lastChangedAt
	delete item.createdAt
}

/**
 * This function returns an updatedUser object with only the attributes that are allowed to be updated. For
 * example, we must pass in a testID if we are updating a user in the redux store. But, we cannot pass the testID
 * attribute to the graphql update function as clients are not allowed to update this attribute.
 *
 * @param {Object} updatedUser - The updated user with any attributes
 * @returns An updatedUser object that we can pass to the graphql function.
 */
function getValidDBUpdatedUserAttributes(updatedUser) {
	const dbUpdatedUser = {
		id: updatedUser.id,
	}

	if (updatedUser.hasOwnProperty('shortlisted')) {
		dbUpdatedUser.shortlisted = updatedUser.shortlisted
	}

	if (updatedUser.hasOwnProperty('clientComments')) {
		dbUpdatedUser.clientComments = updatedUser.clientComments
	}

	return dbUpdatedUser
}

export async function updateDBUser(updatedUser, dispatch) {
	deleteInvalidAWSAttributes(updatedUser)

	const dbUpdatedUser = getValidDBUpdatedUserAttributes(updatedUser)

	try {
		await API.graphql({
			query: updateUser,
			variables: { input: dbUpdatedUser },
			authMode: 'AMAZON_COGNITO_USER_POOLS',
		})

		dispatch(reduxUpdateUser(updatedUser))
	} catch (e) {
		console.error('Error updating user in the database.', e)
	}
}

export async function updateDBAccessCode(updatedCode, dispatch) {
	try {
		deleteInvalidAWSAttributes(updatedCode)

		await API.graphql({
			query: updateAccessCode,
			variables: { input: updatedCode },
			authMode: 'AMAZON_COGNITO_USER_POOLS',
		})

		dispatch(reduxUpdateAccessCode(updatedCode))
	} catch (e) {
		console.error('ERROR UPDATING ACCESS CODE STATUS', e)
	}
}

export async function updateDBClient(updatedClient, dispatch) {
	try {
		deleteInvalidAWSAttributes(updatedClient)

		await API.graphql({
			query: updateClient,
			variables: { input: updatedClient },
			authMode: 'AMAZON_COGNITO_USER_POOLS',
		})

		dispatch(reduxUpdateClient(updatedClient))
	} catch (e) {
		console.error('ERROR UPDATING CLIENT', e)
	}
}

export async function createDBAccessCode(code, dispatch) {
	try {
		const createdCode = await API.graphql({
			query: createAccessCode,
			variables: { input: code },
			authMode: 'AMAZON_COGNITO_USER_POOLS',
		})

		dispatch(reduxAddAccessCode(createdCode.data.createAccessCode))

		return createdCode.data.createAccessCode
	} catch (e) {
		console.error('ERROR CREATING ACCESS CODE', e)
	}
}

export async function deleteDBErrorLog(logID, dispatch) {
	try {
		await API.graphql({
			query: deleteErrorLog,
			variables: { input: { id: logID } },
			authMode: 'AMAZON_COGNITO_USER_POOLS',
		})

		dispatch(reduxDeleteErrorLog(logID))
	} catch (e) {
		console.error('ERROR CREATING ACCESS CODE', e)
	}
}
