import { useRef } from 'react'
import { TextField, Typography, Divider } from '@mui/material'

import { findQuestionText, updateQuestionText, onTabPress } from '../Functions'
import TargetYears from '../ScoringTags/TargetYears'

const styles = {
	container: {
		display: 'flex',
		height: '100%',
		width: '100%',
		alignItems: 'center',
		flexDirection: 'column',
		paddingTop: 30,
		boxSizing: 'border-box',
	},
}

function YourExperience(props) {
	const ref = useRef()

	return (
		<div style={styles.container}>
			<div style={{ width: 600, display: 'flex', alignItems: 'start', flexDirection: 'column' }}>
				<Typography textAlign='left'>
					Please select the ideal number of years of experience you would like the candidates to have for this role. If there is a
					range, select the midpoint. For example: 1 to 2 years = 1.5
				</Typography>

				<Divider sx={{ margin: '30px 0', width: '100%' }} />

				<TextField
					variant='outlined'
					inputProps={{ style: { fontSize: 14 } }}
					InputLabelProps={{ style: { fontSize: 14 } }}
					label='Question Text'
					multiline
					style={{ resize: 'none', height: 80, width: 600, fontSize: 16 }}
					defaultValue={findQuestionText('YourExperience1', props.testInfo.questions)}
					onBlur={(e) => updateQuestionText('YourExperience1', e.target.value, props.testInfo, props.setTestInfo)}
					ref={ref}
					onKeyDown={(e) => {
						if (e.key === 'Tab') {
							onTabPress(ref, e)
						}
					}}
				/>

				<TargetYears questionID='YourExperience1' testInfo={props.testInfo} setTestInfo={props.setTestInfo} />
			</div>
		</div>
	)
}

export default YourExperience
