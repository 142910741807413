import React, { useState } from 'react'
import MenuButton from '../MenuButton/BannerMenuButton'
import InfoTooltip from './InfoTooltip'

/**
 * A component for display quick tooltip information when a button is clicked. Tooltip disappears when mouse moves from button.
 */
function InfoTooltipButton(props) {
	const [showTooltip, setShowTooltip] = useState(false)

	const { tooltipText, ...buttonProps } = props

	return (
		<div style={{ position: 'relative' }}>
			<MenuButton
				{...buttonProps}
				onClick={() => {
					setShowTooltip(true)
				}}
				onMouseLeave={() => setShowTooltip(false)}
			/>
			{showTooltip && <InfoTooltip tooltipText={tooltipText} />}
		</div>
	)
}

export default InfoTooltipButton
