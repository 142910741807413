import React, { useState, useContext } from 'react'
import { Typography, TextField, Button } from '@mui/material'
import Pool from '../../UserPool'
import { CognitoUser } from '@aws-amplify/auth'
import { AccountContext } from './Account'
import styles from './Account.module.css'
import BlueButton from '../Button/BlueButton'

function ForgotPassword({ setUser }) {
	const [email, setEmail] = useState('')
	const { setLoginStage } = useContext(AccountContext)

	const onSubmit = () => {
		const user = new CognitoUser({ Username: email, Pool })
		setUser(user)
		user.forgotPassword({
			onSuccess: () => {
				setLoginStage('ResetPassword')
			},
			onFailure: (err) => {
				console.error(err)
			},
		})
	}

	return (
		<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
			<Typography className={styles['instruction-text']}>Please enter the email that is used to login to your account.</Typography>
			<TextField className={styles['text-input']} value={email} onChange={(e) => setEmail(e.target.value)} helperText='Email' />
			<div style={{ display: 'flex', flexDirection: 'column' }}>
				<BlueButton className={styles['submit-button']} onClick={onSubmit}>
					Send Verification Code
				</BlueButton>
				<Button variant='text' className={styles['small-button']} onClick={() => setLoginStage('LoggedOut')}>
					Cancel
				</Button>
			</div>
		</div>
	)
}

export default ForgotPassword
