import React, { createContext, useContext, useEffect, useState } from 'react'
import { TestContext } from '../TestContext'
import { getAllCandidateTableData } from '../../../lib/candidates'

const CandidatesContext = createContext()

/**
 * @param {*} props.filterFunction - A base filter to be used to exclude candidates that we never want for this list. For example,
 * 									 excluding candidates that are not shortlisted for the executive report table.
 */
function CandidatesContextWrapper(props) {
	const { test, candidateList } = useContext(TestContext)

	// The index of the current candidate in the candidate list
	const [index, setIndex] = useState(0)
	const [candidate, setCandidate] = useState()

	// table: The view of candidate table
	// candidate: The view after clicking on a user
	// responses: The view of a candidates responses
	const [view, setView] = useState('table')

	const [rows, setRows] = useState([])
	const [origRows, setOrigRows] = useState([])

	useEffect(() => {
		setView('table')
		setIndex(0)
	}, [test])

	useEffect(() => {
		const tableData = getAllCandidateTableData(candidateList, test.totalScore)
		const filteredData = []

		for (const candidate of tableData) {
			if (props.filterFunction(candidate)) filteredData.push(candidate)
		}

		setOrigRows([...filteredData])

		// eslint-disable-next-line
	}, [test, candidateList])

	useEffect(() => {
		setCandidate(rows[Math.min(rows.length - 1, index)])

		// eslint-disable-next-line
	}, [index, rows])

	function changeIndex(type) {
		if (type === 'increase') {
			if (index + 1 >= rows.length) return
			setIndex(index + 1)
		} else if (type === 'decrease') {
			if (index - 1 < 0) return
			setIndex(index - 1)
		}
	}

	return (
		<CandidatesContext.Provider
			value={{ index, setIndex, view, setView, candidate, setCandidate, origRows, rows, setRows, changeIndex }}
		>
			{props.children}
		</CandidatesContext.Provider>
	)
}

export { CandidatesContextWrapper, CandidatesContext }
