import React, { useContext, useState, useEffect } from 'react'
import styles from './Candidates.module.css'

import { CandidatesContext } from './CandidatesContext'

import SearchBar from '../../SearchBar/SearchBar'
import CandidateTable from '../CandidateTable'
import Breakdown from './Breakdown/Breakdown'
import ResponsesView from './Breakdown/ResponsesView'
import FilterBar from '../../Filter/FilterBar'

import { TestContext } from '../TestContext'

import { applyFilters } from '../../Filter/Data'
import { Card } from '@mui/material'

function Candidates() {
	const { view, rows, setRows, origRows } = useContext(CandidatesContext)

	// Stores the list of current filters for a test
	const { candidatesFilterList, setCandidatesFilterList } = useContext(TestContext)

	const [searchValue, setSearchValue] = useState('') // Uses with SearchBar component

	useEffect(() => {
		const validRows = applyFilters(candidatesFilterList, origRows)

		// Removes rows that don't match the search term
		for (let i = validRows.length - 1; i >= 0; i--) {
			if (!validRows[i].name.toLowerCase().includes(searchValue.toLowerCase())) {
				validRows.splice(i, 1)
			}
		}
		setRows(validRows)

		// eslint-disable-next-line
	}, [candidatesFilterList, searchValue, origRows])

	return (
		<Card className={styles['container']} style={{ background: view === 'table' ? 'white' : 'transparent' }} elevation={0}>
			{view === 'table' && (
				<>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							width: '100%',
							justifyContent: 'space-between',
							padding: '10px 20px',
							boxSizing: 'border-box',
						}}
					>
						<FilterBar filterList={candidatesFilterList} setFilterList={setCandidatesFilterList} />
						<SearchBar searchValue={searchValue} setSearchValue={setSearchValue} />
					</div>
					<CandidateTable rows={rows} origRows={origRows} setRows={setRows} />
				</>
			)}

			{view === 'candidate' && <Breakdown />}

			{view === 'responses' && <ResponsesView />}
		</Card>
	)
}

export default Candidates
