import React, { useContext, useEffect, useState } from 'react'
// import { useSelector } from 'react-redux'
import { TestContext } from '../TestContext'
import styles from './ManageAccess.module.css'
import { Paper } from '@mui/material'

/**
 * @param {[Object]} codeList - The list of codes for the current test
 * @returns {string} A string in the format 'x/y' where x is the total number of uses for the codes in codeList
 * 					 and y is the total number of available uses for the codes in codelist
 */
function getRemainingCodeString(codeList) {
	let totalUsed = 0
	let totalAvailable = 0

	for (const code of codeList) {
		totalUsed += code.timesUsed
		totalAvailable += code.numAllowed
	}

	return `${totalAvailable - totalUsed} / ${totalAvailable}`
}

/**
 *
 * @param {[Object]} codeList - The list of codes for the current test
 * @returns {Integer} The number of codes that are full and still open
 */
function countFullCodes(codeList) {
	let numFull = 0

	for (const code of codeList) {
		if (code.status === 'open' && code.timesUsed === code.numAllowed) {
			numFull++
		}
	}

	return numFull
}

function Info() {
	const { codeList } = useContext(TestContext)

	// const client = useSelector((state) => state.client)

	const [remainingString, setRemainingString] = useState('')
	const [numFull, setNumFull] = useState(0)

	useEffect(() => {
		setRemainingString(getRemainingCodeString(codeList))
		setNumFull(countFullCodes(codeList))
	}, [codeList])

	return (
		<Paper
			style={{
				background: 'white',
				padding: '13px 30px',
				display: 'flex',
				gap: 60,
				alignItems: 'center',
				width: 'fit-content',
			}}
		>
			<div className={styles['metric']}>
				<span className={styles['title']}>Total Codes</span>
				<span>{codeList.length}</span>
			</div>

			{/* Commenting this out as we no longer want to limit how many codes a client can create */}
			{/* <div className={styles['metric']}>
				<span className={styles['title']}>Available</span>
				<span>{client.role === 'MASTER' ? 'N/A' : client.remainingCodeUses}</span>
			</div> */}

			<div className={styles['metric']}>
				<span className={styles['title']}>Remaining</span>
				<span>{remainingString}</span>
			</div>

			<div className={styles['metric']}>
				<span className={styles['title']}>Full</span>
				<span style={{ color: numFull > 0 ? '#cc0000' : '#32A846' }}>{numFull}</span>
			</div>
		</Paper>
	)
}

export default Info
