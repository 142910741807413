import { getCandidateWeightedScoreValue } from './candidates'
import { convertToHMTimeString } from './date-time'

/**
 * Calculates the average scores for the case study questions of a test.
 *
 * @param {[]} questionList - List of questions for the test
 * @param {[]} candidateList - list of candidates for the test
 * @param {Object} alluserResponses - all userResponses in an object using userID as keys
 * @returns The average score for each case study question, in an object with questionID's as the keys
 */
export function calculateAverageCSScores(questionList, candidateList, allUserResponses) {
	const relevantUserResponses = getUserResponsesForTest(allUserResponses, candidateList)
	const averageScores = {}

	// Gets the case study questions
	const csQuestions = getCaseStudyQuestions(questionList)

	for (const question of csQuestions) {
		averageScores[question.id] = 0
	}

	// Sums up scores of responses from all users
	for (const userID in relevantUserResponses) {
		for (const response of relevantUserResponses[userID]) {
			if (averageScores[response.questionID] !== undefined) {
				averageScores[response.questionID] += response.score
			}
		}
	}

	for (const key in averageScores) {
		averageScores[key] /= candidateList.length
	}

	return averageScores
}

/**
 * Gets the user responses for just the users of the current test.
 *
 * @param {Object} allUserResponses - all userResponses in an object using userID as keys
 * @param {[]} candidateList - list of candidates for the current test
 * @returns Just the users and their responses that are part of the current test
 */
function getUserResponsesForTest(allUserResponses, candidateList) {
	const relevantUserResponses = {}

	for (const user of candidateList) {
		if (allUserResponses[user.id]) {
			relevantUserResponses[user.id] = allUserResponses[user.id]
		}
	}

	return relevantUserResponses
}

/**
 * Gets the case study questions for a test.
 *
 * @param {[Object]} questionList - List of questions for a test
 * @returns The list of questions that are a part of the case study
 */
export function getCaseStudyQuestions(questionList) {
	if (!questionList) return []

	const csQuestions = []

	// Gets the case study questions
	for (const question of questionList) {
		if (
			question.section === 'CaseStudy' &&
			(question.format === 'TimedMultipleChoice' || question.format === 'Memo' || question.format === 'TimedCheckbox')
		) {
			csQuestions.push(question)
		}
	}

	csQuestions.sort(function (a, b) {
		return a.order - b.order
	})

	return csQuestions
}

/**
 * Gets a list of scoring tags from all the questions of a test.
 *
 * @param {[]} testQuestions - The list of questions for the test
 * @param {[]} allTags - The list of all scoring tags
 * @returns All scoring tags for the questions of a test in a list
 */
export function getScoringTagsForTest(testQuestions, allTags) {
	const testTags = []
	const questionIDs = testQuestions.map((question) => question.id)

	for (const tag of allTags) {
		if (questionIDs.includes(tag.questionID)) testTags.push(tag)
	}

	return testTags
}

/**
 * @param {Object} applicantLists - Lists of candidates where each key is a testID for the corresponding candidate list
 * @param {Object} test - The test that you would like to get the candidate list for
 * @param {Object} scoringTagResponsesByUserID - scoringTagResponses that are organized by candidateID
 * @param {[]} scoringTags - The list of all scoringTags
 * @param {*} maxTagScores - The maximum score for each dataMapping type.
 * @returns A list of candidates for a test with the weightedScores calculated
 */
export function getCandidateListForTest(applicantLists, test, scoringTagResponsesByUserID, scoringTags, maxTagScores) {
	if (!applicantLists || !test) {
		console.error('Error: Invalid/missing argument(s).')
		return []
	}

	if (!applicantLists[test.id]) {
		return []
	}

	const candidateList = [...applicantLists[test.id]]

	for (const candidate of candidateList) {
		candidate.weightedScore = getCandidateWeightedScoreValue(
			scoringTagResponsesByUserID[candidate.id],
			scoringTags,
			maxTagScores,
			candidate.score,
			JSON.parse(test.customScoringWeights)
		)
	}

	return candidateList
}

/**
 * @param {[Object]} candidateList - List of candidates
 * @returns The highest score for the test, and the average score, in base values, not in percentages.
 * To get the percentage score, one would have to take the return values and divide by the total score of the test.
 */
export function getTestHighestAndAverageScore(candidateList) {
	if (candidateList.length === 0) {
		return { avgScore: 0, highestScore: 0 }
	}

	let totalScore = 0
	let maxScore = -1

	for (const candidate of candidateList) {
		const score = candidate.weightedScore
		if (score > maxScore) maxScore = score
		totalScore += score
	}

	return { avgScore: totalScore / candidateList.length, highestScore: maxScore }
}

/**
 * @param {[Object]} candidateList - List of candidates
 * @returns The average time taken to complete the test, and the average time taken to complete the case study,
 * in one object { avgTest, avgCS }
 */
export function getTestandCSAverageTime(candidateList) {
	if (candidateList.length === 0) {
		return { avgTest: '0m', avgCS: '0m' }
	}

	const times = { avgTest: 0, avgCS: 0 }

	for (const candidate of candidateList) {
		times.avgTest += candidate.testTime
		times.avgCS += candidate.caseStudyTime
	}

	times.avgTest /= candidateList.length
	times.avgCS /= candidateList.length

	times.avgTest = convertToHMTimeString(times.avgTest)
	times.avgCS = convertToHMTimeString(times.avgCS)

	return times
}
