export const changeClient = (newItem) => {
	return {
		type: 'CHANGECLIENT',
		payload: newItem,
	}
}

export const changeApplicantLists = (newItem) => {
	return {
		type: 'CHANGEAPPLICANTLISTS',
		payload: newItem,
	}
}

export const changeTestList = (newItem) => {
	return {
		type: 'CHANGETESTLIST',
		payload: newItem,
	}
}

export const changeJobsInfo = (newItem) => {
	return {
		type: 'CHANGEJOBSINFO',
		payload: newItem,
	}
}

export const changeLoadedData = (newItem) => {
	return {
		type: 'CHANGELOADEDDATA',
		payload: newItem,
	}
}

export const changeAllUserResponses = (newItem) => {
	return {
		type: 'CHANGEALLUSERRESPONSES',
		payload: newItem,
	}
}

export const changeQuestionLists = (newItem) => {
	return {
		type: 'CHANGEQUESTIONLISTS',
		payload: newItem,
	}
}

export const changeAccessCodes = (newItem) => {
	return {
		type: 'CHANGEACCESSCODES',
		payload: newItem,
	}
}

export const changeScoringTags = (newItem) => {
	return {
		type: 'CHANGESCORINGTAGS',
		payload: newItem,
	}
}

export const changeScoringTagResponses = (newItem) => {
	return {
		type: 'CHANGESCORINGTAGRESPONSES',
		payload: newItem,
	}
}

export const changeQuestionAnswersLists = (newItem) => {
	return {
		type: 'CHANGEQUESTIONANSWERSLISTS',
		payload: newItem,
	}
}

export const changeClientList = (newItem) => {
	return {
		type: 'CHANGECLIENTLIST',
		payload: newItem,
	}
}

export const changeScoringTagResponsesByUserID = (newItem) => {
	return {
		type: 'CHANGESCORINGTAGRESPONSESBYUSERID',
		payload: newItem,
	}
}

export const reduxUpdateAccessCode = (newItem) => {
	return {
		type: 'updateAccessCode',
		payload: newItem,
	}
}

export const reduxUpdateUser = (newItem) => {
	return {
		type: 'updateUser',
		payload: newItem,
	}
}

export const reduxUpdateClient = (newItem) => {
	return {
		type: 'updateClient',
		payload: newItem,
	}
}

export const reduxAddAccessCode = (newItem) => {
	return {
		type: 'addCode',
		payload: newItem,
	}
}

export const changeErrorLogs = (newItem) => {
	return {
		type: 'CHANGEERRORLOGS',
		payload: newItem,
	}
}

export const reduxDeleteErrorLog = (logID) => {
	return {
		type: 'deleteErrorLog',
		payload: logID,
	}
}
