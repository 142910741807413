import React, { useState, useRef, useContext, useEffect } from 'react'
import styles from './ManageAccess.module.css'
import Table from '../../Table/Table'
import BannerMenuButton from '../../MenuButton/BannerMenuButton'

// import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle'
import AddIcon from '@mui/icons-material/Add'
import { TestContext } from '../TestContext'
import { updateDBAccessCode } from '../../../lib/database'
import { useDispatch, useSelector } from 'react-redux'
import EditUsesMenu from './EditUsesMenu'
import { Paper } from '@mui/material'

const columns = [
	{
		label: 'Code',
		sortingKey: 'code',
		sortDscFunc: function (a, b) {
			let aName = a.code.toLowerCase()
			let bName = b.code.toLowerCase()
			if (aName > bName) return 1
			if (aName < bName) return -1
			return 0
		},
		sortAscFunc: function (a, b) {
			let aName = a.code.toLowerCase()
			let bName = b.code.toLowerCase()
			if (aName > bName) return -1
			if (aName < bName) return 1
			return 0
		},
	},
	{
		label: 'Number of Uses',
		sortingKey: 'uses',
		sortDscFunc: function (a, b) {
			return parseInt(a.uses) - parseInt(b.uses)
		},
		sortAscFunc: function (a, b) {
			return parseInt(b.uses) - parseInt(a.uses)
		},
	},
	{
		label: 'Allowed Uses',
		sortingKey: 'allowed',
		sortDscFunc: function (a, b) {
			return parseInt(a.allowedUses) - parseInt(b.allowedUses)
		},
		sortAscFunc: function (a, b) {
			return parseInt(b.allowedUses) - parseInt(a.allowedUses)
		},
	},
	{
		label: 'Status / Changed Status',
		sortingKey: 'status',
		sortDscFunc: function (a, b) {
			if (a.status === b.status) return 0
			if (a.status === 'open') return -1
			return 1
		},
		sortAscFunc: function (a, b) {
			if (a.status === b.status) return 0
			if (a.status === 'open') return 1
			return -1
		},
	},
	{
		label: 'Created By',
		sortingKey: 'createdBy',
		sortDscFunc: function (a, b) {
			let aName = null
			let bName = null

			if (a.createdBy) {
				aName = a.createdBy.name.toLowerCase()
			}
			if (b.createdBy) {
				bName = b.createdBy.name.toLowerCase()
			}
			if (bName === null) return -1
			if (aName < bName) return -1
			if (aName > bName) return 1
			return 0
		},
		sortAscFunc: function (a, b) {
			let aName = null
			let bName = null

			if (a.createdBy) {
				aName = a.createdBy.name.toLowerCase()
			}
			if (b.createdBy) {
				bName = b.createdBy.name.toLowerCase()
			}
			if (bName === null) return -1
			if (aName < bName) return 1
			if (aName > bName) return -1
			return 0
		},
	},
]

// const testData = [
// 	{ code: 'test1', uses: '10', allowedUses: '30', status: 'open' },
// 	{ code: 'test2', uses: '15', allowedUses: '35', status: 'closed' },
// 	{ code: 'test3', uses: '20', allowedUses: '40', status: 'open' },
// 	{ code: 'test4', uses: '25', allowedUses: '45', status: 'closed' },
// ]

const sortingData = {
	code: 'none',
	uses: 'none',
	allowed: 'none',
	status: 'none',
	createdBy: 'none',
}

function CodeTable({ setShowCreateMenu }) {
	const client = useSelector((state) => state.client)
	const { codeList } = useContext(TestContext)

	const [rows, setRows] = useState([])
	const origRows = useRef([])

	const [selectedCode, setSelectedCode] = useState()

	const dispatch = useDispatch()

	useEffect(() => {
		setRows([...codeList])
		origRows.current = [...codeList]
	}, [codeList])

	function formatRow(row, index) {
		const { id, code, timesUsed, numAllowed, status, createdBy } = row

		return (
			<tr key={index}>
				<td>{code}</td>
				<td>{timesUsed}</td>
				<td>
					<BannerMenuButton
						onClick={() => {
							setSelectedCode({
								id,
								timesUsed,
								codeName: code,
								numAllowed,
							})
						}}
						customclasses={styles['allowed-uses-button']}
						style={{ margin: 'auto', borderRadius: 10, padding: '0px 30px', color: 'var(--button-text)' }}
					>
						{numAllowed}
					</BannerMenuButton>{' '}
				</td>
				<td align='center'>
					<BannerMenuButton
						onClick={() => {
							const updatedCode = { id: row.id, status: row.status === 'open' ? 'closed' : 'open' }
							// if (row.status === 'open') row.status = 'closed'
							// else row.status = 'open'

							updateDBAccessCode(updatedCode, dispatch)
						}}
					>
						{status === 'open' ? (
							<span style={{ color: '#32a846', fontWeight: 'var(--bold)' }}>Open</span>
						) : (
							<span style={{ color: '#d13313', fontWeight: 'var(--bold)' }}>Closed</span>
						)}
					</BannerMenuButton>
				</td>
				<td>
					{createdBy &&
						(createdBy.id === client.id ? (
							<span style={{ fontWeight: 'var(--bold)', color: 'var(--primary-color-one)' }}>You</span>
						) : (
							<span>{createdBy.name}</span>
						))}
				</td>
			</tr>
		)
	}
	return (
		<Paper
			style={{
				background: 'white',
				// padding: '20px 30px',
				display: 'flex',
				flexDirection: 'column',
				gap: 10,
				alignItems: 'start',
				flex: '1 1 auto',
			}}
		>
			<div className={styles['table-button-row']}>
				<BannerMenuButton customclasses={styles['test']} onClick={() => setShowCreateMenu(true)}>
					Create Code <AddIcon style={{ width: 25, height: 25, color: '#001940', marginLeft: 8 }} />
				</BannerMenuButton>
				{/* <BannerMenuButton customclasses={styles['test']}>
					Actions <ArrowDropDownCircleIcon style={{ width: 22, height: 22, color: '#001940', opacity: 0.8, marginLeft: 8 }} />
				</BannerMenuButton> */}
			</div>
			<Table
				columns={columns}
				rows={rows}
				setRows={setRows}
				origRows={origRows.current}
				formatRow={formatRow}
				sortingData={sortingData}
			/>
			{selectedCode && (
				<EditUsesMenu
					code={selectedCode}
					onClose={() => {
						setSelectedCode(null)
					}}
				/>
			)}
		</Paper>
	)
}

export default CodeTable
