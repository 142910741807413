export const baseQuestions = [
	/* Privacy Policy
########################################################################################################################################################################
########################################################################################################################################################################
########################################################################################################################################################################
*/

	{
		id: 'PrivacyPolicy',
		format: 'none',
		questionText: '',
		section: 'PrivacyPolicy',
		hasNotes: true,
		helperText: '',
		maxScore: 3,
		order: 1,
		referenceID: 'PrivacyPolicy',
		scoringTags: [
			{
				tag: 'PrivacyPolicy',
				data: 'none',
				dataMapping: 'AttentionToDetail',
				maxScore: 3,
			},
		],
	},

	/* About Yourself
########################################################################################################################################################################
########################################################################################################################################################################
########################################################################################################################################################################
*/

	{
		id: 'AboutYourself',
		format: 'LongResponse',
		questionText: 'In two short paragraphs please tell us about your industry specific experience and your career goals.',
		section: 'AboutYourself',
		hasNotes: false,
		helperText: '',
		maxScore: 10,
		order: 1,
		referenceID: 'ef9c91d8-27ce-46e5-9fd5-bd981db968e3',
		scoringTags: [
			{
				tag: 'FleschKincaid',
				data: 'none',
				dataMapping: 'CommunicationSkills',
				maxScore: 10,
			},
		],
	},

	/* Your Experience
########################################################################################################################################################################
########################################################################################################################################################################
########################################################################################################################################################################
*/

	{
		id: 'YourExperience1',
		format: 'ShortResponse',
		questionText: 'How many years of relevant industry experience do you have?',
		section: 'YourExperience',
		hasNotes: false,
		helperText: 'Enter Number',
		maxScore: 0,
		order: 1,
		referenceID: 'eb3c4c0c-7a16-4c33-9eec-fad41cb9d5f9',
		scoringTags: [],
	},

	// ########################################################################################################################################
	// ########################################################################################################################################

	{
		id: 'YourExperience2',
		format: 'ShortResponse',
		questionText: 'When did you start your most recent position?',
		section: 'YourExperience',
		hasNotes: false,
		helperText: 'YYYY-MM',
		maxScore: 1,
		order: 2,
		referenceID: 'ce1ecf75-ae70-4eba-b2de-9675a40aa3dd',
		scoringTags: [
			{
				tag: 'MatchDate',
				data: 'none',
				dataMapping: 'AttentionToDetail',
				maxScore: 1,
			},
		],
	},

	// ########################################################################################################################################
	// ########################################################################################################################################

	{
		id: 'YourExperience3',
		format: 'MultipleChoice',
		questionText: 'Is your real estate experience in ICI?',
		section: 'YourExperience',
		hasNotes: false,
		helperText: '',
		maxScore: 1,
		order: 3,
		referenceID: '1d0554af-865b-492b-8294-54c0f275d07f',
		questionAnswers: [
			{
				answerText: 'Yes',
				score: 1,
				isCorrect: false,
			},
			{
				answerText: 'No',
				score: 0,
				isCorrect: false,
			},
		],
		scoringTags: [
			{
				tag: 'MatchResponse',
				data: 'Yes',
				dataMapping: 'PositionRequirements',
				maxScore: 1,
			},
			{
				tag: 'MatchResponse',
				data: 'No',
				dataMapping: 'PositionRequirements',
				maxScore: 1,
			},
		],
	},

	// ########################################################################################################################################
	// ########################################################################################################################################

	{
		id: 'YourExperience4',
		format: 'Checkbox',
		questionText: 'Do you have intermediate to advanced experience in each of the following (select all that apply):',
		section: 'YourExperience',
		hasNotes: false,
		helperText: '',
		maxScore: 8.5,
		order: 4,
		referenceID: 'd6a891b1-b9b9-4309-884f-fbdb9f1f395c',
		questionAnswers: [
			{
				answerText: 'Cash Flow Modeling',
				score: 1.5,
				isCorrect: false,
			},
			{
				answerText: 'Argus Enterprise',
				score: 2,
				isCorrect: false,
			},
			{
				answerText: 'Property Valuation',
				score: 2,
				isCorrect: false,
			},
			{
				answerText: 'Excel',
				score: 1,
				isCorrect: false,
			},
			{
				answerText: 'Reporting and Presentations',
				score: 2,
				isCorrect: false,
			},
		],
		scoringTags: [
			{
				tag: 'MatchResponse',
				data: 'Cash Flow Modeling',
				dataMapping: 'PositionRequirements',
				maxScore: 1.5,
			},
			{
				tag: 'MatchResponse',
				data: 'Argus Enterprise',
				dataMapping: 'PositionRequirements',
				maxScore: 2,
			},
			{
				tag: 'MatchResponse',
				data: 'Property Valuation',
				dataMapping: 'PositionRequirements',
				maxScore: 2,
			},
			{
				tag: 'MatchResponse',
				data: 'Excel',
				dataMapping: 'PositionRequirements',
				maxScore: 1,
			},
			{
				tag: 'MatchResponse',
				data: 'Reporting and Presentations',
				dataMapping: 'PositionRequirements',
				maxScore: 2,
			},
		],
	},

	// ########################################################################################################################################
	// ########################################################################################################################################

	{
		id: 'YourExperience5',
		format: 'Checkbox',
		questionText: 'Which of the following asset classes do you have experience with (select all that apply):',
		section: 'YourExperience',
		hasNotes: false,
		helperText: '',
		maxScore: 9,
		order: 5,
		referenceID: 'f64cedc3-26cd-4c85-8609-d1f93b1fa231',
		questionAnswers: [
			{
				answerText: 'Office',
				score: 1,
				isCorrect: false,
			},
			{
				answerText: 'Land',
				score: 1.5,
				isCorrect: false,
			},
			{
				answerText: 'Multi-Family Residential',
				score: 1,
				isCorrect: false,
			},
			{
				answerText: 'Mixed-Use',
				score: 2,
				isCorrect: false,
			},
			{
				answerText: 'Retail',
				score: 2,
				isCorrect: false,
			},
			{
				answerText: 'Special Purpose',
				score: 1,
				isCorrect: false,
			},
			{
				answerText: 'Industrial',
				score: 0.5,
				isCorrect: false,
			},
		],
		scoringTags: [
			{
				tag: 'MatchResponse',
				data: 'Office',
				dataMapping: 'PositionRequirements',
				maxScore: 1,
			},
			{
				tag: 'MatchResponse',
				data: 'Land',
				dataMapping: 'PositionRequirements',
				maxScore: 1.5,
			},
			{
				tag: 'MatchResponse',
				data: 'Multi-Family Residential',
				dataMapping: 'PositionRequirements',
				maxScore: 1,
			},
			{
				tag: 'MatchResponse',
				data: 'Mixed-Use',
				dataMapping: 'PositionRequirements',
				maxScore: 2,
			},
			{
				tag: 'MatchResponse',
				data: 'Retail',
				dataMapping: 'PositionRequirements',
				maxScore: 2,
			},
			{
				tag: 'MatchResponse',
				data: 'Special Purpose',
				dataMapping: 'PositionRequirements',
				maxScore: 1,
			},
			{
				tag: 'MatchResponse',
				data: 'Industrial',
				dataMapping: 'PositionRequirements',
				maxScore: 0.5,
			},
		],
	},

	// ########################################################################################################################################
	// ########################################################################################################################################

	{
		id: 'YourExperience6',
		format: 'Checkbox',
		questionText: 'Do you have experience directly working for (select all that apply):',
		section: 'YourExperience',
		hasNotes: false,
		helperText: '',
		maxScore: 6,
		order: 6,
		referenceID: 'da5ec9ce-da8a-49d2-a8a7-4a9113909334',
		questionAnswers: [
			{
				answerText: 'Investment Manager',
				score: 1,
				isCorrect: false,
			},
			{
				answerText: 'Brokerage Firm',
				score: 1,
				isCorrect: false,
			},
			{
				answerText: 'Private Equity Investor',
				score: 1,
				isCorrect: false,
			},
			{
				answerText: 'REIT',
				score: 1,
				isCorrect: false,
			},
			{
				answerText: 'Pension Fund',
				score: 1,
				isCorrect: false,
			},
			{
				answerText: 'Appraisal Firm',
				score: 1,
				isCorrect: false,
			},
			{
				answerText: 'Real Estate Lender/Bank',
				score: 1,
				isCorrect: false,
			},
		],
		scoringTags: [
			{
				tag: 'MatchResponse',
				data: 'Investment Manager',
				dataMapping: 'PositionRequirements',
				maxScore: 1,
			},
			{
				tag: 'MatchResponse',
				data: 'Brokerage Firm',
				dataMapping: 'PositionRequirements',
				maxScore: 1,
			},
			{
				tag: 'MatchResponse',
				data: 'Private Equity Investor',
				dataMapping: 'PositionRequirements',
				maxScore: 1,
			},
			{
				tag: 'MatchResponse',
				data: 'REIT',
				dataMapping: 'PositionRequirements',
				maxScore: 1,
			},
			{
				tag: 'MatchResponse',
				data: 'Pension Fund',
				dataMapping: 'PositionRequirements',
				maxScore: 1,
			},
			{
				tag: 'MatchResponse',
				data: 'Appraisal Firm',
				dataMapping: 'PositionRequirements',
				maxScore: 1,
			},
		],
	},

	/* Knowledge Assessment
########################################################################################################################################################################
########################################################################################################################################################################
########################################################################################################################################################################
*/

	{
		id: 'KnowledgeAssessment1',
		format: 'MultipleChoice',
		questionText:
			'In commercial real estate investment, the amount resulting from discounting the future cash flows of lease and rental payments plus capital appreciation represents the value of the:',
		section: 'KnowledgeAssessment',
		hasNotes: false,
		helperText: '',
		maxScore: 0,
		order: 1,
		referenceID: 'e26a3a0d-db3a-46b1-9dbd-066d02f83e7b',
		questionAnswers: [
			{
				answerText: 'long-term liability',
				score: 0,
				isCorrect: false,
			},
			{
				answerText: 'asset',
				score: 0,
				isCorrect: false,
			},
			{
				answerText: 'short-term liability',
				score: 0,
				isCorrect: false,
			},
			{
				answerText: 'equity',
				score: 0,
				isCorrect: false,
			},
		],
		scoringTags: [
			{
				tag: 'MatchResponse',
				data: 'long-term liability',
				dataMapping: 'PositionRequirements',
				maxScore: 0,
			},
			{
				tag: 'MatchResponse',
				data: 'asset',
				dataMapping: 'PositionRequirements',
				maxScore: 0,
			},
			{
				tag: 'MatchResponse',
				data: 'short-term liability',
				dataMapping: 'PositionRequirements',
				maxScore: 0,
			},
			{
				tag: 'MatchResponse',
				data: 'equity',
				dataMapping: 'PositionRequirements',
				maxScore: 0,
			},
		],
	},

	// ########################################################################################################################################
	// ########################################################################################################################################

	{
		id: 'KnowledgeAssessment2',
		format: 'MultipleChoice',
		questionText:
			'Which of the following government acts will most likely lead to an increase in the level of aggregate demand? A reduction in:',
		section: 'KnowledgeAssessment',
		hasNotes: false,
		helperText: '',
		maxScore: 0,
		order: 2,
		referenceID: '5eb9f486-2379-4ae2-8294-7b4dc953cf47',
		questionAnswers: [
			{
				answerText: 'transfer payments',
				score: 0,
				isCorrect: false,
			},
			{
				answerText: 'public spending in goods and infrastructure',
				score: 0,
				isCorrect: false,
			},
			{
				answerText: 'the capital gains tax',
				score: 0,
				isCorrect: false,
			},
			{
				answerText: 'foreign based purchases and direct investments',
				score: 0,
				isCorrect: false,
			},
		],
		scoringTags: [
			{
				tag: 'MatchResponse',
				data: 'transfer payments',
				dataMapping: 'PositionRequirements',
				maxScore: 0,
			},
			{
				tag: 'MatchResponse',
				data: 'public spending in goods and infrastructure',
				dataMapping: 'PositionRequirements',
				maxScore: 0,
			},
			{
				tag: 'MatchResponse',
				data: 'the capital gains tax',
				dataMapping: 'PositionRequirements',
				maxScore: 0,
			},
			{
				tag: 'MatchResponse',
				data: 'foreign based purchases and direct investments',
				dataMapping: 'PositionRequirements',
				maxScore: 0,
			},
		],
	},

	// ########################################################################################################################################
	// ########################################################################################################################################

	{
		id: 'KnowledgeAssessment3',
		format: 'MultipleChoice',
		questionText:
			'A form of pension plan in which the participant has some ability to determine how pension contributions are invested is described as a(n):',
		section: 'KnowledgeAssessment',
		hasNotes: false,
		helperText: '',
		maxScore: 0,
		order: 3,
		referenceID: 'f16df2dd-52c5-444d-981b-0349c2a368c8',
		questionAnswers: [
			{
				answerText: 'defined contribution plan',
				score: 0,
				isCorrect: false,
			},
			{
				answerText: 'defined benefit plan',
				score: 0,
				isCorrect: false,
			},
			{
				answerText: 'locked-in retirement account',
				score: 0,
				isCorrect: false,
			},
			{
				answerText: 'endowment plan',
				score: 0,
				isCorrect: false,
			},
		],
		scoringTags: [
			{
				tag: 'MatchResponse',
				data: 'defined contribution plan',
				dataMapping: 'PositionRequirements',
				maxScore: 0,
			},
			{
				tag: 'MatchResponse',
				data: 'defined benefit plan',
				dataMapping: 'PositionRequirements',
				maxScore: 0,
			},
			{
				tag: 'MatchResponse',
				data: 'locked-in retirement account',
				dataMapping: 'PositionRequirements',
				maxScore: 0,
			},
			{
				tag: 'MatchResponse',
				data: 'endowment plan',
				dataMapping: 'PositionRequirements',
				maxScore: 0,
			},
		],
	},

	// ########################################################################################################################################
	// ########################################################################################################################################

	{
		id: 'KnowledgeAssessment4',
		format: 'MultipleChoice',
		questionText: 'If a company is liquidated, proceeds from asset sales are distributed to common shareholders:',
		section: 'KnowledgeAssessment',
		hasNotes: false,
		helperText: '',
		maxScore: 0,
		order: 4,
		referenceID: '2954edf3-cff7-4dda-9a3e-9604a03233d8',
		questionAnswers: [
			{
				answerText: 'before unsecured debt investor and preferred shareholder claims are paid',
				score: 0,
				isCorrect: false,
			},
			{
				answerText: 'after preferred shareholder claims are paid but before unsecured debt investor claims are paid',
				score: 0,
				isCorrect: false,
			},
			{
				answerText: 'after secured debt investor and preferred shareholder claims are paid',
				score: 0,
				isCorrect: false,
			},
			{
				answerText: 'before secured debt investor and preferred shareholder claims are paid',
				score: 0,
				isCorrect: false,
			},
		],
		scoringTags: [
			{
				tag: 'MatchResponse',
				data: 'before unsecured debt investor and preferred shareholder claims are paid',
				dataMapping: 'PositionRequirements',
				maxScore: 0,
			},
			{
				tag: 'MatchResponse',
				data: 'after preferred shareholder claims are paid but before unsecured debt investor claims are paid',
				dataMapping: 'PositionRequirements',
				maxScore: 0,
			},
			{
				tag: 'MatchResponse',
				data: 'after secured debt investor and preferred shareholder claims are paid',
				dataMapping: 'PositionRequirements',
				maxScore: 0,
			},
			{
				tag: 'MatchResponse',
				data: 'before secured debt investor and preferred shareholder claims are paid',
				dataMapping: 'PositionRequirements',
				maxScore: 0,
			},
		],
	},

	/* Voice
########################################################################################################################################################################
########################################################################################################################################################################
########################################################################################################################################################################
*/

	{
		id: 'Voice',
		format: 'Voice',
		questionText: 'Voice',
		section: 'Voice',
		hasNotes: false,
		helperText: '',
		maxScore: 5,
		order: 1,
		referenceID: 'fe63e48f-e14c-46d7-944b-047b07f68516',
		scoringTags: [
			{
				tag: 'VoiceRecordingLength',
				data: 'none',
				dataMapping: 'AttentionToDetail',
				maxScore: 5,
			},
			{
				tag: 'VoiceUseMicrophone',
				data: 'none',
				dataMapping: 'TechnologySkills',
				maxScore: 0,
			},
		],
	},

	/* Last Question Answer
########################################################################################################################################################################
########################################################################################################################################################################
########################################################################################################################################################################
*/

	{
		id: 'LastQuestion',
		format: 'TimedLongResponse',
		questionText:
			'Taking current industry trends into consideration, what asset class or region would you consider a good investment and why?',
		section: 'LastQuestion',
		hasNotes: false,
		helperText: '600000',
		maxScore: 10,
		order: 1,
		referenceID: 'ef891885-607d-4488-b791-2a6031c08566',
		scoringTags: [
			{
				tag: 'FleschKincaid',
				data: 'none',
				dataMapping: 'CommunicationSkills',
				maxScore: 10,
			},
		],
	},
]
