import React from 'react'
import BannerMenuButton from './MenuButton/BannerMenuButton'

function FormButtons({ onCancel, onSubmit }) {
	return (
		<div
			style={{
				display: 'flex',
				gap: 25,
				marginTop: '40px',
				alignItems: 'center',
				justifyContent: 'end',
				width: '100%',
			}}
		>
			<BannerMenuButton style={{ color: 'var(--faded-black)', fontSize: 14 }} onClick={() => onCancel()}>
				Cancel
			</BannerMenuButton>
			<BannerMenuButton
				style={{
					color: 'var(--primary-color-one)',
					fontSize: 14,
					fontWeight: 'var(--bold)',
				}}
				onClick={() => onSubmit()}
			>
				Submit
			</BannerMenuButton>
		</div>
	)
}

export default FormButtons
