import React from 'react'
import BannerMenuButton from '../MenuButton/BannerMenuButton'

import { getFilterLabel } from './Data'

/* Displays a label for the filter, as well as provides functionality to remove it from the filterList by clicking on it.  */
function Filter({ category, condition, value, removeFilter }) {
	return (
		<BannerMenuButton
			style={{
				color: '#001940',
				fontWeight: 'var(--semi-bold)',
				fontSize: 14,
				height: 35,
				boxShadow: 'rgb(0, 0, 0, 0.3) 0px 1px 2px 2px',
				padding: '5px 10px',
				borderRadius: 10,
			}}
			onClick={() => {
				removeFilter(category, condition, value)
			}}
		>
			{getFilterLabel(category, condition, value)}
		</BannerMenuButton>
	)
}

export default Filter
