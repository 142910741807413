import { API, Storage } from 'aws-amplify'
import {
	getClient,
	getTest,
	listQuestions,
	listUserResponses,
	listUsers,
	accessCodeByTest,
	listAccessCodes,
	getAccessCode,
	getUser,
	listScoringTags,
	listScoringTagResponses,
	listTests,
	listQuestionAnswers,
	listClients,
	usersByTest,
	responsesByUser,
	questionsByTest,
	tagsByQuestion,
	tagResponsesByUser,
	listErrorLogs,
} from '../graphql/queries'
import {
	changeAccessCodes,
	changeAllUserResponses,
	changeApplicantLists,
	changeClient,
	changeClientList,
	changeErrorLogs,
	changeQuestionAnswersLists,
	changeQuestionLists,
	changeScoringTagResponses,
	changeScoringTagResponsesByUserID,
	changeScoringTags,
	changeTestList,
} from '../actions'
import {
	deleteAccessCode,
	deleteQuestion,
	deleteQuestionAnswer,
	deleteScoringTag,
	deleteScoringTagResponse,
	deleteTest,
	deleteUser,
	deleteUserResponse,
	updateAccessCode,
	updateUser,
} from '../graphql/mutations'
import { updateJobsData } from './ReduxFunctions'

export async function loadClientInfo(clientID, dispatch) {
	if (clientID === null || clientID === undefined) {
		console.error("ERROR: ClientID is null/undefined when passed into 'loadClientInfo'.")
		return
	}

	try {
		const clientData = await API.graphql({ query: getClient, variables: { id: clientID }, authMode: 'AMAZON_COGNITO_USER_POOLS' })

		if (clientData !== undefined && clientData !== null) {
			const client = clientData.data.getClient
			if (!client) {
				console.error('Error: Could not get client!')
				return
			}

			delete client.createdAt
			delete client.updatedAt
			delete client._version
			delete client._deleted
			delete client._lastChangedAt
			delete client.Test

			dispatch(changeClient(client))
			return client
		} else {
			console.error('ERROR: Client is null/undefined')
		}
	} catch (e) {
		console.error(e)
	}
}

/* ########################################################################################## */
/* ########################################################################################## */
/* ########################################################################################## */
/* ########################################################################################## */

// LEVEL 1 CLIENT LOADING FUNCTIONS

export async function loadLevel1Data(client, dispatch) {
	let status = 'SUCCESS'

	const tests = await loadTests(client.testList, dispatch)

	// eslint-disable-next-line
	const [_, applicantLists, questions] = await Promise.all([
		loadAccessCodes(tests, dispatch),
		loadApplicantsByTestList(tests, dispatch),
		loadTestQuestions(client.testList, dispatch),
	]).catch(() => {
		status = 'ERROR'
	})

	if (status === 'ERROR') return status

	const applicants = []

	for (const key in applicantLists) {
		for (const applicant of applicantLists[key]) {
			applicants.push(applicant)
		}
	}

	await Promise.all([
		loadScoringTagResponses(applicants, dispatch),
		loadScoringTags(questions, dispatch),
		loadUserResponses(applicantLists, dispatch),
	]).catch(() => {
		status = 'ERROR'
	})

	updateJobsData(applicantLists, tests, dispatch)

	return status
}

export async function loadApplicantsByTestList(testList, dispatch) {
	console.log('Loading Users')

	return new Promise((resolve, reject) => {
		const testIDs = testList.map((test) => test.id)
		const promises = []

		for (const testID of testIDs) {
			let nextToken = null

			promises.push(
				new Promise(async (resolve, reject) => {
					const result = {
						testID,
						users: [],
					}

					try {
						do {
							const usersData = await API.graphql({
								query: usersByTest,
								variables: { testID, nextToken, limit: 1000 },
								authMode: 'AMAZON_COGNITO_USER_POOLS',
							})
							const users = usersData.data.usersByTest.items
							nextToken = usersData.data.usersByTest.nextToken

							for (const user of users) {
								if (user?.status !== 'INPROGRESS') {
									result.users.push(user)
								}
							}
						} while (nextToken)

						resolve(result)
					} catch (e) {
						reject(e)
					}
				})
			)
		}

		Promise.all(promises)
			.then((results) => {
				const applicantLists = {}

				for (const result of results) {
					applicantLists[result.testID] = result.users
				}

				dispatch(changeApplicantLists(applicantLists))
				resolve(applicantLists)
			})
			.catch((err) => {
				console.error('Error loading applicants', err)
				reject()
			})
	})
}

export async function loadTests(testIDList, dispatch) {
	return new Promise((resolve, reject) => {
		const testPromises = []

		for (const testID of testIDList) {
			const currPromise = new Promise(async (resolve, reject) => {
				try {
					const testData = await API.graphql({ query: getTest, variables: { id: testID }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
					const test = testData.data.getTest

					if (!test) {
						resolve(null)
					} else {
						resolve(test)
					}
				} catch (e) {
					console.error('ERROR LOADING TESTS: ', e)
					reject()
				}
			})

			testPromises.push(currPromise)
		}

		Promise.all(testPromises)
			.then((results) => {
				const tests = []
				console.log(results)

				for (const result of results) {
					if (result) tests.push(result)
				}

				dispatch(changeTestList(tests))
				resolve(tests)
			})
			.catch((err) => {
				console.error(err)
				reject(err)
			})
	})
}

export async function loadUserResponses(applicantLists, dispatch) {
	console.log('Loading User Responses')

	return new Promise((resolve, reject) => {
		if (applicantLists === undefined || applicantLists === null) {
			resolve()
		}

		let applicants = []

		// Gets an array of all the applicants
		// Initializes relResponses at each applicantID as an empty array
		for (const testID in applicantLists) {
			for (const applicant of applicantLists[testID]) {
				applicants.push(applicant)
			}
		}

		const promises = []

		for (const applicant of applicants) {
			promises.push(
				new Promise(async (resolve, reject) => {
					try {
						const responsesData = await API.graphql({
							query: responsesByUser,
							variables: { limit: 200, userID: applicant.id },
							authMode: 'AMAZON_COGNITO_USER_POOLS',
						})
						resolve({
							id: applicant.id,
							responses: responsesData.data.responsesByUser.items,
						})
					} catch (e) {
						console.error('Could not load responses for the user with id', applicant.id, e)
						reject()
					}
				})
			)
		}

		Promise.all(promises)
			.then((results) => {
				const allResponses = {}

				for (const result of results) {
					allResponses[result.id] = result.responses
				}

				dispatch(changeAllUserResponses(allResponses))
				resolve(allResponses)
			})
			.catch((err) => {
				reject(err)
			})
	})
}

export async function loadTestQuestions(testIDs, dispatch) {
	console.log('Loading Questions')

	// The sections that we don't want, ie descriptive sections
	const irrSections = ['CaseStudyInstructions', 'CaseStudyDescription']

	const promises = []

	return new Promise((resolve, reject) => {
		for (const testID of testIDs) {
			promises.push(
				new Promise(async (resolve, reject) => {
					try {
						const questionsData = await API.graphql({
							query: questionsByTest,
							variables: { limit: 200, testID },
							authMode: 'AMAZON_COGNITO_USER_POOLS',
						})

						const relevantQuestions = []

						for (const question of questionsData.data.questionsByTest.items) {
							if (!irrSections.includes(question.section)) relevantQuestions.push(question)
						}

						resolve({
							testID,
							questions: relevantQuestions,
						})
					} catch (err) {
						console.error('Error loading questions for test with ID ', testID, err)
						reject()
					}
				})
			)
		}

		Promise.all(promises)
			.then((results) => {
				const allQuestions = {}

				for (const result of results) {
					allQuestions[result.testID] = result.questions
				}

				dispatch(changeQuestionLists(allQuestions))
				resolve(allQuestions)
			})
			.catch((err) => {
				reject(err)
			})
	})
}

// export async function loadAccessCodes(codeIDs, testList, dispatch) {
// 	console.log('Loading Access Codes')

// 	return new Promise((resolve, reject) => {
// 		const promises = []

// 		for (const codeID of codeIDs) {
// 			promises.push(
// 				new Promise(async (resolve, reject) => {
// 					try {
// 						const codeData = await API.graphql({
// 							query: getAccessCode,
// 							variables: { id: codeID },
// 							authMode: 'AMAZON_COGNITO_USER_POOLS',
// 						})
// 						const code = codeData.data.getAccessCode

// 						if (!code) {
// 							resolve(null)
// 						} else {
// 							code.testName = 'Undefined'
// 							for (const test of testList) {
// 								if (test.id === code.testID) {
// 									code.testName = test.testName
// 									break
// 								}
// 							}

// 							resolve(code)
// 						}
// 					} catch (e) {
// 						console.error('Error getting access code', e)
// 						reject(e)
// 					}
// 				})
// 			)
// 		}

// 		Promise.all(promises)
// 			.then((codes) => {
// 				const allCodes = {}

// 				for (const code of codes) {
// 					if (!code) continue

// 					allCodes[code.id] = code
// 				}

// 				dispatch(changeAccessCodes(allCodes))
// 				resolve(allCodes)
// 			})
// 			.catch((err) => {
// 				reject(err)
// 			})
// 	})
// }

export async function loadAccessCodes(testList, dispatch) {
	console.log('Loading Access Codes')
	console.log({ testList })

	return new Promise((resolve, reject) => {
		const promises = []

		for (const test of testList) {
			console.log(test)
			promises.push(
				new Promise(async (resolve, reject) => {
					try {
						const codesData = await API.graphql({
							query: accessCodeByTest,
							variables: { testID: test.id, limit: 50 },
							authMode: 'AMAZON_COGNITO_USER_POOLS',
						})
						const codes = codesData.data.accessCodeByTest.items

						const codeListForTest = []

						for (const code of codes) {
							if (code) {
								code.testName = 'Undefined'
								for (const test of testList) {
									if (test.id === code.testID) {
										code.testName = test.testName
										break
									}
								}

								codeListForTest.push(code)
							}
						}

						resolve(codeListForTest)
					} catch (e) {
						console.error('Error getting access code', e)
						reject(e)
					}
				})
			)
		}

		Promise.all(promises)
			.then((codeLists) => {
				const allCodes = {}

				for (const codeList of codeLists) {
					for (const code of codeList) {
						if (!code) continue

						allCodes[code.id] = code
					}
				}

				dispatch(changeAccessCodes(allCodes))
				resolve(allCodes)
			})
			.catch((err) => {
				reject(err)
			})
	})
}

export async function loadScoringTags(questionLists, dispatch) {
	const questions = []

	console.log('Loading Scoring Tags')

	for (const testID in questionLists) {
		for (const question of questionLists[testID]) {
			questions.push(question)
		}
	}

	return new Promise((resolve, reject) => {
		const promises = []

		for (const question of questions) {
			promises.push(
				new Promise(async (resolve, reject) => {
					try {
						const tagsData = await API.graphql({
							query: tagsByQuestion,
							variables: { questionID: question.id, limit: 20 },
							authMode: 'AMAZON_COGNITO_USER_POOLS',
						})

						resolve({
							questionID: question.id,
							tags: tagsData.data.tagsByQuestion.items,
						})
					} catch (err) {
						console.error('Error getting scoring tag for question with ID ', question.id, err)
						reject()
					}
				})
			)
		}

		Promise.all(promises)
			.then((results) => {
				const allTags = []

				for (const result of results) {
					if (result.tags.length > 0) allTags.push(...result.tags)
				}

				dispatch(changeScoringTags(allTags))
				resolve(allTags)
			})
			.catch((err) => {
				reject(err)
			})
	})
}

// Load Scoring Tag responses and organize them by the userID of the responses
export async function loadScoringTagResponses(applicants, dispatch) {
	console.log('Loading Scoring Tag Responses')

	const applicantIDs = applicants.map((a) => a.id)

	return new Promise((resolve, reject) => {
		const promises = []

		for (const applicantID of applicantIDs) {
			promises.push(
				new Promise(async (resolve, reject) => {
					try {
						const tagRespsData = await API.graphql({
							query: tagResponsesByUser,
							variables: { userID: applicantID },
							authMode: 'AMAZON_COGNITO_USER_POOLS',
						})
						const tagResps = tagRespsData.data.tagResponsesByUser.items

						resolve(tagResps)
					} catch (err) {
						reject(err)
					}
				})
			)
		}

		Promise.all(promises)
			.then((results) => {
				const allResps = []

				for (const result of results) {
					allResps.push(...result)
				}

				const newRespsByUserID = {}
				for (const resp of allResps) {
					if (!newRespsByUserID[resp.userID]) {
						newRespsByUserID[resp.userID] = []
					}
					newRespsByUserID[resp.userID].push(resp)
				}

				dispatch(changeScoringTagResponsesByUserID(newRespsByUserID))
				dispatch(changeScoringTagResponses(allResps))
				resolve(allResps)
			})
			.catch((err) => {
				reject(err)
			})
	})

	// try {

	//     do {

	//         const respsData = await API.graphql({
	//             query: listScoringTagResponses,
	//             variables: { limit: 1000, nextToken: nextToken },
	//             authMode: 'AMAZON_COGNITO_USER_POOLS'
	//         })
	//         const resps = respsData.data.listScoringTagResponses.items
	//         nextToken = respsData.data.listScoringTagResponses.nextToken

	//         for (let resp of resps) {

	//             if (applicantIDs.includes(resp.userID)) {
	//                 relResps.push(resp)
	//             }

	//         }

	//     } while (nextToken)

	//     dispatch(changeScoringTagResponses(relResps))

	// } catch(e) {
	//     console.error("ERROR GETTING SCORING TAG RESPONSES", e)
	// }
}

/* ########################################################################################## */
/* ########################################################################################## */
/* ########################################################################################## */
/* ########################################################################################## */

export async function updateAccessCodeStatus(newStatus, passedCode, allCodes, dispatch) {
	try {
		const codeData = await API.graphql({ query: getAccessCode, variables: { id: passedCode.id } })
		const code = codeData.data.getAccessCode

		if (!code) {
			console.error('Error: Could not find access code to update!')
			return
		}

		delete code._deleted
		delete code.updatedAt
		delete code._lastChangedAt
		delete code.createdAt

		code.status = newStatus

		await API.graphql({
			query: updateAccessCode,
			variables: { input: code },
			authMode: 'AMAZON_COGNITO_USER_POOLS',
		})

		// Update redux and update state
		const updatedCodes = { ...allCodes }
		updatedCodes[passedCode.id].status = newStatus
		dispatch(changeAccessCodes(updatedCodes))
		// console.log("Successfully updated access code status.")
	} catch (e) {
		console.error('ERROR UPDATING ACCESS CODE STATUS', e)
	}
}

export async function updateUserShortListed(newSL, id, testID, applicantLists, dispatch) {
	let changed = false

	// Updated shortlisted value in redux store
	for (const applicant of applicantLists[testID]) {
		if (applicant.id === id) {
			applicant.shortlisted = newSL
			changed = true
			break
		}
	}
	const newApplicantLists = { ...applicantLists }
	dispatch(changeApplicantLists(newApplicantLists))

	try {
		const userData = await API.graphql({ query: getUser, variables: { id: id }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
		const user = userData.data.getUser

		if (!user) {
			console.error('Error: Could not find user!')
			return
		}

		delete user._deleted
		delete user.updatedAt
		delete user._lastChangedAt
		delete user.createdAt
		delete user.UserResponses
		delete user.ScoringTagResponses

		user.shortlisted = newSL

		await API.graphql({
			query: updateUser,
			variables: { input: user },
			authMode: 'AMAZON_COGNITO_USER_POOLS',
		})
	} catch (e) {
		console.error('ERROR UPDATED USER SHORTLISTED', e)

		// If there was an error, reset shortlisted value in redux store
		if (changed) {
			for (let applicant of applicantLists[testID]) {
				if (applicant.id === id) {
					applicant.shortlisted = !applicant.shortListed
					break
				}
			}

			const newApplicantLists = { ...applicantLists }
			dispatch(changeApplicantLists(newApplicantLists))
		}
	}
}

// Fix this if no file exists
export async function getVoiceRecordingFile(fileName) {
	try {
		// S3 still returns an object url even for a failed fetch...
		// So we try to download it and it will fail if the fetch failed, which will take us to the catch statement
		await Storage.get('VoiceRecordings/' + fileName, {
			level: 'public',
			download: true,
		})

		// If the previous operation didn't fail, we actually get the file in the format we can use
		const file = await Storage.get('VoiceRecordings/' + fileName, {
			level: 'public',
		})

		// console.log("file = ", file)

		return file
	} catch (e) {
		// console.error("ERROR GETTING VOICE RECORDING", e)
		return 'ERROR'
	}
}

/* ########################################################################################## */
/* ########################################################################################## */
/* ########################################################################################## */
/* ########################################################################################## */

/* FUNCTIONS FOR LOADING DATA FOR A HIRING MANAGER */

export async function loadApplicantsByIDs(applicantIDs, dispatch) {
	let applicantLists = {}

	if (applicantIDs === null || applicantIDs === undefined || applicantIDs.length === 0) {
		return
	}

	for (let id of applicantIDs) {
		console.log(id)

		try {
			const userData = await API.graphql({ query: getUser, variables: { id: id }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
			const user = userData.data.getUser

			if (!user) {
				console.error('Error: Could not find user!')
				return
			}

			if (user.status !== 'INPROGRESS') {
				if (applicantLists[user.testID] === undefined) {
					applicantLists[user.testID] = []
				}
				applicantLists[user.testID].push(user)
			}
		} catch (e) {
			console.error('ERROR LOADING APPLICANT WITH ID: ', id, e)
		}
	}

	dispatch(changeApplicantLists(applicantLists))
	return applicantLists
}

/* END HIRING MANAGER FUNCTIONS */

/* ########################################################################################## */
/* ########################################################################################## */
/* ########################################################################################## */
/* ########################################################################################## */
/* ########################################################################################## */

/* MASTER LOADING FUNCTIONS*/

export async function loadMasterData(dispatch) {
	let status = 'SUCCESS'

	try {
		const testList = await loadAllTests(dispatch)
		const applicantLists = await loadAllUsers(testList, dispatch)

		updateJobsData(applicantLists, testList, dispatch)
	} catch (err) {
		console.error(err)
		status = 'ERROR'
	}

	if (status !== 'SUCCESS') return status

	await Promise.all([
		loadErrorLogs(dispatch),
		loadAllUserResponses(dispatch),
		loadAllAccessCodes(dispatch),
		loadAllScoringTags(dispatch),
		loadAllScoringTagResponses(dispatch),
		loadAllQuestions(dispatch),
		loadAllQuestionAnswers(dispatch),
		loadAllClients(dispatch),
	]).catch(() => {
		status = 'ERROR'
	})

	return status
}

export async function loadAllTests(dispatch) {
	let nextToken = null
	const newTests = []

	try {
		do {
			const testsData = await API.graphql({
				query: listTests,
				variables: { limit: 1000, nextToken: nextToken },
				authMode: 'AMAZON_COGNITO_USER_POOLS',
			})
			const tests = testsData.data.listTests.items
			nextToken = testsData.data.listTests.nextToken

			for (const test of tests) newTests.push(test)
		} while (nextToken !== null)

		dispatch(changeTestList(newTests))
		return newTests
	} catch (err) {
		console.error('ERROR LOADING ALL TESTS', err)
		throw new Error()
	}
}

export async function loadAllUsers(testList, dispatch) {
	const applicantLists = {}
	let nextToken = null

	try {
		for (const test of testList) {
			applicantLists[test.id] = []
		}

		do {
			const usersData = await API.graphql({
				query: listUsers,
				variables: { limit: 1000, nextToken: nextToken },
				authMode: 'AMAZON_COGNITO_USER_POOLS',
			})
			const users = usersData.data.listUsers.items
			nextToken = usersData.data.listUsers.nextToken

			for (const user of users) {
				if (user.status === 'INPROGRESS') continue
				if (applicantLists[user.testID] === undefined) {
					applicantLists[user.testID] = []
				}
				applicantLists[user.testID].push(user)
			}
		} while (nextToken !== null)

		dispatch(changeApplicantLists(applicantLists))
		return applicantLists
	} catch (err) {
		console.error('ERROR LOADING ALL USERS', err)
		throw new Error()
	}
}

export async function loadAllUserResponses(dispatch) {
	const allUserResponses = {}
	let nextToken = null

	try {
		do {
			const responsesData = await API.graphql({
				query: listUserResponses,
				variables: { limit: 1000, nextToken: nextToken },
				authMode: 'AMAZON_COGNITO_USER_POOLS',
			})
			const responses = responsesData.data.listUserResponses.items
			nextToken = responsesData.data.listUserResponses.nextToken

			for (let response of responses) {
				if (allUserResponses[response.userID] === undefined) {
					allUserResponses[response.userID] = []
				}
				allUserResponses[response.userID].push(response)
			}
		} while (nextToken !== null)

		dispatch(changeAllUserResponses(allUserResponses))
	} catch (err) {
		console.error('ERROR LOADING ALL USER RESPONSES', err)
		throw new Error()
	}
}

export async function loadAllAccessCodes(dispatch) {
	const accessCodes = {}
	let nextToken = null

	try {
		do {
			const codesData = await API.graphql({
				query: listAccessCodes,
				variables: { limit: 1000, nextToken: nextToken },
				authMode: 'AMAZON_COGNITO_USER_POOLS',
			})
			const codes = codesData.data.listAccessCodes.items
			nextToken = codesData.data.listAccessCodes.nextToken

			for (let code of codes) {
				accessCodes[code.id] = code
			}
		} while (nextToken !== null)

		dispatch(changeAccessCodes(accessCodes))
	} catch (err) {
		console.error('ERROR LOADING ALL CODES', err)
		throw new Error()
	}
}

export async function loadAllScoringTags(dispatch) {
	let nextToken = null
	let newTags = []

	try {
		do {
			const tagsData = await API.graphql({
				query: listScoringTags,
				variables: { limit: 1000, nextToken: nextToken },
				authMode: 'AMAZON_COGNITO_USER_POOLS',
			})
			const tags = tagsData.data.listScoringTags.items
			nextToken = tagsData.data.listScoringTags.nextToken

			for (let tag of tags) {
				newTags.push(tag)
			}
		} while (nextToken !== null)

		dispatch(changeScoringTags(newTags))
	} catch (err) {
		console.error('ERROR LOADING SCORING TAGS', err)
		throw new Error()
	}
}

export async function loadAllScoringTagResponses(dispatch) {
	let nextToken = null
	const newResps = []
	const newRespsByUserID = {}

	try {
		do {
			const respsData = await API.graphql({
				query: listScoringTagResponses,
				variables: { limit: 1000, nextToken: nextToken },
				authMode: 'AMAZON_COGNITO_USER_POOLS',
			})
			const resps = respsData.data.listScoringTagResponses.items
			nextToken = respsData.data.listScoringTagResponses.nextToken

			for (const resp of resps) {
				if (!newRespsByUserID[resp.userID]) {
					newRespsByUserID[resp.userID] = []
				}
				newRespsByUserID[resp.userID].push(resp)
				newResps.push(resp)
			}
		} while (nextToken !== null)

		dispatch(changeScoringTagResponsesByUserID(newRespsByUserID))
		dispatch(changeScoringTagResponses(newResps))
	} catch (err) {
		console.error('ERROR LOADING SCORING TAG RESPONSES', err)
		throw new Error()
	}
}

export async function loadAllQuestions(dispatch) {
	let questionLists = {}
	let nextToken = null

	try {
		do {
			const questionsData = await API.graphql({
				query: listQuestions,
				variables: { limit: 1000, nextToken: nextToken },
				authMode: 'AMAZON_COGNITO_USER_POOLS',
			})
			const questions = questionsData.data.listQuestions.items
			nextToken = questionsData.data.listQuestions.nextToken

			for (let question of questions) {
				if (questionLists[question.testID] === undefined) {
					questionLists[question.testID] = []
				}
				questionLists[question.testID].push(question)
			}
		} while (nextToken !== null)

		dispatch(changeQuestionLists(questionLists))
	} catch (err) {
		console.error('ERROR LOADING QUESTIONS', err)
		throw new Error()
	}
}

export async function loadAllQuestionAnswers(dispatch) {
	let questionAnswersLists = {}
	let nextToken = null

	try {
		do {
			const questionAnswersData = await API.graphql({
				query: listQuestionAnswers,
				variables: { limit: 1000, nextToken: nextToken },
				authMode: 'AMAZON_COGNITO_USER_POOLS',
			})
			const questionAnswers = questionAnswersData.data.listQuestionAnswers.items
			nextToken = questionAnswersData.data.listQuestionAnswers.nextToken

			for (const questionAnswer of questionAnswers) {
				if (questionAnswersLists[questionAnswer.questionID] === undefined) {
					questionAnswersLists[questionAnswer.questionID] = []
				}
				questionAnswersLists[questionAnswer.questionID].push(questionAnswer)
			}
		} while (nextToken !== null)

		dispatch(changeQuestionAnswersLists(questionAnswersLists))
	} catch (err) {
		console.error('ERROR LOADING QUESTION ANSWERS', err)
		throw new Error()
	}
}

export async function loadAllClients(dispatch) {
	let clientList = []
	let nextToken = null

	try {
		do {
			const clientsData = await API.graphql({
				query: listClients,
				variables: { limit: 1000, nextToken: nextToken },
				authMode: 'AMAZON_COGNITO_USER_POOLS',
			})
			const clients = clientsData.data.listClients.items
			nextToken = clientsData.data.listClients.nextToken

			for (const client of clients) {
				if (client.role === 'MASTER') continue
				clientList.push(client)
			}
		} while (nextToken !== null)

		dispatch(changeClientList(clientList))
	} catch (err) {
		console.error('ERROR LOADING CLIENTS', err)
		throw new Error()
	}
}

export async function loadErrorLogs(dispatch) {
	const errorLogList = []
	let nextToken = null

	try {
		do {
			const errorLogsData = await API.graphql({
				query: listErrorLogs,
				variables: { limit: 250, nextToken: nextToken },
				authMode: 'AMAZON_COGNITO_USER_POOLS',
			})
			const errorLogs = errorLogsData.data.listErrorLogs.items
			nextToken = errorLogsData.data.listErrorLogs.nextToken

			errorLogList.push(...errorLogs)
		} while (nextToken !== null)

		dispatch(changeErrorLogs(errorLogList))
	} catch (err) {
		console.error('ERROR LOADING ERROR LOGS', err)
		throw new Error()
	}
}

/* END MASTER LOADING FUNCTIONS */

/* ########################################################################################## */
/* ########################################################################################## */
/* ########################################################################################## */
/* ########################################################################################## */
/* ########################################################################################## */

/* DELETE ITEMS FUNCTIONS */

export async function deleteApplicantFromDatabase(applicantID, userResponses, tagResponses) {
	try {
		const promises = []

		for (const responseID of userResponses) {
			promises.push(
				new Promise(async (resolve, reject) => {
					try {
						await API.graphql({
							query: deleteUserResponse,
							variables: { input: { id: responseID } },
							authMode: 'AMAZON_COGNITO_USER_POOLS',
						})
						resolve()
					} catch (err) {
						console.error(err)
						reject()
					}
				})
			)
		}

		for (const responseID of tagResponses) {
			promises.push(
				new Promise(async (resolve, reject) => {
					try {
						await API.graphql({
							query: deleteScoringTagResponse,
							variables: { input: { id: responseID } },
							authMode: 'AMAZON_COGNITO_USER_POOLS',
						})
						resolve()
					} catch (err) {
						console.error(err)
						reject()
					}
				})
			)
		}

		await Promise.all([promises]).catch((err) => {
			throw new Error(err)
		})

		await API.graphql({
			query: deleteUser,
			variables: { input: { id: applicantID } },
			authMode: 'AMAZON_COGNITO_USER_POOLS',
		})
	} catch (err) {
		throw new Error(err)
	}
}

export async function deleteTestFromDatabase(testID) {
	try {
		await API.graphql({
			query: deleteTest,
			variables: { input: { id: testID } },
			authMode: 'AMAZON_COGNITO_USER_POOLS',
		})
	} catch (err) {
		throw new Error(err)
	}
}

export async function deleteQuestionFromDatabase(questionID, scoringTagIDs, questionAnswerIDs) {
	try {
		const promises = []

		for (const id of scoringTagIDs) {
			promises.push(
				new Promise(async (resolve, reject) => {
					try {
						await API.graphql({
							query: deleteScoringTag,
							variables: { input: { id } },
							authMode: 'AMAZON_COGNITO_USER_POOLS',
						})

						resolve()
					} catch (err) {
						reject(err)
					}
				})
			)
		}

		for (const id of questionAnswerIDs) {
			promises.push(
				new Promise(async (resolve, reject) => {
					try {
						await API.graphql({
							query: deleteQuestionAnswer,
							variables: { input: { id } },
							authMode: 'AMAZON_COGNITO_USER_POOLS',
						})

						resolve()
					} catch (err) {
						reject(err)
					}
				})
			)
		}

		await Promise.all(promises).catch((err) => {
			throw new Error(err)
		})

		await API.graphql({
			query: deleteQuestion,
			variables: { input: { id: questionID } },
			authMode: 'AMAZON_COGNITO_USER_POOLS',
		})
	} catch (err) {
		throw new Error(err)
	}
}

export async function deleteAccessCodeFromDatabase(codeID) {
	try {
		await API.graphql({
			query: deleteAccessCode,
			variables: { input: { id: codeID } },
			authMode: 'AMAZON_COGNITO_USER_POOLS',
		})
	} catch (err) {
		throw new Error(err)
	}
}

/* ########################################################################################## */
/* ########################################################################################## */
/* ########################################################################################## */
/* ########################################################################################## */
/* ########################################################################################## */
