import { Typography, Button, Divider, Box, Grid, Paper, Skeleton } from '@mui/material'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { Storage } from 'aws-amplify'
import { DarkButton } from '../../../Button/DarkButton'

function getLogoPaths(testList) {
	let paths = []

	for (let test of testList) {
		if (!paths.includes(test.logoPath)) {
			paths.push(test.logoPath)
		}
	}

	return paths
}

const styles = {
	container: {
		display: 'flex',
		height: '100%',
		width: '100%',
		justifyContent: 'start',
		alignItems: 'center',
		flexDirection: 'column',
		paddingTop: 70,
		boxSizing: 'border-box',
		overflowY: 'auto',
	},
	previewBox: {
		paddingTop: 10,
		paddingBottom: 40,
		width: 400,
		boxShadow: 'rgba(0, 0, 0, 0.4) 0 1px 5px',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: '0.5rem',
	},
	choicesBox: {
		position: 'fixed',
		top: '50%',
		left: 'calc(50% + 150px)',
		transform: 'translate(-50%, -50%)',
		paddingTop: 10,
		paddingBottom: 40,
		width: 400,
		background: 'white',
		boxShadow: 'rgba(0, 0, 0, 0.4) 0 1px 5px',
		display: 'flex',
		flexDirection: 'column',
	},
}

function FileUploadPage(props) {
	const [showChoices, setShowChoices] = useState(false)
	const [showPreview, setShowPreview] = useState(props.testInfo.file ? true : false)

	if (showPreview) {
		return <PreviewLogo {...props} setShowPreview={setShowPreview} />
	}

	return (
		<div style={styles.container}>
			<Box>
				<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
					<Typography fontSize='18px' fontWeight='600' mb={1}>
						Upload Logo
					</Typography>
					<Typography fontSize='16px' textAlign='left' mb={2}>
						Choose a logo from your computer to use with the test.
					</Typography>
					<Button style={{ paddingLeft: 0, color: '#001940', textTransform: 'none' }} component='label' variant='text'>
						Click here to upload a logo
						<input
							type='file'
							accept='image/*'
							onChange={(e) => {
								if (!e.target.files[0].type.includes('image')) {
									alert('File must be an image.')
									return
								}

								props.setTestInfo({
									...props.testInfo,
									file: e.target.files[0],
									imageURL: URL.createObjectURL(e.target.files[0]),
									fileType: e.target.files[0].type,
									logoPath: '',
								})
								setShowPreview(true)
							}}
							hidden
						/>
					</Button>
				</Box>

				<Divider style={{ margin: '30px 0' }}>
					<Typography textTransform='uppercase' color='rgb(0, 0, 0, 0.4)' fontSize={14}>
						Or
					</Typography>
				</Divider>

				<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
					<Typography fontSize='18px' fontWeight='600' mb={1}>
						Choose existing logo
					</Typography>
					<Typography fontSize='16px' textAlign='left' mb={2}>
						Choose from the logos that have already been uploaded.
					</Typography>

					<Button
						onClick={() => setShowChoices(true)}
						style={{ color: '#001940', textTransform: 'none', paddingLeft: 0 }}
						variant='text'
					>
						Click here to choose an existing logo
					</Button>
				</Box>
			</Box>

			{showChoices && <LogoChoices {...props} setShowChoices={setShowChoices} setShowPreview={setShowPreview} />}
		</div>
	)
}

export default FileUploadPage

function LogoChoices({ testInfo, setTestInfo, setShowChoices, setShowPreview }) {
	const testList = useSelector((state) => state.testList)
	const logoPaths = getLogoPaths(testList)
	const [images, setImages] = useState([...logoPaths])
	const [selectedIndex, setSelectedIndex] = useState(0)

	useEffect(() => {
		const fetchImages = async () => {
			try {
				const imagePromises = logoPaths.map(async (path) => {
					const imageUrl = await Storage.get(path, { level: 'public' })
					return { path, imageUrl }
				})
				const imageList = await Promise.all(imagePromises)
				setTimeout(() => {
					setImages(imageList)
				}, 200) // ####### ARTIFICIALLY SLOWED DOWN ###############
			} catch (error) {
				console.error('Error retrieving images:', error)
			}
		}

		fetchImages()
	}, [logoPaths])

	return (
		<Paper
			component={Box}
			position='absolute'
			top='50%'
			left='calc(50% + 110px)'
			sx={{ transform: 'translate(-50%, -50%)' }}
			bgcolor='white'
			elevation={5}
			padding='10px 20px 20px 20px'
			width={600}
		>
			{/* <Box height='70vh' overflowY='auto' width='100%'> */}
			<Grid
				container
				columns={2}
				columnGap={6}
				rowGap={2}
				width='100%'
				maxHeight='70vh'
				padding={2}
				marginBottom={4}
				sx={{ overflowY: 'auto' }}
				boxSizing='border-box'
			>
				{images.map((image, index) => {
					if (!image.path) {
						return <Skeleton variant='rectangular' width={240} height={100} />
					}

					return (
						<button
							key={image.path}
							style={{
								height: 100,
								width: 240,
								borderRadius: '0.5rem',
								opacity: selectedIndex === index ? 1 : 0.7,
								padding: '0px 20px',
								outline: selectedIndex === index ? '2px solid #001940' : 'none',
								boxShadow: '0 0 5px rgba(0, 0, 0, 0.3)',
							}}
							onClick={() => setSelectedIndex(index)}
						>
							<img src={image.imageUrl} alt={image.path} style={{ width: 200, maxHeight: 100 }} />
						</button>
					)
				})}
			</Grid>
			{/* </Box> */}
			<div style={{ display: 'flex', justifyContent: 'end', gap: 10 }}>
				<Button sx={{ color: '#001940', textTransform: 'none' }} variant='outlined' onClick={() => setShowChoices(false)}>
					Cancel
				</Button>
				<DarkButton
					onClick={() => {
						setTestInfo({
							...testInfo,
							imageURL: images[selectedIndex].imageUrl,
							file: 'FromStorage',
							logoPath: images[selectedIndex].path,
						})
						setShowChoices(false)
						setShowPreview(true)
					}}
					disabled={images[0].path === undefined || images[0].path === null}
				>
					Use Selected Image
				</DarkButton>
			</div>
		</Paper>
	)
}

function PreviewLogo({ testInfo, setTestInfo, setShowPreview }) {
	return (
		<div style={styles.container}>
			<Paper style={styles.previewBox} elevation={5}>
				<img src={testInfo.imageURL} alt='' style={{ maxWidth: 300, maxHeight: 150 }} />
				<img src={testInfo.imageURL} alt='' style={{ maxWidth: 200, maxHeight: 100, marginTop: 50 }} />
				<Typography width='90%' mt='20px'>
					This is how the logo will look on the pages of the test. If you would like to try a different image, click the delete
					button.
				</Typography>
				<DarkButton
					style={{ marginTop: 20 }}
					onClick={() => {
						setShowPreview(false)

						setTestInfo({ ...testInfo, imageURL: null, file: null, logoPath: '', fileType: '' })
					}}
				>
					Delete
				</DarkButton>
			</Paper>
		</div>
	)
}
