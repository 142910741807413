import React, { useEffect, useState } from 'react'
import styles from './Dashboard.module.css'
import BarChartIcon from '@mui/icons-material/BarChart'
import { useSelector } from 'react-redux'
import { Card } from '@mui/material'

// Adds getWeek functionality to Date
// eslint-disable-next-line
Date.prototype.getWeekNumber = function () {
	let d = new Date(Date.UTC(this.getFullYear(), this.getMonth(), this.getDate()))
	let dayNum = d.getUTCDay() || 7
	d.setUTCDate(d.getUTCDate() + 4 - dayNum)
	let yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1))
	return Math.ceil(((d - yearStart) / 86400000 + 1) / 7)
}

function CandidatesTab() {
	const applicantLists = useSelector((state) => state.applicantLists)
	const testList = useSelector((state) => state.testList)
	const [info, setInfo] = useState({ total: 0, monthly: 0, weekly: 0, shortlisted: 0 })

	// Calculates candidate info
	useEffect(() => {
		const newInfo = { total: 0, monthly: 0, weekly: 0, shortlisted: 0 }

		for (const key in applicantLists) {
			// Checks to make sure test exists in list before we count the applicants
			let found = false
			for (const test of testList) {
				if (test.id === key) {
					found = true
					break
				}
			}
			if (!found) continue

			for (const applicant of applicantLists[key]) {
				newInfo.total++

				if (applicant.shortlisted) {
					newInfo.shortlisted++
				}

				if (new Date(applicant.createdAt).getMonth() === new Date().getMonth()) {
					newInfo.monthly++
				}

				if (new Date(applicant.createdAt).getWeekNumber() === new Date().getWeekNumber()) {
					newInfo.weekly++
				}
			}
		}

		setInfo(newInfo)
		// eslint-disable-next-line
	}, [])

	return (
		<Card className={styles['item-container']}>
			<div className={styles['item-title']}>
				<BarChartIcon style={{ color: '#001940', height: 30, width: 30 }} />
				Candidates
			</div>
			<div className={styles['candidates-grid']}>
				<div className={styles['candidates-metric']}>
					<span className={styles['candidates-metric-title']}> Total </span>
					<span className={styles['candidates-metric-amount']}>{info.total}</span>
				</div>
				<div className={styles['candidates-metric']}>
					<span className={styles['candidates-metric-title']}> Shortlisted </span>
					<span className={styles['candidates-metric-amount']}>{info.shortlisted}</span>
				</div>
				<div className={styles['candidates-metric']}>
					<span className={styles['candidates-metric-title']}> This Month </span>
					<span className={styles['candidates-metric-amount']}>{info.monthly}</span>
				</div>
				<div className={styles['candidates-metric']}>
					<span className={styles['candidates-metric-title']}> This Week </span>
					<span className={styles['candidates-metric-amount']}>{info.weekly}</span>
				</div>
			</div>
		</Card>
	)
}

export default CandidatesTab
