import React, { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import styles from './TestSelector.module.css'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { TestContext } from '../TestContext'
import { Paper } from '@mui/material'

function convertToMonthName(monthNumber) {
	switch (monthNumber) {
		case 0:
			return 'January'
		case 1:
			return 'February'
		case 2:
			return 'March'
		case 3:
			return 'April'
		case 4:
			return 'May'
		case 5:
			return 'June'
		case 6:
			return 'July'
		case 7:
			return 'August'
		case 8:
			return 'September'
		case 9:
			return 'October'
		case 10:
			return 'November'
		case 11:
			return 'December'
		default:
			return ''
	}
}

function TestSelector() {
	const { test, setTest } = useContext(TestContext)
	const testList = useSelector((state) => state.testList)
	const [showDropdown, setShowDropdown] = useState(false)

	const [dateString, setDateString] = useState('')

	// console.log(new Date().getMonth())

	useEffect(() => {
		if (!test?.createdAt) return

		const date = new Date(test.createdAt)

		setDateString(convertToMonthName(date.getMonth()) + ' ' + date.getFullYear())
	}, [test])

	return (
		<div className={styles['dropdown-container']}>
			<Paper
				component='button'
				className={styles['current-item']}
				onClick={() => setShowDropdown((prev) => !prev)}
				data-cy='test-selector-button'
			>
				<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
					<span
						style={{
							color: '#001940',
							fontSize: 16,
							fontWeight: 'var(--bold)',
							width: 215,
							textOverflow: 'ellipsis',
							whiteSpace: 'nowrap',
							overflow: 'hidden',
							textAlign: 'left',
						}}
					>
						{test ? test.testName : ''}
					</span>
					<span style={{ fontSize: 14, opacity: 0.8 }}>{dateString}</span>
				</div>

				<ArrowDropDownIcon />
			</Paper>
			{showDropdown && (
				<Paper component='ul' className={styles['dropdown-list']} elevation={12}>
					{testList.map((item) => {
						const testDate = new Date(item.createdAt)

						return (
							<li
								className={styles['dropdown-list-item']}
								key={item.testName}
								style={test && item === test ? { borderLeft: '5px solid #001940dd', background: '#00194011' } : {}}
								onClick={() => {
									setShowDropdown(false)
									setTest(item)
								}}
							>
								<span style={{ whiteSpace: 'nowrap' }}>{item.testName}</span>
								<span style={{ fontSize: 14, opacity: 0.8, fontWeight: 'normal' }}>
									{convertToMonthName(testDate.getMonth()) + ' ' + testDate.getFullYear()}
								</span>
							</li>
						)
					})}
				</Paper>
			)}
		</div>
	)
}

export default TestSelector
