import React, { useContext } from 'react'
import styles from '../Candidates.module.css'
import { RadialBarChart, RadialBar, PolarAngleAxis } from 'recharts'
import BarChartIcon from '@mui/icons-material/BarChart'

import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded'
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded'
import CallRoundedIcon from '@mui/icons-material/CallRounded'
import HomeRoundedIcon from '@mui/icons-material/HomeRounded'
import { CandidatesContext } from '../CandidatesContext'
import { addEndingToNumber } from '../../../../lib/utility'

const iconStyle = {
	opacity: 0.7,
	width: 30,
	height: 30,
	paddingRight: 10,
}
const diameter = 150

function InfoPDF() {
	const { candidate } = useContext(CandidatesContext)

	return (
		<div className={`${styles['info-container']} pdf-item`} id='pdf-container' style={{ width: 'calc(100% - 40px)' }}>
			<span style={{ fontSize: 16 }}>
				<b>{candidate.name}</b>
			</span>
			<div style={{ display: 'flex', gap: 20, alignItems: 'center', width: '100%' }}>
				<RadialBarChart
					width={diameter}
					height={diameter}
					innerRadius={diameter / 2}
					data={[candidate]}
					barSize={10}
					startAngle={90}
					endAngle={-270}
				>
					<PolarAngleAxis type='number' domain={[0, 100]} angleAxisId={0} tick={false} radius={500} />
					<RadialBar
						background
						clockWise
						dataKey='autoScore'
						isAnimationActive={false}
						cornerRadius={diameter / 2}
						fill='#32a846'
						label={{
							fill: 'black',
							position: 'center',
							style: { fontWeight: 'var(--bold)', fontSize: '16px' },
							formatter: (label) => {
								return label + '%'
							},
						}}
					/>
				</RadialBarChart>
				<div className={styles['metrics-grid']} style={{ rowGap: 10 }}>
					<div className={styles['metric']}>
						<div className={styles['title']}>
							<BarChartIcon style={iconStyle} />
							Rank
						</div>
						<span>{addEndingToNumber(candidate.rank)}</span>
					</div>

					<div className={styles['metric']}>
						<div className={styles['title']}>
							<AccessTimeRoundedIcon style={iconStyle} />
							Test Time
						</div>
						<span>{candidate.testTime}</span>
					</div>

					<div className={styles['metric']}>
						<div className={styles['title']}>
							<CallRoundedIcon style={iconStyle} />
							Phone
						</div>
						<span>{candidate.phone}</span>
					</div>

					<div className={styles['metric']}>
						<div className={styles['title']}>
							<CalendarMonthRoundedIcon style={iconStyle} />
							Completed
						</div>
						<span>{candidate.dateSubmitted}</span>
					</div>

					<div className={styles['metric']}>
						<div className={styles['title']}>
							<AccessTimeRoundedIcon style={iconStyle} />
							Case Study Time
						</div>
						<span>{candidate.caseStudyTime}</span>
					</div>

					<div className={styles['metric']}>
						<div className={styles['title']}>
							<HomeRoundedIcon style={iconStyle} />
							Location
						</div>
						<span>{candidate.location}</span>
					</div>
				</div>
			</div>
		</div>
	)
}

export default InfoPDF
