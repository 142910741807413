export function debounce(cb, delay) {
	let timeout

	return (...args) => {
		clearTimeout(timeout)
		timeout = setTimeout(() => {
			cb(...args)
		}, delay)
	}
}

export function throttle(cb, delay = 250) {
	let shouldWait = false

	return (...args) => {
		if (shouldWait) return

		cb(...args)
		shouldWait = true
		setTimeout(() => {
			shouldWait = false
		}, delay)
	}
}

// Returns date strings in mm/dd format for last 5 days (including current day)
export function getLast5Days() {
	const date = new Date()
	let dateString = []

	for (let i = 4; i >= 0; i--) {
		const temp = new Date(date.getTime() - i * 24 * 60 * 60 * 1000)
		dateString.push(temp.getMonth() + 1 + '/' + temp.getDate())
	}

	return dateString
}

// Returns array of number of applicants for last 5 days (including current day)
export function getApplicantNumbers(applicants, lastFiveDates) {
	let numAppsByDate = []

	for (let date of lastFiveDates) {
		numAppsByDate[date] = 0
	}

	for (let applicant of applicants) {
		const currDT = parseAWSDateTime(applicant.createdAt)
		numAppsByDate[currDT] += 1
	}

	return numAppsByDate
}

// Passes in string in "mm/dd" format and returns relevant string ie "1/14" returns "Jan 14"
export function convertMonthDayString(mdString) {
	let retString = ''
	let temp = mdString.split('/')

	switch (temp[0]) {
		case '1':
			retString += 'Jan'
			break
		case '2':
			retString += 'Feb'
			break
		case '3':
			retString += 'Mar'
			break
		case '4':
			retString += 'April'
			break
		case '5':
			retString += 'May'
			break
		case '6':
			retString += 'June'
			break
		case '7':
			retString += 'July'
			break
		case '8':
			retString += 'Aug'
			break
		case '9':
			retString += 'Sept'
			break
		case '10':
			retString += 'Oct'
			break
		case '11':
			retString += 'Nov'
			break
		case '12':
			retString += 'Dec'
			break
		default:
			retString += '??'
			break
	}

	retString += ' '

	retString += temp[1]

	return retString
}

// Returns date string in "mm/dd" format
// 2022-05-12T19:50:07.937Z    <--- example AWSDateTime, returns "5/12"
export function parseAWSDateTime(dtString) {
	let retString = ''
	let temp = dtString.split('-')

	// Get rid of leading 0 in month
	if (temp[1][0] !== '0') retString += temp[1][0]
	retString += temp[1][1]

	// Get rid of leading 0 in day
	retString += '/'
	if (temp[2][0] !== '0') retString += temp[2][0]
	retString += temp[2][1]

	return retString.toString()
}

// Returns date string in "Feb 18, 1998" format
// 2022-05-12T19:50:07.937Z    <--- example AWSDateTime, returns "May 12, 2022"
export function parseAWSDateTimeWithYear(dtString) {
	let retString = ''
	let monthDay = parseAWSDateTime(dtString)
	let temp = dtString.split('-')

	retString += convertMonthDayString(monthDay)

	retString += ', ' + temp[0]

	return retString
}

export function convertToHMSTimeString(time) {
	let numHours = parseInt(time / 3600000)

	time = time - numHours * 3600000

	let numMinutes = parseInt(time / 60000)

	time = time - numMinutes * 60000

	let numSeconds = parseInt(time / 1000)

	if (numHours > 0) return numHours.toString() + 'h ' + numMinutes.toString() + 'm ' + numSeconds.toString() + 's'
	else return numMinutes.toString() + 'm ' + numSeconds.toString() + 's'
	// console.log(timeString)
}

export function convertToMMSSTimeString(time) {
	let numMinutes = parseInt(time / 60000)

	time = time - numMinutes * 60000

	let numSeconds = parseInt(time / 1000)

	let timeString = numMinutes.toString() + 'm ' + numSeconds.toString() + 's'
	// console.log(timeString)
	return timeString
}

export function convertToMMSSNoLabelTimeString(time) {
	let numMinutes = parseInt(time / 60000)

	time = time - numMinutes * 60000

	let numSeconds = parseInt(time / 1000)

	let minString = numMinutes.toString()
	let secString = numSeconds.toString()

	if (minString.length === 1) {
		minString = '0' + minString
	}

	if (secString.length === 1) {
		secString = '0' + secString
	}

	let timeString = minString + ':' + secString
	// console.log(timeString)
	return timeString
}

export function convertToReadablePhoneNumber(number) {
	if (!number) return number

	number = number.replace(/ /g, '')
	number = number.replace(/-/g, '')

	if (number.length !== 10 && number.length !== 11) return number

	if (number.length === 10) return number.substring(0, 3) + '-' + number.substring(3, 6) + '-' + number.substring(6)
	else return '(' + number.substring(0, 1) + ') ' + number.substring(1, 4) + '-' + number.substring(4, 7) + '-' + number.substring(7)
}

export function convertScoreToPercent(score, maxScore) {
	let percent = (score / maxScore) * 100
	return Math.round(percent).toString() + '%'
}

export function convertToPlayerTimeString(milli) {
	if (milli < 0) {
		milli = 0
	}

	let minutes = milli / 60000
	let seconds = (milli / 1000) % 60

	minutes = Math.floor(minutes)
	seconds = Math.floor(seconds)

	if (seconds < 10) {
		seconds = '0' + seconds.toString()
	} else {
		seconds = seconds.toString()
	}
	if (minutes < 10) {
		minutes = '0' + minutes.toString()
	} else {
		minutes = minutes.toString()
	}

	let timeString = minutes + ':' + seconds

	return timeString
}

export function calculateMaxScoringTags(scoringTags, questions) {
	let maxTags = {}

	const questionIDs = questions.map((q) => q.id)

	for (let tag of scoringTags) {
		if (questionIDs.includes(tag.questionID) && tag.dataMapping !== 'TechnologySkills') {
			if (maxTags[tag.dataMapping] === undefined) {
				maxTags[tag.dataMapping] = 0.0
			}
			maxTags[tag.dataMapping] += tag.maxScore
		}
	}

	return maxTags
}

export function calculateUserTagTotals(scoringTags, tagResps, userID) {
	let tagTotals = {}

	if (tagResps === undefined) {
		return tagTotals
	}

	for (const resp of tagResps) {
		let dataMapping = ''

		// Skip if not a response from this specific user
		if (resp.userID !== userID) continue

		// Find relevant dataMapping for specific response
		for (const tag of scoringTags) {
			if (resp.scoringTagID === tag.id) {
				dataMapping = tag.dataMapping
			}
		}

		if (dataMapping === '' || dataMapping === 'TechnologySkills') continue

		if (tagTotals[dataMapping] === undefined) {
			tagTotals[dataMapping] = 0
		}

		// console.log(resp.score, dataMapping)
		tagTotals[dataMapping] += resp.score
	}

	// console.log(tagTotals)

	return tagTotals
}

export function stringCompare(str1, str2) {
	if (str1 === str2) return 0
	else if (str1 < str2) return -1
	else return 1
}

export function calculateWeightedScore(userTagTotals, maxTags, origScore, customScoringWeights) {
	if (!customScoringWeights) return origScore

	// Calculates their score without any scoring tags (this just comes from the position info button)
	let weightedScore = origScore
	for (const key in userTagTotals) {
		weightedScore -= userTagTotals[key]
	}

	let scoringTagMaxScore = 0
	for (const key in maxTags) {
		scoringTagMaxScore += maxTags[key]
	}

	// Calculates what percentage of the overall score each category makes up in the original weighting scheme
	const origWeights = {}
	for (const key in maxTags) {
		origWeights[key] = Math.round((maxTags[key] / scoringTagMaxScore) * 1000) / 1000 // Rounds to 3 decimal places (or 1 decimal place in 10.5% format)
	}

	const newUserTagTotals = {}
	for (const key in customScoringWeights) {
		newUserTagTotals[key] = (userTagTotals[key] * customScoringWeights[key]) / origWeights[key]
		weightedScore += newUserTagTotals[key]
	}

	return weightedScore
}

export function getApplicantData(applicant, totalScore) {
	return {
		userID: applicant.id,
		testID: applicant.testID,
		shortlisted: applicant.shortlisted,
		name: applicant.firstName + ' ' + applicant.lastName,
		scoreValue: applicant.score,
		autoScore: Math.round((applicant.score / totalScore) * 100),
		adjustedScore: Math.round((applicant.score / totalScore) * 100),
		phone: convertToReadablePhoneNumber(applicant.phone),
		testTimeMS: applicant.testTime,
		testTime: convertToHMSTimeString(applicant.testTime),
		caseStudyTime: convertToHMSTimeString(applicant.caseStudyTime),
		location: applicant.city + ', ' + applicant.province,
		createdAt: applicant.createdAt,
		dateSubmitted: parseAWSDateTimeWithYear(applicant.createdAt),
		rank: 0,
		codeUsed: applicant.codeUsed,
	}
}

export function convertDataMappingName(dataMapping) {
	switch (dataMapping) {
		case 'CommunicationSkills':
			return 'Communication'
		case 'TechnologySkills':
			return 'Technology'
		case 'AttentionToDetail':
			return 'Attention To Detail'
		case 'PositionRequirements':
			return 'Position Requirements'
		case 'REKnowledge':
			return 'RE Knowledge'
		default:
			return dataMapping
	}
}

export function calculateAutoScoringTagWeights(questions) {
	const totals = {}

	for (const question of questions) {
		if (!question.scoringTags) continue

		for (const tag of question.scoringTags) {
			if (tag.dataMapping === 'TechnologySkills') continue
			totals[tag.dataMapping] = totals[tag.dataMapping] ? totals[tag.dataMapping] + tag.maxScore : tag.maxScore
		}
	}

	const weights = []
	let totalScore = 0

	for (const key in totals) {
		totalScore += totals[key]
	}

	let weightTotalCheck = 0
	let index = 0
	let numTags = Object.keys(totals).length
	for (const key in totals) {
		index++
		weights.push({
			label: key,
			value: Math.round((totals[key] / totalScore) * 100),
			locked: false,
			lastEdited: index % numTags,
		})

		weightTotalCheck += weights[weights.length - 1].value // Adds most recent value to the total
	}

	// Makes sure weights add up to 100.
	// If they do not add up to 100, then simply add the difference to any weight (it should be at most 1 off of 100)
	// We don't have to worry about where we add it because we will adjust the weights later
	if (weightTotalCheck !== 100 && weights.length > 0) {
		weights[0].value += 100 - weightTotalCheck
	}

	return weights
}
