import React, { useContext } from 'react'
import Table from '../Table/Table'
import MenuButton from '../MenuButton/BannerMenuButton'

import StarIcon from '@mui/icons-material/Star'
import StarOutlinedIcon from '@mui/icons-material/StarBorderOutlined'

import { stringCompare } from '../../functions/CalculationFunctions'
import { CandidatesContext } from './Candidates/CandidatesContext'
import { updateDBUser } from '../../lib/database'
import { useDispatch } from 'react-redux'
import InfoTooltipButton from '../InfoTooltip/InfoTooltipButton'

const columns = [
	{
		label: 'Name',
		sortingKey: 'name',
		sortDscFunc: function (a, b) {
			let aName = a.name.toLowerCase()
			let bName = b.name.toLowerCase()
			if (aName > bName) return 1
			if (aName < bName) return -1
			return 0
		},
		sortAscFunc: function (a, b) {
			let aName = a.name.toLowerCase()
			let bName = b.name.toLowerCase()
			if (aName > bName) return -1
			if (aName < bName) return 1
			return 0
		},
	},
	{
		label: 'Short Listed',
		sortingKey: 'shortlisted',
		sortDscFunc: function (a, b) {
			return b.shortlisted - a.shortlisted
		},
		sortAscFunc: function (a, b) {
			return a.shortlisted - b.shortlisted
		},
	},
	{ label: 'Rank' },
	{ label: 'Score' },
	{
		label: 'Test Time',
		sortingKey: 'time',
		sortDscFunc: function (a, b) {
			return a.testTimeMS - b.testTimeMS
		},
		sortAscFunc: function (a, b) {
			return b.testTimeMS - a.testTimeMS
		},
	},
	{
		label: 'Date Completed',
		sortingKey: 'date',
		sortDscFunc: function (a, b) {
			return stringCompare(b.createdAt, a.createdAt)
		},
		sortAscFunc: function (a, b) {
			return stringCompare(a.createdAt, b.createdAt)
		},
	},
]

const sortingData = {
	shortlisted: 'none',
	rank: 'none',
	name: 'none',
	autoScore: 'none',
	time: 'none',
	date: 'none',
}

function CandidateTable({ rows, setRows, origRows }) {
	const { setView, setIndex } = useContext(CandidatesContext)
	const dispatch = useDispatch()

	function formatRow(row, index) {
		const { name, autoScore, dateSubmitted, shortlisted, rank, testTime } = row

		return (
			<tr key={index}>
				<td>
					<MenuButton
						style={{ color: 'var(--button-text)', display: 'inline-block', textAlign: 'left' }}
						onClick={() => {
							setView('candidate')
							setIndex(index)
						}}
					>
						{name}
					</MenuButton>
				</td>
				<td>
					<MenuButton
						data-cy='shortlisted-button'
						style={{ margin: 'auto' }}
						onClick={() => {
							const updatedCandidate = { id: row.userID, shortlisted: !row.shortlisted, testID: row.testID }
							updateDBUser(updatedCandidate, dispatch)
						}}
					>
						{shortlisted ? (
							<StarIcon style={{ color: '#001940', height: 24, width: 24 }} data-cy='shortlisted-true-icon' />
						) : (
							<StarOutlinedIcon style={{ color: '#001940', height: 24, width: 24 }} data-cy='shortlisted-false-icon' />
						)}
					</MenuButton>
				</td>
				<td>{rank}</td>
				<td>{autoScore}%</td>
				<td>{testTime}</td>
				<td>
					<InfoTooltipButton
						style={{ color: 'var(--button-text)', fontSize: '14px', display: 'inline' }}
						tooltipText={row.codeUsed ? row.codeUsed : 'Unknown code.'}
					>
						{dateSubmitted}
					</InfoTooltipButton>
				</td>
			</tr>
		)
	}

	return <Table columns={columns} rows={rows} setRows={setRows} origRows={origRows} formatRow={formatRow} sortingData={sortingData} />
}

export default CandidateTable
