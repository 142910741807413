import React, { useState, useEffect } from 'react'
import LockIcon from '@mui/icons-material/LockOutlined'
import styles from './Dashboard.module.css'
import { useSelector } from 'react-redux'
import { Card } from '@mui/material'

function CodesTab() {
	const [codeInfo, setCodeInfo] = useState({ available: 0, full: 0, active: 0, remaining: 0 })
	const codeList = useSelector((state) => state.accessCodes)
	const client = useSelector((state) => state.client)

	useEffect(() => {
		const newInfo = { available: 0, full: 0, active: 0, remaining: 0 }

		if (client.role === 'MASTER') newInfo.available = 'N/A'
		else newInfo.available = client.remainingCodeUses

		let totalAllowed = 0
		let totalUsed = 0

		for (const key in codeList) {
			const code = codeList[key]

			if (code.status === 'open') {
				if (code.timesUsed === code.numAllowed) {
					newInfo.full++
				} else {
					newInfo.active++
				}
			}
			totalAllowed += code.numAllowed
			totalUsed += code.timesUsed
		}

		if (client.role === 'MASTER') newInfo.remaining = 'N/A'
		else newInfo.remaining = (totalAllowed - totalUsed).toString() + '/' + totalAllowed

		setCodeInfo(newInfo)
		// eslint-disable-next-line
	}, [])

	return (
		<Card className={styles['item-container']}>
			<div className={styles['item-title']}>
				<LockIcon style={{ color: '#001940', height: 30, width: 30 }} />
				Access
			</div>
			<div className={styles['candidates-grid']}>
				<div className={styles['candidates-metric']}>
					<span className={styles['candidates-metric-title']}> Available </span>
					<span className={styles['candidates-metric-amount']}>{codeInfo.available}</span>
				</div>
				<div className={styles['candidates-metric']}>
					<span className={styles['candidates-metric-title']}> Full/Open </span>
					<span
						className={`${styles['candidates-metric-amount']} ${
							codeInfo.full > 0 ? styles['show-red-dot'] : styles['show-green-dot']
						}`}
					>
						{codeInfo.full}
					</span>
				</div>
				<div className={styles['candidates-metric']}>
					<span className={styles['candidates-metric-title']}> Active </span>
					<span className={`${styles['candidates-metric-amount']} ${styles['show-green-dot']}`}>{codeInfo.active}</span>
				</div>
				<div className={styles['candidates-metric']}>
					<span className={styles['candidates-metric-title']}> Remaining </span>
					<span className={styles['candidates-metric-amount']}>{codeInfo.remaining}</span>
				</div>
			</div>
		</Card>
	)
}

export default CodesTab
