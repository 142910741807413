import { Paper } from '@mui/material'
import React from 'react'

const tooltipStyle = {
	background: 'white',
	color: '#001940',
	padding: 10,
	position: 'absolute',
	fontSize: 14,
	top: '110%',
	left: '20%',
	transform: 'translate(-50%, 0%)',
	zIndex: 10,
}

/**
 *
 * @param {String} toolTipText - Text displayed when tooltip is shown
 *
 * The tooltip interface displayed when tooltip is shown
 */
function InfoTooltip({ tooltipText }) {
	return (
		<Paper style={tooltipStyle} elevation={4}>
			{tooltipText}
		</Paper>
	)
}

export default InfoTooltip
