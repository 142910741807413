import React, { useContext, useState } from 'react'
import CaseStudyGraph from '../Breakdown/CaseStudyGraph'
import RadarGraph from '../Breakdown/RadarGraph'
import ResponsesPDF from './ResponsesPDF'
import InfoPDF from './InfoPDF'
import BTRHire from '../../../../images/BTRHireClientTransparent.png'

import styles from '../Candidates.module.css'

import BannerMenuButton from '../../../MenuButton/BannerMenuButton'
import DownloadIcon from '@mui/icons-material/Download'
import { CandidatesContext } from '../CandidatesContext'
import { TestContext } from '../../TestContext'
import PDFProgressModal from './PDFProgressModal'

function CandidatePDF() {
	const { candidate } = useContext(CandidatesContext)
	const { test } = useContext(TestContext)
	const [progressModalOpen, setProgressModalOpen] = useState(false)

	return (
		<>
			<BannerMenuButton
				customclasses={styles['candidate-section-button']}
				onClick={async () => {
					setProgressModalOpen(true)
				}}
			>
				<DownloadIcon style={{ opacity: 0.8, marginRight: 5 }} /> Export to PDF
			</BannerMenuButton>
			<div id='myMm' style={{ height: '1mm' }} />
			<div
				id='candidate-pdf'
				style={{
					position: 'fixed',
					zIndex: -1,
					display: 'flex',
					minHeight: '1120px',
					width: '216mm',
					flexDirection: 'column',
					alignItems: 'flex-start',
					gap: 20,
					// background: 'var(--background-color-two)',
					padding: '20px 20px 100px 20px',
				}}
			>
				<div
					style={{
						padding: '5px 15px',
						borderRadius: 10,
						backgroundColor: 'white',
						display: 'grid',
						placeContent: 'center',
						width: '200px',
					}}
					className='pdf-item'
				>
					<img src={BTRHire} alt='' style={{ width: '100%' }} />
				</div>
				<InfoPDF />
				<CaseStudyGraph usePDFVersion={true} />
				<RadarGraph usePDFVersion={true} />
				<ResponsesPDF />
			</div>
			<PDFProgressModal
				progressModalOpen={progressModalOpen}
				setProgressModalOpen={setProgressModalOpen}
				candidate={candidate}
				test={test}
			/>
		</>
	)
}

export default CandidatePDF
