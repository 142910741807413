// Adds a scoring tag, "tag", to a question with id of "questionID"
export function addScoringTag(questionID, tag, oldState, setState) {
	let newState = Object.assign({}, oldState)

	for (let question of newState.questions) {
		if (question.id === questionID) {
			if (!question.scoringTags) {
				question.scoringTags = []
			}

			question.maxScore += tag.maxScore
			question.scoringTags.push(tag)
			setState(newState)
			return
		}
	}
}

// Deletes a scoring tag, where "tag" is the tag name on the scoring tag (yikes that wording), from a question with id of "questionID"
export function deleteScoringTag(questionID, tag, oldState, setState) {
	const newState = Object.assign({}, oldState)

	// Search for question
	for (const question of newState.questions) {
		if (question.id === questionID) {
			// Search for scoring tag
			for (let i = 0; i < question.scoringTags.length; i++) {
				// If found scoring tag, delete it and remove the associated score from the question
				if (question.scoringTags[i].tag === tag) {
					question.maxScore -= question.scoringTags[i].maxScore
					question.scoringTags.splice(i, 1)
					setState(newState)
					return
				}
			}
		}
	}
}

/*  #####################################################################################
    #####################################################################################
    #####################################################################################
    ##################################################################################### */

export function createJobInfoQuestions(oldState, setState) {
	let newState = Object.assign({}, oldState)
	const ids = ['AboutThisOpportunity', 'Overview', 'Responsibilities', 'KeySkills']

	for (const id of ids) {
		newState.questions.push({
			id: id,
			format: 'Dropdown',
			questionText: '',
			section: id,
			hasNotes: false,
			helperText: '',
			maxScore: 1,
			order: 1,
			referenceID: null,
			scoringTags: [
				{
					tag: 'AccessDropdown',
					data: 'none',
					dataMapping: 'AttentionToDetail',
					maxScore: 1,
				},
			],
		})
	}

	setState(newState)
}

/*  #####################################################################################
    #####################################################################################
    #####################################################################################
    ##################################################################################### */

export function onTabPress(ref, e) {
	e.preventDefault()

	const currValue = ref.current.value
	const start = e.target.selectionStart
	const end = e.target.selectionEnd

	ref.current.value = currValue.substring(0, start) + '   ' + currValue.substring(end)
	e.target.selectionStart = start + 3
	e.target.selectionEnd = start + 3
}

export function findQuestionText(id, questions) {
	if (!questions) return ''

	for (const question of questions) {
		if (question.id === id) {
			return question.questionText
		}
	}

	return ''
}

export function updateQuestionText(id, value, oldState, setState) {
	const newState = Object.assign({}, oldState)
	const newQuestions = newState.questions.map((question) => {
		if (question.id === id) {
			return {
				...question,
				questionText: value,
			}
		}
		return question
	})
	newState.questions = newQuestions
	setState(newState)
}
