import { Box, Typography, Divider } from '@mui/material'
import YesNoToggle from './YesNoToggle'
import FormBuilder from './FormBuilder'

export default function DEIPage({ testInfo, setTestInfo }) {
	const handleToggle = (_, newValue) => {
		if (newValue !== null) {
			setTestInfo({ ...testInfo, includeDEISurvey: newValue })
		}
	}

	return (
		<Box display='flex' flexDirection='column' p='30px' width='600px' margin='auto' alignItems='start'>
			<Typography color='black' textAlign='left'>
				If your firm is looking to track self-identification data for Diversity, Equity, and Inclusion initiatives, you can create
				the survey here. This page will be optional to your candidates, and responses will not be tied to their individual tests.
			</Typography>
			<Divider sx={{ width: '80%', margin: '30px 0' }}></Divider>
			<YesNoToggle
				toggleValue={testInfo.includeDEISurvey}
				handleToggle={handleToggle}
				label={
					<Typography fontWeight='bold' mb={1}>
						Include survey?
					</Typography>
				}
			/>
			{testInfo.includeDEISurvey === true && (
				<>
					<Typography textAlign='left' fontSize={16} color='rgb(40, 40, 40, 0.8)' mt={2}>
						Note that in order to allow a user to clarify why they selected "Other" as a reponse, you must click the "Add Other"
						button.
					</Typography>
					<FormBuilder
						questions={testInfo.selfIdentificationQuestions}
						setQuestions={(newQuestions) => {
							setTestInfo({ ...testInfo, selfIdentificationQuestions: newQuestions })
						}}
					/>
				</>
			)}
		</Box>
	)
}
