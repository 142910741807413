import React, { useEffect, useState } from 'react'

import AddIcon from '@mui/icons-material/Add'
import { RadioGroup, FormControlLabel, Radio, Typography } from '@mui/material'

import { possibleFilters } from '../Data'
import TypeInputHandler from './TypeInputs/TypeInputHandler'
import BannerMenuButton from '../../MenuButton/BannerMenuButton'
import FormButtons from '../../FormButtons'

const radioButtonSX = {
	root: {
		'MuiSvgIcon-root': {
			height: 10,
			width: 10,
			fill: 'green',
		},
	},
}

function getConditionByLabel(filter, label) {
	for (const condition of filter.conditions) {
		if (condition.label === label) return condition
	}
	return null
}

/**
 * Component that handles adding filters. Displays the menu to add the filter, selection of which filter to add (Score, Test Time, etc), as well as options
 * for each condition (greater than, less than, etc). Filters are in the form:
 * 'Score greater than 60%' where 'Score' is the category/type, 'greater than' is the condition, and '60' is the value
 *
 * @param {Function} addFilter - Adds a filter to the list of filters
 *
 */
function AddFilter({ addFilter }) {
	const [showAddMenu, setShowAddMenu] = useState(false)

	const [currFilter, setCurrFilter] = useState(possibleFilters[0]) // Current filter type that is being added
	const [currConditionLabel, setCurrConditionLabel] = useState('') // Current condition of the filter (ie greater than, less than)

	const [newFilterValue, setNewFilterValue] = useState('') // Value that the client will edit for the new filter (ie choosing a score of 65% if filtering by Score)
	const [errorMessage, setErrorMessage] = useState('')

	function submitFilter() {
		const newFilter = {
			value: newFilterValue,
			condition: currConditionLabel,
			category: currFilter.type,
		}
		const condition = getConditionByLabel(currFilter, currConditionLabel)

		if (condition?.checkIfValidValue) {
			if (!condition.checkIfValidValue(newFilterValue)) {
				setErrorMessage(condition.errorMessage)
				return
			}
		}
		addFilter(newFilter)
		setShowAddMenu(false)
	}

	useEffect(() => {
		if (currFilter) setCurrConditionLabel(currFilter.conditions[0].label)
		else setCurrConditionLabel('')
	}, [currFilter])

	useEffect(() => {
		setErrorMessage('')
	}, [currFilter, currConditionLabel])

	return (
		<div style={{ position: 'relative' }}>
			<BannerMenuButton
				style={{ color: 'rgb(0, 0, 0, 0.8)', fontWeight: 'var(--semi-bold)', fontSize: 14 }}
				onClick={() => {
					setShowAddMenu(!showAddMenu)
				}}
			>
				<AddIcon style={{ height: 22, width: 22, color: 'inherit', marginRight: 5 }} /> Add filter
			</BannerMenuButton>
			{showAddMenu && (
				<div style={{ display: 'flex', gap: 10, position: 'absolute', transform: 'translateY(20px)', zIndex: 10 }}>
					<div
						style={{
							height: 'fit-content',
							borderRadius: 10,
							background: 'white',
							boxShadow: 'var(--box-shadow-one)',
							minWidth: 50,
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'flex-start',
							flexShrink: 0,
							overflow: 'hidden',
						}}
					>
						{possibleFilters.map((filter, index) => {
							return (
								<BannerMenuButton
									style={{
										fontWeight: filter.type === currFilter?.type ? 'var(--bold)' : 'var(--semi-bold)',
										fontSize: 14,
										color: 'rgb(40, 40, 40)',
										textAlign: 'left',
										width: '100%',
										padding: '5px 15px',
										background: filter.type === currFilter?.type ? '#00194022' : 'transparent',
									}}
									key={index}
									onClick={() => {
										setCurrFilter(filter)
									}}
								>
									{filter.type}
								</BannerMenuButton>
							)
						})}
					</div>
					{currFilter && (
						<div
							style={{
								padding: '10px 25px 15px 15px',
								borderRadius: 10,
								background: 'white',
								boxShadow: 'var(--box-shadow-one)',
								flexShrink: 0,
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'flex-start',
								gap: 5,
								height: 'fit-content',
								minWidth: 200,
							}}
						>
							{currConditionLabel && (
								<RadioGroup
									value={currConditionLabel}
									onChange={(e) => {
										setCurrConditionLabel(e.target.value)
									}}
								>
									{currFilter.conditions.map((condition, index) => {
										return (
											<div style={{ display: 'flex', flexDirection: 'column' }} key={index}>
												<FormControlLabel
													value={condition.label}
													control={<Radio sx={radioButtonSX} />}
													label={
														<Typography
															style={{ fontWeight: 'normal', fontSize: 14, color: 'rgb(40, 40, 40)' }}
														>
															{condition.label}
														</Typography>
													}
												/>
												{condition.label === currConditionLabel && (
													<TypeInputHandler
														condition={condition}
														newFilterValue={newFilterValue}
														setNewFilterValue={setNewFilterValue}
													/>
												)}
												{condition.label === currConditionLabel && errorMessage.length > 0 && (
													<span style={{ fontSize: 14, fontWeight: 'normal', color: 'var(--error-red)' }}>
														{errorMessage}
													</span>
												)}
											</div>
										)
									})}
								</RadioGroup>
							)}
							<FormButtons onSubmit={submitFilter} onCancel={() => setShowAddMenu(false)} />
						</div>
					)}
				</div>
			)}
		</div>
	)
}

export default AddFilter
