import React from 'react'
import { Typography } from '@mui/material'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import ComputerIcon from '@mui/icons-material/Computer'

function InvalidSizeError() {
	return (
		<div
			style={{
				width: '100vw',
				height: '100vh',
				background: 'rgb(138, 190, 206)',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				position: 'fixed',
				top: 0,
				left: 0,
				zIndex: 10000,
				overflow: 'hidden',
			}}
		>
			<div
				style={{
					background: 'white',
					width: 'min(400px, 70%)',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
					borderRadius: '10px',
					padding: '30px',
				}}
			>
				<div style={{ display: 'flex', gap: 20, justifyContent: 'center', alignItems: 'center', marginBottom: 20 }}>
					<ErrorOutlineIcon style={{ height: 50, width: 50, color: '#001940' }} />
					<ComputerIcon style={{ height: 50, width: 50, color: '#001940' }} />
				</div>
				<Typography style={{ textAlign: 'center', fontSize: 16, color: '#001940' }}>
					Oh no! Your current screen size is too small to display all the necessary information. We apologize for the
					inconvenience while we work to develop our mobile application. Currently, a minimum resolution of 950x550 is required.
				</Typography>
			</div>
		</div>
	)
}

export default InvalidSizeError
