import React, { useState } from 'react'
import Pagination from './Pagination'
import SortableColumn from './SortableColumn'
import styles from './Table.module.css'

function Table({ columns, rows, setRows, origRows, formatRow, sortingData }) {
	const [pageRows, setPageRows] = useState([])

	return (
		<div style={{ width: '100%', flex: '1 1 auto', maxHeight: '85%' }}>
			<Pagination rows={rows} setPageRows={setPageRows} />
			<div style={{ width: '100%', height: '100%', overflowY: 'auto' }}>
				<table className={styles['custom-table']}>
					<thead>
						<tr>
							{columns.map((column, index) => {
								if (column.sortingKey) {
									return (
										<th key={index}>
											<SortableColumn
												columnTitle={column.label}
												sortAscFunc={column.sortAscFunc}
												sortDscFunc={column.sortDscFunc}
												origRows={origRows}
												setRows={setRows}
												sortingData={sortingData}
												sortingKey={column.sortingKey}
											/>
										</th>
									)
								} else {
									return <th key={index}>{column.label}</th>
								}
							})}
						</tr>
					</thead>
					<tbody>
						{pageRows.map(([row, index]) => {
							return formatRow(row, index)
						})}
					</tbody>
				</table>
			</div>
		</div>
	)
}

export default Table
