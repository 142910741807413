import { Typography, Button, Stack, TextField, Card, Box, Divider, Select, MenuItem, FormControl, InputLabel } from '@mui/material'
import React from 'react'
import uuid from 'react-uuid'

import { DarkButton } from '../../../Button/DarkButton'

import DeleteIcon from '@mui/icons-material/Delete'
import CloseIcon from '@mui/icons-material/Close'

import YesNoToggle from './DEI/YesNoToggle'

function KnowledgeAssessmentPage({ testInfo, setTestInfo }) {
	const questions = testInfo.questions.filter((question) => question.section === 'KnowledgeAssessment')

	return (
		<div
			style={{
				display: 'flex',
				minHeight: '100%',
				width: '100%',
				alignItems: 'center',
				flexDirection: 'column',
				padding: 30,
				boxSizing: 'border-box',
			}}
		>
			<div style={{ width: 600, display: 'flex', flexDirection: 'column' }}>
				<Typography textAlign='left'>
					Add up to four questions to test the general knowledge of your candidates. These questions are untimed and will have
					their scores associated with the Position Requirements section.
				</Typography>
				<Divider sx={{ margin: '30px 0', width: '100%' }} />
				<FormBuilder questions={questions} testInfo={testInfo} setTestInfo={setTestInfo} />
			</div>
		</div>
	)
}

export default KnowledgeAssessmentPage

function FormBuilder({ questions, testInfo, setTestInfo }) {
	const addQuestion = () => {
		const newQuestion = {
			id: uuid(),
			format: 'MultipleChoice',
			questionText: '',
			section: 'KnowledgeAssessment',
			hasNotes: false,
			helperText: '',
			maxScore: 0,
			order: questions.length + 1,
			questionAnswers: [],
		}
		setTestInfo({ ...testInfo, questions: [...testInfo.questions, newQuestion] })
	}

	const calculateMaxScore = (question) => {
		if (question.format === 'MultipleChoice') {
			return Math.max(...question.questionAnswers.map((answer) => answer.score))
		} else if (question.format === 'Checkbox') {
			return question.questionAnswers.reduce((sum, answer) => sum + answer.score, 0)
		}
		return 0 // Default to 0 if the format is unknown or not provided
	}

	const updateQuestion = (newQuestion) => {
		// Update the maxScore and scoringTags here so we don't have to worry about doing it
		// whenever we update/delete questionAnswers or change the question type
		newQuestion.maxScore = calculateMaxScore(newQuestion)
		newQuestion.scoringTags = newQuestion.questionAnswers.map((questionAnswer) => {
			return {
				tag: 'MatchResponse',
				data: questionAnswer.answerText,
				dataMapping: 'PositionRequirements',
				maxScore: questionAnswer.score,
			}
		})

		console.log(newQuestion)

		const updatedQuestions = testInfo.questions.map((question) => {
			if (question.id === newQuestion.id) {
				return newQuestion
			}
			return question
		})

		setTestInfo({ ...testInfo, questions: updatedQuestions })
	}

	const deleteQuestion = (questionID) => {
		const newQuestions = testInfo.questions.filter((question) => question.id !== questionID)
		setTestInfo({ ...testInfo, questions: newQuestions })
	}

	return (
		<>
			<Stack direction='column' mt={4} gap={4}>
				{questions.map((question, index) => (
					<Question
						question={question}
						index={index}
						deleteQuestion={deleteQuestion}
						updateQuestion={updateQuestion}
						key={question.id}
					/>
				))}
			</Stack>

			{questions?.length < 4 && (
				<DarkButton
					sx={{ textTransform: 'none', marginTop: '30px', width: 'fit-content' }}
					variant='contained'
					onClick={addQuestion}
				>
					Add Question
				</DarkButton>
			)}
		</>
	)
}

function Question({ question, index, deleteQuestion, updateQuestion }) {
	const updateQuestionAnswer = (newQuestionAnswer, answerIndex) => {
		const updatedQuestionAnswers = [...question.questionAnswers]
		updatedQuestionAnswers[answerIndex] = newQuestionAnswer

		updateQuestion({ ...question, questionAnswers: updatedQuestionAnswers })
	}

	const deleteQuestionAnswer = (answerIndex) => {
		const updatedQuestionAnswers = question.questionAnswers.filter((_, index) => index !== answerIndex)

		updateQuestion({ ...question, questionAnswers: updatedQuestionAnswers })
	}

	return (
		<Card
			sx={{ padding: '20px 30px 20px 20px', display: 'flex', alignItems: 'start', flexDirection: 'column', width: 'fit-content' }}
			elevation={3}
		>
			<Stack direction='column' alignItems='start'>
				<Box display='flex' justifyContent='space-between' width='100%' alignItems='center' mb={2}>
					<Typography fontWeight='bold' color='rgba(40, 40, 40, 0.9)' fontSize={14}>
						Question {index + 1}
					</Typography>
					<Button onClick={() => deleteQuestion(question.id)}>
						<DeleteIcon style={{ color: 'rgb(40, 40, 40, 0.8)' }} />
					</Button>
				</Box>

				<TextField
					multiline
					sx={{ width: '500px' }}
					size='small'
					label='Question'
					inputProps={{ style: { fontSize: 14 } }}
					InputLabelProps={{ style: { fontSize: 14 } }}
					defaultValue={question.questionText}
					onBlur={(e) => updateQuestion({ ...question, questionText: e.target.value })}
				/>

				<Typography mt={2} fontWeight='bold' color='rgba(40, 40, 40, 0.9)' fontSize={14}>
					Options
				</Typography>
				{question.questionAnswers.map((questionAnswer, answerIndex) => (
					<QuestionAnswer
						key={answerIndex + questionAnswer.answerText + questionAnswer.score}
						questionAnswer={questionAnswer}
						answerIndex={answerIndex}
						updateQuestionAnswer={updateQuestionAnswer}
						deleteQuestionAnswer={deleteQuestionAnswer}
					/>
				))}
			</Stack>

			<Box display='flex' gap={2} width='100%' mt={6} alignItems='center'>
				<DarkButton
					sx={{ textTransform: 'none' }}
					variant='contained'
					onClick={() =>
						// Add blank questionAnswer with associated blank scoring tag
						updateQuestion({
							...question,
							questionAnswers: [...question.questionAnswers, { answerText: '', score: 0, isCorrect: false }],
						})
					}
				>
					Add Option
				</DarkButton>
			</Box>
			<Divider variant='middle' style={{ width: '100%', margin: '30px 0' }} />
			<Box display='flex' gap={2} width='100%' alignItems='center'>
				<YesNoToggle
					toggleValue={question.format === 'Checkbox'}
					handleToggle={() =>
						updateQuestion({
							...question,
							format: question.format === 'Checkbox' ? 'MultipleChoice' : 'Checkbox',
						})
					}
					label={<Typography>Allow multiple selections?</Typography>}
				/>
			</Box>
		</Card>
	)
}

const QuestionAnswer = ({ questionAnswer, answerIndex, updateQuestionAnswer, deleteQuestionAnswer }) => {
	return (
		<Stack mt={2} alignItems='end' direction='row' width='100%'>
			<TextField
				sx={{
					width: '100%',
					'& .MuiInputBase-input.Mui-disabled': {
						WebkitTextFillColor: 'rgb(40, 40, 40, 0.6)',
					},
				}}
				multiline
				size='small'
				label={`Option ${answerIndex + 1}`}
				inputProps={{ style: { fontSize: 14 } }}
				InputLabelProps={{ style: { fontSize: 14 } }}
				defaultValue={questionAnswer.answerText}
				onBlur={(e) => {
					updateQuestionAnswer({ ...questionAnswer, answerText: e.target.value }, answerIndex)
				}}
			/>
			<FormControl sx={{ minWidth: 80, marginLeft: 1 }}>
				<InputLabel sx={{ fontSize: 14 }} id={`QuestionAnswer${answerIndex}`}>
					Score
				</InputLabel>
				<Select
					size='small'
					label='Score'
					id={`QuestionAnswer${answerIndex}`}
					value={questionAnswer.score}
					onChange={(e) => {
						updateQuestionAnswer({ ...questionAnswer, score: e.target.value }, answerIndex)
					}}
					sx={{
						'& .MuiSelect-select.MuiSelect-standard': {
							fontSize: 14,
						},
					}}
				>
					<MenuItem value={0} sx={{ fontSize: 14 }}>
						0
					</MenuItem>
					<MenuItem value={1} sx={{ fontSize: 14 }}>
						1
					</MenuItem>
					<MenuItem value={2} sx={{ fontSize: 14 }}>
						2
					</MenuItem>
					<MenuItem value={3} sx={{ fontSize: 14 }}>
						3
					</MenuItem>
					<MenuItem value={4} sx={{ fontSize: 14 }}>
						4
					</MenuItem>
					<MenuItem value={5} sx={{ fontSize: 14 }}>
						5
					</MenuItem>
				</Select>
			</FormControl>
			<Button
				style={{ padding: 10, minWidth: 0, marginLeft: 2 }}
				onClick={() => {
					deleteQuestionAnswer(answerIndex)
				}}
			>
				<CloseIcon style={{ color: 'rgb(40, 40, 40, 0.9)' }} />
			</Button>
		</Stack>
	)
}
