import { Typography, TextField, Divider } from '@mui/material'

import { updateQuestionText, findQuestionText } from '../Functions'

import Keywords from '../ScoringTags/Keywords'
import ParagraphBreaks from '../ScoringTags/ParagraphBreaks'

const styles = {
	container: {
		display: 'flex',
		height: '100%',
		width: '100%',
		alignItems: 'center',
		flexDirection: 'column',
		paddingTop: 30,
		boxSizing: 'border-box',
	},
}

function AboutYourselfPage(props) {
	return (
		<div style={styles.container}>
			<div style={{ width: 600, display: 'flex', alignItems: 'start', flexDirection: 'column' }}>
				<Typography textAlign='left'>
					This is the first question a candidate will see on the test. It is meant to prompt the candidate to provide a general
					overview of who they are, while allowing us to assess their written communication skills and attention to detail. You
					can add keywords that should be of note in the candidate's overview of their experience and career goals. Additional
					points will be awarded for use of these terms.
				</Typography>
				<Divider sx={{ margin: '30px 0', width: '100%' }} />
				<TextField
					variant='outlined'
					inputProps={{ style: { fontSize: 14 } }}
					InputLabelProps={{ style: { fontSize: 14 } }}
					label='Question Text'
					multiline
					style={{ width: 600, marginBottom: 10 }}
					defaultValue={findQuestionText('AboutYourself', props.testInfo.questions)}
					onBlur={(e) => updateQuestionText('AboutYourself', e.target.value, props.testInfo, props.setTestInfo)}
				/>

				<ParagraphBreaks testInfo={props.testInfo} setTestInfo={props.setTestInfo} questionID='AboutYourself' />
				<Keywords testInfo={props.testInfo} setTestInfo={props.setTestInfo} questionID='AboutYourself' />
			</div>
		</div>
	)
}

export default AboutYourselfPage
