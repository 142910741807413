import React, { useEffect, useState, useContext } from 'react'
import styles from './Overview.module.css'
import PinOutlinedIcon from '@mui/icons-material/PinOutlined'
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined'
import PeopleIcon from '@mui/icons-material/People'
import { TestContext } from '../TestContext'
import { useSelector } from 'react-redux'

import { getTestandCSAverageTime, getTestHighestAndAverageScore } from '../../../lib/test'
import { countCandidatesInDayWeekMonth } from '../../../lib/date-time'
import { Card } from '@mui/material'

const iconStyle = {
	height: 30,
	width: 30,
	color: '#001940',
}

function ScoreTimeContainer() {
	const { test, candidateList, maxTagScores } = useContext(TestContext)

	const scoringTags = useSelector((state) => state.scoringTags)
	const scoringTagResponsesByUserID = useSelector((state) => state.scoringTagResponsesByUserID)

	const [scoreData, setScoreData] = useState({})
	const [timeData, setTimeData] = useState({})
	const [appNumbers, setAppNumbers] = useState({})

	useEffect(() => {
		setScoreData(
			getTestHighestAndAverageScore(
				candidateList,
				scoringTags,
				scoringTagResponsesByUserID,
				maxTagScores,
				JSON.parse(test.customScoringWeights)
			)
		)

		setTimeData(getTestandCSAverageTime(candidateList))
		setAppNumbers(countCandidatesInDayWeekMonth(candidateList))

		// eslint-disable-next-line
	}, [test, candidateList, maxTagScores])

	return (
		<Card className={styles['container']} style={{ minWidth: 240 }}>
			<div>
				<div className={styles['label']} style={{ marginBottom: 20 }}>
					<PinOutlinedIcon style={iconStyle} />
					<div>Scores</div>
				</div>
				<div className={styles['metric-grid']}>
					<div className={styles['metric-label']}>Highest</div>
					<span className={styles['metric-amount']}>{Math.round((scoreData.highestScore / test.totalScore) * 100)}%</span>

					<div className={styles['metric-label']}>Average</div>
					<span className={styles['metric-amount']}>{Math.round((scoreData.avgScore / test.totalScore) * 100)}%</span>
				</div>
			</div>

			<div>
				<div className={styles['label']} style={{ marginTop: 30, marginBottom: 20 }}>
					<AccessTimeOutlinedIcon style={iconStyle} />
					<div>Average Times</div>
				</div>
				<div className={styles['metric-grid']}>
					<div className={styles['metric-label']}>Test</div>
					<span className={styles['metric-amount']}>{timeData.avgTest}</span>

					<div className={styles['metric-label']}>Case Study</div>
					<span className={styles['metric-amount']}>{timeData.avgCS}</span>
				</div>
			</div>

			<div className={styles['application-numbers-row']} style={{ marginTop: 30 }}>
				<PeopleIcon style={iconStyle} />
				<div className={styles['application-numbers']}>
					<span className={styles['metric-label']}>Month</span>
					<span className={styles['metric-amount']}>{appNumbers.month}</span>
				</div>
				<div className={styles['application-numbers']}>
					<span className={styles['metric-label']}>Week</span>
					<span className={styles['metric-amount']}>{appNumbers.week}</span>
				</div>
				<div className={styles['application-numbers']}>
					<span className={styles['metric-label']}>Today</span>
					<span className={styles['metric-amount']}>{appNumbers.today}</span>
				</div>
			</div>
		</Card>
	)
}

export default ScoreTimeContainer
