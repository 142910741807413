/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getScoringTag = /* GraphQL */ `
  query GetScoringTag($id: ID!) {
    getScoringTag(id: $id) {
      id
      questionID
      tag
      data
      dataMapping
      maxScore
      createdAt
      updatedAt
    }
  }
`;
export const listScoringTags = /* GraphQL */ `
  query ListScoringTags(
    $filter: ModelScoringTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listScoringTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        questionID
        tag
        data
        dataMapping
        maxScore
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const tagsByQuestion = /* GraphQL */ `
  query TagsByQuestion(
    $questionID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelScoringTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tagsByQuestion(
      questionID: $questionID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        questionID
        tag
        data
        dataMapping
        maxScore
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getScoringTagResponse = /* GraphQL */ `
  query GetScoringTagResponse($id: ID!) {
    getScoringTagResponse(id: $id) {
      id
      scoringTagID
      userID
      score
      createdAt
      updatedAt
    }
  }
`;
export const listScoringTagResponses = /* GraphQL */ `
  query ListScoringTagResponses(
    $filter: ModelScoringTagResponseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listScoringTagResponses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        scoringTagID
        userID
        score
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const tagResponsesByTag = /* GraphQL */ `
  query TagResponsesByTag(
    $scoringTagID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelScoringTagResponseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tagResponsesByTag(
      scoringTagID: $scoringTagID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        scoringTagID
        userID
        score
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const tagResponsesByUser = /* GraphQL */ `
  query TagResponsesByUser(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelScoringTagResponseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tagResponsesByUser(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        scoringTagID
        userID
        score
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAccessCode = /* GraphQL */ `
  query GetAccessCode($id: ID!) {
    getAccessCode(id: $id) {
      id
      code
      numAllowed
      timesUsed
      testID
      status
      createdBy {
        id
        name
      }
      createdAt
      updatedAt
    }
  }
`;
export const listAccessCodes = /* GraphQL */ `
  query ListAccessCodes(
    $filter: ModelAccessCodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAccessCodes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        code
        numAllowed
        timesUsed
        testID
        status
        createdBy {
          id
          name
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const accessCodeByCodeString = /* GraphQL */ `
  query AccessCodeByCodeString(
    $code: String!
    $sortDirection: ModelSortDirection
    $filter: ModelAccessCodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    accessCodeByCodeString(
      code: $code
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        code
        numAllowed
        timesUsed
        testID
        status
        createdBy {
          id
          name
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const accessCodeByTest = /* GraphQL */ `
  query AccessCodeByTest(
    $testID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAccessCodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    accessCodeByTest(
      testID: $testID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        code
        numAllowed
        timesUsed
        testID
        status
        createdBy {
          id
          name
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getQuestionAnswer = /* GraphQL */ `
  query GetQuestionAnswer($id: ID!) {
    getQuestionAnswer(id: $id) {
      id
      questionID
      isCorrect
      answerText
      score
      createdAt
      updatedAt
    }
  }
`;
export const listQuestionAnswers = /* GraphQL */ `
  query ListQuestionAnswers(
    $filter: ModelQuestionAnswerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestionAnswers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        questionID
        isCorrect
        answerText
        score
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const questionAnswersByQuestion = /* GraphQL */ `
  query QuestionAnswersByQuestion(
    $questionID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionAnswerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    questionAnswersByQuestion(
      questionID: $questionID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        questionID
        isCorrect
        answerText
        score
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserResponse = /* GraphQL */ `
  query GetUserResponse($id: ID!) {
    getUserResponse(id: $id) {
      id
      response
      userID
      notes
      questionID
      score
      timeTaken
      createdAt
      updatedAt
    }
  }
`;
export const listUserResponses = /* GraphQL */ `
  query ListUserResponses(
    $filter: ModelUserResponseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserResponses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        response
        userID
        notes
        questionID
        score
        timeTaken
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const responsesByUser = /* GraphQL */ `
  query ResponsesByUser(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserResponseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    responsesByUser(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        response
        userID
        notes
        questionID
        score
        timeTaken
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const responsesByQuestion = /* GraphQL */ `
  query ResponsesByQuestion(
    $questionID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserResponseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    responsesByQuestion(
      questionID: $questionID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        response
        userID
        notes
        questionID
        score
        timeTaken
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getQuestion = /* GraphQL */ `
  query GetQuestion($id: ID!) {
    getQuestion(id: $id) {
      id
      format
      questionText
      section
      hasNotes
      testID
      helperText
      maxScore
      order
      referenceID
      createdAt
      updatedAt
    }
  }
`;
export const listQuestions = /* GraphQL */ `
  query ListQuestions(
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        format
        questionText
        section
        hasNotes
        testID
        helperText
        maxScore
        order
        referenceID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const questionsByTest = /* GraphQL */ `
  query QuestionsByTest(
    $testID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    questionsByTest(
      testID: $testID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        format
        questionText
        section
        hasNotes
        testID
        helperText
        maxScore
        order
        referenceID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      phone
      email
      firstName
      lastName
      city
      province
      postalCode
      score
      address
      caseStudyTime
      testTime
      testID
      status
      shortlisted
      codeUsed
      clientComments {
        createdBy {
          id
          name
        }
        comment
        date
      }
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        phone
        email
        firstName
        lastName
        city
        province
        postalCode
        score
        address
        caseStudyTime
        testTime
        testID
        status
        shortlisted
        codeUsed
        clientComments {
          createdBy {
            id
            name
          }
          comment
          date
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const usersByTest = /* GraphQL */ `
  query UsersByTest(
    $testID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByTest(
      testID: $testID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        phone
        email
        firstName
        lastName
        city
        province
        postalCode
        score
        address
        caseStudyTime
        testTime
        testID
        status
        shortlisted
        codeUsed
        clientComments {
          createdBy {
            id
            name
          }
          comment
          date
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTest = /* GraphQL */ `
  query GetTest($id: ID!) {
    getTest(id: $id) {
      id
      testName
      logoPath
      casestudyFiles
      dataMappingTags
      clientID
      totalScore
      customScoringWeights
      selfIdentificationQuestions {
        id
        type
        questionText
        questionAnswers
      }
      selfIdentificationResponses {
        items {
          id
          testID
          responses {
            questionID
            responses
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listTests = /* GraphQL */ `
  query ListTests(
    $filter: ModelTestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTests(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        testName
        logoPath
        casestudyFiles
        dataMappingTags
        clientID
        totalScore
        customScoringWeights
        selfIdentificationQuestions {
          id
          type
          questionText
          questionAnswers
        }
        selfIdentificationResponses {
          items {
            id
            testID
            responses {
              questionID
              responses
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const testsByClient = /* GraphQL */ `
  query TestsByClient(
    $clientID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelTestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    testsByClient(
      clientID: $clientID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        testName
        logoPath
        casestudyFiles
        dataMappingTags
        clientID
        totalScore
        customScoringWeights
        selfIdentificationQuestions {
          id
          type
          questionText
          questionAnswers
        }
        selfIdentificationResponses {
          items {
            id
            testID
            responses {
              questionID
              responses
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getClient = /* GraphQL */ `
  query GetClient($id: ID!) {
    getClient(id: $id) {
      id
      name
      role
      userList
      testList
      clientList
      codeList
      remainingCodeUses
      createdAt
      updatedAt
    }
  }
`;
export const listClients = /* GraphQL */ `
  query ListClients(
    $filter: ModelClientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClients(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        role
        userList
        testList
        clientList
        codeList
        remainingCodeUses
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getErrorLog = /* GraphQL */ `
  query GetErrorLog($id: ID!) {
    getErrorLog(id: $id) {
      id
      userID
      testID
      table
      message
      createdAt
      updatedAt
    }
  }
`;
export const listErrorLogs = /* GraphQL */ `
  query ListErrorLogs(
    $filter: ModelErrorLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listErrorLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        testID
        table
        message
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSelfIdentificationResponse = /* GraphQL */ `
  query GetSelfIdentificationResponse($id: ID!) {
    getSelfIdentificationResponse(id: $id) {
      id
      testID
      responses {
        questionID
        responses
      }
      createdAt
      updatedAt
    }
  }
`;
export const listSelfIdentificationResponses = /* GraphQL */ `
  query ListSelfIdentificationResponses(
    $filter: ModelSelfIdentificationResponseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSelfIdentificationResponses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        testID
        responses {
          questionID
          responses
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUnfinishedAttempt = /* GraphQL */ `
  query GetUnfinishedAttempt($id: ID!) {
    getUnfinishedAttempt(id: $id) {
      id
      testID
      score
      responses {
        response
        notes
        questionID
        score
        timeTaken {
          startTime
          endTime
        }
        submitted
      }
      scoringTagResponses {
        scoringTagID
        score
      }
      lastPage
      testTimes {
        startTime
        endTime
      }
      caseStudyTimes {
        startTime
        endTime
      }
      hasRecording
      createdAt
      updatedAt
    }
  }
`;
export const listUnfinishedAttempts = /* GraphQL */ `
  query ListUnfinishedAttempts(
    $filter: ModelUnfinishedAttemptFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUnfinishedAttempts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        testID
        score
        responses {
          response
          notes
          questionID
          score
          timeTaken {
            startTime
            endTime
          }
          submitted
        }
        scoringTagResponses {
          scoringTagID
          score
        }
        lastPage
        testTimes {
          startTime
          endTime
        }
        caseStudyTimes {
          startTime
          endTime
        }
        hasRecording
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
