import React, { useEffect, useState, useRef, createRef, useContext } from 'react'
import { Typography } from '@mui/material'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { useSelector } from 'react-redux'
import { getDetailedSectionName } from '../../../../lib/utility'
import { createSortedResponses } from '../../../../lib/candidates'

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import { CandidatesContext } from '../CandidatesContext'
import { TestContext } from '../../TestContext'
import BannerMenuButton from '../../../MenuButton/BannerMenuButton'

function ResponsesView() {
	const { candidate, setView } = useContext(CandidatesContext)
	const { test } = useContext(TestContext)

	const [tables, setTables] = useState([])
	const questionLists = useSelector((state) => state.questionLists)
	const allUserResponses = useSelector((state) => state.allUserResponses)
	const [locations, setLocations] = useState([]) // Scroll locations of the start of each section
	const [currIndex, setCurrIndex] = useState(0)
	const scrollRef = useRef()

	useEffect(() => {
		setTables(createSortedResponses(allUserResponses[candidate.userID], questionLists, test.id))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	// Finds the location of the beginning of each section
	useEffect(() => {
		let loadingLocs = []
		// eslint-disable-next-line no-unused-vars
		for (const key in tables) {
			loadingLocs.push(createRef())
		}
		setLocations(loadingLocs)
	}, [tables])

	function handleScroll(event) {
		let newIndex = 0

		// Finds what section the user is currently scrolled at
		for (const loc of locations) {
			if (event.target.scrollTop >= loc.current.offsetTop + loc.current.offsetHeight - locations[0].current.offsetTop - 100) {
				// 100 for more buffer
				newIndex++
			}
		}

		// Updates the navigation bar to show what section is currently being viewed
		setCurrIndex(newIndex)
	}

	// Scrolls to newLocation
	function changeScroll(newLocation) {
		scrollRef.current.scrollTop = newLocation
	}

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				height: '100%',
				width: '100%',
				borderRadius: '10px',
				overflow: 'hidden',
				gap: 20,
			}}
		>
			<Paper
				style={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					overflow: 'auto',
					background: 'white',
					width: '98.5%',
					marginRight: 'auto',
					padding: '15px 20px 15px 0',
					gap: 40,
					boxSizing: 'border-box',
					overflowY: 'hidden',
				}}
			>
				<BannerMenuButton
					style={{ color: 'black', margin: '0 auto 0 10px', fontWeight: 'var(--bold)' }}
					onClick={() => setView('candidate')}
				>
					<ChevronLeftIcon style={{ height: 25, width: 25 }} /> Back
				</BannerMenuButton>
				{Object.keys(tables).map((key, index) => {
					let buttonStyle = { textTransform: 'none', fontSize: 16, color: 'black', opacity: 0.8, fontWeight: 'var(--bold)' }
					if (index === currIndex) buttonStyle.borderBottom = '3px solid #001940'
					return (
						<BannerMenuButton
							key={key}
							style={buttonStyle}
							onClick={() => {
								changeScroll(locations[index].current.offsetTop - locations[0].current.offsetTop)
							}}
						>
							{getDetailedSectionName(key)}
						</BannerMenuButton>
					)
				})}
			</Paper>

			<div style={{ height: '100%', width: '99%', overflow: 'auto', paddingRight: '1%' }} onScroll={handleScroll} ref={scrollRef}>
				{Object.keys(tables).map((key, index) => {
					return (
						<Paper
							style={{ width: '100%', marginBottom: 50, background: 'white', overflow: 'hidden' }}
							key={key}
							ref={locations[index]}
						>
							<TableContainer component={Paper}>
								<Table aria-label='simple table'>
									<TableHead>
										<TableRow>
											<TableCell align='left'>
												<span style={{ fontWeight: 'var(--bold)' }}>Question</span>
											</TableCell>
											<TableCell align='left'>
												<span style={{ fontWeight: 'var(--bold)' }}>Response</span>
											</TableCell>
											<TableCell align='center'>
												<span style={{ fontWeight: 'var(--bold)' }}>Autoscore</span>
											</TableCell>
											{(key === 'CaseStudy' || key === 'LastQuestion') && (
												<TableCell align='center'>
													<span style={{ fontWeight: 'var(--bold)' }}>Time</span>
												</TableCell>
											)}
										</TableRow>
									</TableHead>
									<TableBody>
										{tables[key].map((row, index) => {
											return (
												<TableRow key={index}>
													<TableCell align='left' style={{ verticalAlign: 'top', width: '30%' }}>
														{row.question}
													</TableCell>
													<TableCell align='left' style={{ verticalAlign: 'top', width: '40%' }}>
														<Typography style={{ fontSize: 14, whiteSpace: 'pre-line' }}>
															{row.response}
														</Typography>
														{row.notes && row.notes.length > 0 && (
															<Typography style={{ fontSize: 14, whiteSpace: 'pre-line' }}>
																<br />
																Notes: <br />
																<br /> {row.notes}
															</Typography>
														)}
													</TableCell>
													<TableCell align='center' style={{ verticalAlign: 'top' }}>
														{' '}
														{row.score} / {row.maxScore}{' '}
													</TableCell>
													{key === 'CaseStudy' && (
														<TableCell align='center' style={{ verticalAlign: 'top' }}>
															{' '}
															{row.timeString}{' '}
														</TableCell>
													)}
													{key === 'LastQuestion' && (
														<TableCell align='center' style={{ verticalAlign: 'top', height: '350px' }}>
															{' '}
															{row.timeString}{' '}
														</TableCell>
													)}
												</TableRow>
											)
										})}
									</TableBody>
								</Table>
							</TableContainer>
						</Paper>
					)
				})}
			</div>
		</div>
	)
}

export default ResponsesView
