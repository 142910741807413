import React, { useContext, useEffect } from 'react'
import { CandidatesContext } from '../CandidatesContext'
import CaseStudyGraph from './CaseStudyGraph'
import Comments from './Comments/Comments'
import Info from './Info'
import Menu from './Menu'
import Nav from './Nav'
import RadarGraph from './RadarGraph'

function Breakdown() {
	const { candidate, setCandidate, origRows } = useContext(CandidatesContext)

	// Gets the updated candidate
	useEffect(() => {
		for (const iterCandidate of origRows) {
			if (candidate.userID === iterCandidate.userID) {
				setCandidate({ ...iterCandidate })
				return
			}
		}

		// eslint-disable-next-line
	}, [origRows])

	return (
		<div
			style={{
				display: 'flex',
				width: '99%',
				flexDirection: 'column',
				alignItems: 'flex-start',
				gap: 20,
				paddingBottom: 20,
				boxSizing: 'border-box',
			}}
		>
			<Nav />
			<Menu />
			<Info />
			<div style={{ display: 'flex', width: '100%', alignItems: 'flex-start', justifyContent: 'space-between' }}>
				<CaseStudyGraph />
				<RadarGraph />
			</div>
			<Comments />
		</div>
	)
}

export default Breakdown
