import React, { useEffect } from 'react'
import { TextField } from '@mui/material'

function TextInput({ condition, newFilterValue, setNewFilterValue }) {
	useEffect(() => {
		setNewFilterValue('')

		// eslint-disable-next-line
	}, [])

	return (
		<TextField
			style={{ marginLeft: 10, width: 150 }}
			InputProps={{
				style: {
					fontSize: 14,
				},
			}}
			inputProps={{ maxLength: 30 }}
			helperText={
				<span
					style={{
						fontWeight: 'normal',
						fontSize: 14,
						color: 'rgb(40, 40, 40, 0.8)',
						marginBottom: 10,
					}}
				>
					{condition.typeLabel}
				</span>
			}
			value={newFilterValue}
			onChange={(e) => {
				setNewFilterValue(e.target.value)
			}}
		/>
	)
}

export default TextInput
