import React, { useEffect, useState } from 'react'
import styles from './Dashboard.module.css'
import CandidatesTab from './CandidatesTab'
import TestsTab from './TestsTab'
import CodesTab from './CodesTab'
import CandidateNumbersGraph from './CandidateNumbersGraph'
import TestTable from './TestTable'
import { useSelector } from 'react-redux'

function Dashboard() {
	const applicantLists = useSelector((state) => state.applicantLists)
	const [candidates, setCandidates] = useState([])

	useEffect(() => {
		const newCandidatesList = []

		for (const key in applicantLists) {
			for (const candidate of applicantLists[key]) {
				newCandidatesList.push(candidate)
			}
		}
		setCandidates(newCandidatesList)
	}, [applicantLists])

	return (
		<div className={styles.container}>
			<div className={styles['dashboard-items-column']}>
				<CandidatesTab />
				<TestsTab />
				<CodesTab />
			</div>
			<div className={styles['graph-table-container']}>
				<div style={{ height: '45%', width: '100%' }}>
					<CandidateNumbersGraph candidates={candidates} isDashboard />
				</div>
				<TestTable />
			</div>
		</div>
	)
}

export default Dashboard
