import React, { useContext, useEffect, useState } from 'react'
import styles from '../Candidates.module.css'
import { Radar, RadarChart, PolarGrid, PolarAngleAxis, ResponsiveContainer, PolarRadiusAxis } from 'recharts'
import { TestContext } from '../../TestContext'
import { convertDataMappingName } from '../../../../functions/CalculationFunctions'
import { useSelector } from 'react-redux'
import { CandidatesContext } from '../CandidatesContext'
import { getCandidateTagTotals } from '../../../../lib/candidates'
import { Card } from '@mui/material'

// const data = [
// 	{ dataMapping: 'Position Requirements', score: 56.36363636363636 },
// 	{ dataMapping: 'Communication', score: 75 },
// 	{ dataMapping: 'Attention To Detail', score: 38.46153846153847 },
// 	{ dataMapping: 'RE Knowledge', score: 60 },
// ]

const sortOrder = ['Position Requirements', 'Communication', 'Attention To Detail', 'RE Knowledge']

function createData(maxTags, userTagTotals) {
	let data = []

	for (const dataMapping in maxTags) {
		let score

		if (maxTags[dataMapping] === 0) {
			if (userTagTotals[dataMapping] >= 0) {
				score = 100
			} else {
				score = 0
			}
		} else {
			score = (userTagTotals[dataMapping] / maxTags[dataMapping]) * 100
		}

		if (isNaN(score)) {
			if (userTagTotals[dataMapping] === maxTags[dataMapping]) {
				score = 100
			} else {
				score = 0
			}
		}

		const dataPoint = {
			dataMapping: convertDataMappingName(dataMapping),
			score: score,
		}
		data.push(dataPoint)
	}

	data.sort((a, b) => {
		for (const label of sortOrder) {
			if (a.dataMapping === label) return -1
			else if (b.dataMapping === label) return 1
		}

		return 1
	})

	return data
}

function RadarGraph({ usePDFVersion }) {
	const { maxTagScores } = useContext(TestContext)
	const { candidate } = useContext(CandidatesContext)

	const tagResponsesByID = useSelector((state) => state.scoringTagResponsesByUserID)
	const scoringTags = useSelector((state) => state.scoringTags)

	const [data, setData] = useState([])
	const [fontSize, setFontSize] = useState(14)

	useEffect(() => {
		setData(createData(maxTagScores, getCandidateTagTotals(tagResponsesByID[candidate.userID], scoringTags)))
		// eslint-disable-next-line
	}, [candidate, maxTagScores])

	useEffect(() => {
		const handleResize = () => {
			if (window.innerWidth < 1030) {
				setFontSize(10)
			} else if (window.innerWidth < 1130) {
				setFontSize(12)
			} else {
				setFontSize(14)
			}
		}

		window.addEventListener('resize', handleResize)

		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	return (
		<Card
			className={`${styles['graph-container']} ${usePDFVersion ? 'pdf-item' : ''}`}
			style={usePDFVersion ? { width: 'calc(80% - 80px)', boxShadow: 'none' } : {}}
		>
			<span className={styles['container-label']}> Skills Breakdown </span>
			<ResponsiveContainer height={250} width='100%'>
				<RadarChart cx='50%' cy='50%' outerRadius='80%' data={data}>
					<PolarGrid />
					<PolarAngleAxis dataKey='dataMapping' id='Radar-Graph-Axis' fontSize={fontSize} />
					<PolarRadiusAxis axisLine={false} tick={false} domain={[0, 100]} />
					<Radar dataKey='score' stroke='#8884d8' fill='#32a846' fillOpacity={0.8} animationDuration={500} />
				</RadarChart>
			</ResponsiveContainer>
		</Card>
	)
}

export default RadarGraph
