import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { parseAWSDateTimeWithYear } from '../../../functions/CalculationFunctions'
import Table from '../../Table/Table'
import ClearIcon from '@mui/icons-material/Clear'
import BannerMenuButton from '../../MenuButton/BannerMenuButton'
import { deleteDBErrorLog } from '../../../lib/database'

const columns = [
	{ label: 'Table' },
	{ label: 'User ID' },
	{ label: 'Test ID' },
	{ label: 'Error Message' },
	{ label: 'Date' },
	{ label: '' },
]

function getShortTableName(table) {
	switch (table) {
		case 'UserResponse':
			return 'UR'
		case 'TimedUserResponse':
			return 'TUR'
		case 'ScoringTagResponse':
			return 'STR'
		default:
			return table
	}
}

function ErrorLogs() {
	const errorLogs = useSelector((state) => state.errorLogs)

	const [rows, setRows] = useState([])

	const dispatch = useDispatch()

	useEffect(() => {
		setRows([...errorLogs])
	}, [errorLogs])

	function formatRow(row) {
		const { id, table, message, userID, testID, createdAt } = row

		return (
			<tr key={id}>
				<td>{getShortTableName(table)}</td>
				<td style={{ padding: '0 20px', fontSize: 14 }}>{userID}</td>
				<td style={{ padding: '0 20px', fontSize: 14 }}>{testID}</td>
				<td style={{ textAlign: 'left', padding: '0 20px', color: 'var(--error-red)' }}>{message}</td>
				<td style={{ padding: '0 20px', width: 100 }}>{parseAWSDateTimeWithYear(createdAt)}</td>
				<td>
					<BannerMenuButton
						style={{ color: 'var(--faded-black)' }}
						onClick={() => {
							deleteDBErrorLog(id, dispatch)
						}}
					>
						<ClearIcon style={{ height: 20, width: 20 }} />
					</BannerMenuButton>
				</td>
			</tr>
		)
	}

	return (
		<div
			style={{
				display: 'flex',
				alignItems: 'start',
				flexDirection: 'column',
				boxSizing: 'border-box',
				padding: '1% 3%',
				width: '100%',
				height: '100%',
			}}
		>
			<h2>Error Logs</h2>
			<p className='section-description'>
				This section shows any error messages that a user has encountered when attempting to upload a response.{' '}
			</p>
			<Table rows={rows} setRows={setRows} columns={columns} origRows={rows} formatRow={formatRow} />
		</div>
	)
}

export default ErrorLogs
