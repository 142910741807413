import { Button, Box, Divider, TextField, Typography, Paper } from '@mui/material'
import { API, Storage } from 'aws-amplify'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createQuestion, createQuestionAnswer, createScoringTag } from '../../../../graphql/mutations'
import { customCreateTest } from '../../../../graphql/CustomMutations'
import { caseStudies } from '../CaseStudies'
import { changeQuestionAnswersLists, changeQuestionLists, changeScoringTags, changeTestList } from '../../../../actions'

import { CircularProgress } from '@mui/material'

function convertScoringWeightsToJSON(scoringWeights) {
	const weightsJSON = {}

	for (const weight of scoringWeights) {
		weightsJSON[weight.label] = weight.value / 100
	}

	// console.log(JSON.stringify(weightsJSON))
	return JSON.stringify(weightsJSON)
}

function SubmitPage(props) {
	const [testName, setTestName] = useState(props.testInfo.testName)
	const [loading, setLoading] = useState(false)
	const [result, setResult] = useState('')
	const client = useSelector((state) => state.client)

	// For updating store with results
	const testList = useSelector((state) => state.testList)
	const questionLists = useSelector((state) => state.questionLists)
	const scoringTags = useSelector((state) => state.scoringTags)
	const questionAnswers = useSelector((state) => state.questionAnswers)
	const dispatch = useDispatch()

	function getCaseStudyFiles(name) {
		for (const caseStudy of caseStudies) {
			if (caseStudy.name === name) {
				return caseStudy.files
			}
		}
	}

	function sumScore(questions) {
		let totalScore = 0

		for (const question of questions) {
			// console.log(question)
			if (question.maxScore) totalScore += question.maxScore
		}

		return totalScore + 1 // +1 is for the info button being clicked
	}

	function checkAllQuestionsForEmpty(questions) {
		for (const question of questions) {
			if (!question.questionText || question.questionText.length === 0) {
				if (question.section !== 'PrivacyPolicy') return false
			}
		}

		return true
	}

	async function submitTest() {
		let createdTest = null
		const createdQuestions = []
		const createdQuestionAnswers = []
		const createdScoringTags = []

		setLoading(true)

		if (testName.length === 0) {
			setResult('ERROR: Test name cannot be empty. Please hit the back button and then return to this page to enter a test name.')
			setLoading(false)
			return false
		}

		if (!checkAllQuestionsForEmpty(props.testInfo.questions)) {
			setResult('ERROR: A question has empty question text. Please check your customized questions to see if you left a field empty.')
			setLoading(false)
			return false
		}

		// Upload image if necessary
		if (props.testInfo.file) {
			setResult('Uploading logo to storage... ')

			if (props.testInfo.logoPath.length === 0) {
				props.testInfo.logoPath = 'ClientLogos/' + testName + 'Logo' + new Date().getTime()

				if (props.testInfo.fileType.includes('jpeg')) props.testInfo.logoPath += '.jpg'
				else if (props.testInfo.fileType.includes('png')) props.testInfo.logoPath += '.png'
				else {
					setResult("ERROR: Couldn't verify image format. Please use jpg or png.")
					setLoading(false)
					return false
				}

				try {
					await Storage.put(props.testInfo.logoPath, props.testInfo.file, { contentType: props.testInfo.fileType })
				} catch (e) {
					console.error(e)
					setResult('ERROR: Could not upload logo to storage.')
					setLoading(false)
					return false
				}
			}
		} else {
			setResult('ERROR: A logo must be provided for the test.')
			setLoading(false)
			return false
		}

		// Create Test
		try {
			setResult('Uploading test to database...')

			const testInput = {
				testName: testName,
				logoPath: props.testInfo.logoPath,
				casestudyFiles: getCaseStudyFiles(props.testInfo.caseStudyName),
				clientID: client.id,
				totalScore: sumScore(props.testInfo.questions),
			}

			if (props.testInfo.customScoringWeights) {
				testInput.customScoringWeights = convertScoringWeightsToJSON(props.testInfo.customScoringWeights)
			}

			if (props.testInfo.includeDEISurvey) {
				testInput.selfIdentificationQuestions = props.testInfo.selfIdentificationQuestions
			}

			const createTestResult = await API.graphql({
				query: customCreateTest,
				variables: {
					input: testInput,
				},
				authMode: 'AMAZON_COGNITO_USER_POOLS',
			})
			createdTest = createTestResult.data.createTest

			console.log('Created test!')
		} catch (e) {
			console.error(e)
			setResult('ERROR: Could not upload test to database.')
			setLoading(false)
			return false
		}

		let questionIndex = 1
		// Create all non case study Questions
		for (const question of props.testInfo.questions) {
			let createQuestionResult = null

			try {
				setResult('Uploading question ' + questionIndex + ' of ' + props.testInfo.questions.length + '...')

				createQuestionResult = await API.graphql({
					query: createQuestion,
					variables: {
						input: {
							format: question.format,
							questionText: question.questionText,
							section: question.section,
							hasNotes: question.hasNotes,
							testID: createdTest.id,
							helperText: question.helperText,
							maxScore: question.maxScore,
							order: question.order,
							referenceID: question.referenceID ? question.referenceID : null,
						},
					},
					authMode: 'AMAZON_COGNITO_USER_POOLS',
				})
				createdQuestions.push(createQuestionResult.data.createQuestion)

				// console.log("Uploaded question ", questionIndex, " of ", props.testInfo.questions.length)
				questionIndex++
			} catch (e) {
				console.error(e)
				setResult('ERROR: Could not upload question to database.')
				setLoading(false)
				return false
			}

			// Upload all associated QuestionAnswers
			if (question.questionAnswers) {
				for (const questionAnswer of question.questionAnswers) {
					try {
						const createQuestionAnswerResult = await API.graphql({
							query: createQuestionAnswer,
							variables: {
								input: {
									questionID: createQuestionResult.data.createQuestion.id,
									isCorrect: false,
									answerText: questionAnswer.answerText,
									score: questionAnswer.score,
								},
							},
							authMode: 'AMAZON_COGNITO_USER_POOLS',
						})
						createdQuestionAnswers.push(createQuestionAnswerResult.data.createQuestionAnswer)
					} catch (e) {
						console.error(e)
						console.error(questionAnswer)
						setResult('ERROR: Could not upload question answer to database.')
						setLoading(false)
						return false
					}
				}
			}

			// Upload all associated ScoringTags
			if (question.scoringTags) {
				for (const scoringTag of question.scoringTags) {
					try {
						const createScoringTagResult = await API.graphql({
							query: createScoringTag,
							variables: {
								input: {
									questionID: createQuestionResult.data.createQuestion.id,
									tag: scoringTag.tag,
									data: scoringTag.data,
									dataMapping: scoringTag.dataMapping,
									maxScore: scoringTag.maxScore,
								},
							},
							authMode: 'AMAZON_COGNITO_USER_POOLS',
						})
						createdScoringTags.push(createScoringTagResult.data.createScoringTag)
					} catch (e) {
						console.error(e)
						setLoading(false)
						setResult('ERROR: Could not upload scoring tag to database.')
						return false
					}
				}
			}
		}

		setLoading(false)
		setResult('SUCCESS')

		updateStore(createdTest, createdQuestions, createdQuestionAnswers, createdScoringTags)

		return true
	}

	function updateStore(newTest, newQuestions, newQuestionAnswers, newScoringTags) {
		dispatch(changeTestList([...testList, newTest]))
		dispatch(changeScoringTags([...scoringTags, ...newScoringTags]))
		dispatch(changeQuestionLists({ ...questionLists, [newTest.id]: newQuestions }))

		const organizedQuestionAnswers = {}

		for (const questionAnswer of newQuestionAnswers) {
			if (!organizedQuestionAnswers[questionAnswer.questionID]) {
				organizedQuestionAnswers[questionAnswer.questionID] = []
			}
			organizedQuestionAnswers[questionAnswer.questionID].push(questionAnswer)
		}
		dispatch(changeQuestionAnswersLists({ ...questionAnswers, ...organizedQuestionAnswers }))
	}

	return (
		<div
			style={{
				display: 'flex',
				height: '100%',
				width: '100%',
				alignItems: 'center',
				justifyContent: 'start',
				flexDirection: 'column',
			}}
		>
			<div style={{ width: '600px', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', padding: '30px 0 4vh' }}>
				<Typography textAlign='left'>All that's left is to provide a name for your test!</Typography>
				<Divider sx={{ width: 600, margin: '30px 0' }} />
				<TextField
					value={testName}
					onChange={(e) => setTestName(e.target.value)}
					onBlur={(e) => {
						let newState = Object.assign({}, props.testInfo)
						newState.testName = e.target.value
						props.setTestInfo(newState)
					}}
					style={{ width: 350 }}
					variant='outlined'
					size='medium'
					label='Test Name'
				/>

				<Button
					style={{
						textTransform: 'none',
						background: result.length > 0 ? '#758399' : '#001940',
						color: 'white',
						height: 40,
						width: 150,
						textAlign: 'center',
						fontSize: 14,
						marginTop: 30,
						marginBottom: 30,
					}}
					disabled={loading || result.length > 0}
					onClick={() => submitTest()}
				>
					{loading ? <CircularProgress style={{ color: 'white', height: 30, width: 30 }} /> : 'Create Test'}
				</Button>

				{result.includes('SUCCESS') && <Feedback type='success' message='Your test has been successfully created.' />}
				{result.includes('ERROR') && <Feedback type='error' message={result} />}
				{!result.includes('SUCCESS') && !result.includes('ERROR') && result.length > 0 && (
					<Feedback type='inprogress' message={result} />
				)}
			</div>
		</div>
	)
}

export default SubmitPage

function Feedback({ type, message }) {
	const getBorderColor = () => {
		switch (type) {
			case 'success':
				return '#32a846'
			case 'error':
				return '#d13313'
			case 'warning':
				return '#FF5F15'
			case 'inprogress':
				return '#001940'
			default:
				return 'rgb(40, 40, 40)'
		}
	}

	return (
		<Paper elevation={3} sx={{ borderRadius: '3px', overflow: 'hidden' }}>
			<Box
				display='flex'
				flexDirection='column'
				alignItems='start'
				padding='6px 10px'
				style={{ borderLeft: `4px solid ${getBorderColor()}` }}
			>
				<Box display='flex' alignItems='center' gap={1}>
					{type === 'success' && (
						<Typography variant='subtitle1' style={{ color: '#32a846', fontWeight: 500 }}>
							Success
						</Typography>
					)}
					{type === 'error' && (
						<Typography variant='subtitle1' style={{ color: '#d13313', fontWeight: 500 }}>
							Error
						</Typography>
					)}
					{type === 'warning' && (
						<Typography variant='subtitle1' style={{ color: '#FF5F15', fontWeight: 500 }}>
							Warning
						</Typography>
					)}
					{type === 'inprogress' && (
						<Typography variant='subtitle1' style={{ color: '#001940', fontWeight: 500 }}>
							In Progress
						</Typography>
					)}
				</Box>
				<Typography style={{ color: 'rgb(60, 60, 60)', fontSize: 14, maxWidth: 300, textAlign: 'left' }}>
					{message.replace('ERROR: ', '')}
				</Typography>
			</Box>
		</Paper>
	)
}
