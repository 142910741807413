import { Typography, Button, Divider, Paper, Box, MobileStepper } from '@mui/material'
import { useState } from 'react'
import { useSelector } from 'react-redux'

import LeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import RightIcon from '@mui/icons-material/KeyboardArrowRight'

import './TestBuilder.css'
import FileUploadPage from './Pages/FileUploadPage'
import JobDescriptionPage from './Pages/JobDescriptionsPage'
import AboutYourselfPage from './Pages/AboutYourselfPage'
import CaseStudyPage from './Pages/CaseStudyPage'
import LastQuestionPage from './Pages/LastQuestionPage'
import ScoringWeightsPage from './Pages/ScoringWeightsPage'
import SubmitPage from './Pages/SubmitPage'
import YourExperience from './Pages/YourExperiencePage'

import { caseStudies } from './CaseStudies'
import { baseQuestions } from './BaseQuestions'
import DEIPage from './Pages/DEI/DEIPage'
import KnowledgeAssessmentPage from './Pages/KnowledgeAssessmentPage'

const getBaseTestInfo = (client) => {
	return {
		caseStudyName: caseStudies[0].name,
		imageURL: null,
		file: null,
		fileType: null,
		logoPath: '',
		testName: '',
		totalScore: 0,
		clientID: client.id,
		questions: [...baseQuestions, ...caseStudies[0].questions],
		includeDEISurvey: false,
		selfIdentificationQuestions: [],
		customScoringWeights: null,
	}
}

function TestBuilder() {
	const client = useSelector((state) => state.client)

	const [index, setIndex] = useState(1)
	const [started, setStarted] = useState(false)
	const [testInfo, setTestInfo] = useState(getBaseTestInfo(client))

	const pageNames = [
		'Choose Logo',
		'Job Descriptions',
		'About Yourself',
		'Your Experience',
		'Knowledge Assessment',
		'Case Study',
		'Last Question',
		'DEI',
		'Scoring Weights',
		'Submit',
	]
	const maxIndex = pageNames.length

	if (!started) {
		return (
			<Box height='100%' width='100%' alignItems='center' justifyContent='center' display='flex'>
				<Paper style={{ padding: '20px 40px', display: 'flex', alignItems: 'start', flexDirection: 'column' }} elevation={5}>
					<Typography fontSize='18px' fontWeight='600' mb={1}>
						Welcome to the Test Builder
					</Typography>
					<Typography fontSize='16px' textAlign='left' maxWidth='500px'>
						Create a test with your own custom questions and logo. Choose from one of our specialized case studies. Decide how
						much focus you want to be on real estate knowledge or attention to detail.
					</Typography>
					<Button
						variant='text'
						sx={{
							color: '#001940',
							textTransform: 'none',
							justifySelf: 'start',
							paddingLeft: 0,
							fontWeight: 'bold',
							fontSize: 16,
							marginTop: 3,
						}}
						onClick={() => {
							setStarted(true)
						}}
					>
						Click here to get started
					</Button>
				</Paper>
			</Box>
		)
	}

	return (
		<div style={{ height: '100%', width: '100%', background: 'white', display: 'flex', flexDirection: 'column' }}>
			<Box
				sx={{
					width: '96%',
					color: '#001940',
					margin: '5px auto',
					display: 'flex',
					justifyContent: 'space-between',
					marginTop: 1,
					alignItems: 'center',
				}}
			>
				<span style={{ fontWeight: 'bold' }}>{pageNames[index - 1]}</span>
				<TestBuilderNavigation index={index} setIndex={setIndex} maxIndex={maxIndex} />
				<span style={{ color: 'white', fontWeight: 'bold' }}>{pageNames[index - 1]}</span>
			</Box>
			<Divider />
			<div className='Container'>
				{index === 1 && <FileUploadPage testInfo={testInfo} setTestInfo={setTestInfo} />}
				{index === 2 && <JobDescriptionPage testInfo={testInfo} setTestInfo={setTestInfo} />}
				{index === 3 && <AboutYourselfPage testInfo={testInfo} setTestInfo={setTestInfo} />}
				{index === 4 && <YourExperience testInfo={testInfo} setTestInfo={setTestInfo} />}
				{index === 5 && <KnowledgeAssessmentPage testInfo={testInfo} setTestInfo={setTestInfo} />}
				{index === 6 && <CaseStudyPage testInfo={testInfo} setTestInfo={setTestInfo} />}
				{index === 7 && <LastQuestionPage testInfo={testInfo} setTestInfo={setTestInfo} />}
				{index === 8 && <DEIPage testInfo={testInfo} setTestInfo={setTestInfo} />}
				{index === 9 && <ScoringWeightsPage testInfo={testInfo} setTestInfo={setTestInfo} />}
				{index === 10 && <SubmitPage testInfo={testInfo} setTestInfo={setTestInfo} />}
			</div>
		</div>
	)
}

export default TestBuilder

function TestBuilderNavigation({ index, setIndex, maxIndex }) {
	const handleNext = () => {
		setIndex((prevIndex) => Math.min(prevIndex + 1, maxIndex))
	}

	const handleBack = () => {
		setIndex((prevIndex) => Math.max(prevIndex - 1, 1))
	}

	return (
		<MobileStepper
			variant='dots'
			steps={maxIndex}
			position='static'
			activeStep={index - 1}
			sx={{
				maxWidth: 400,
				'& .MuiMobileStepper-dotActive': { backgroundColor: '#001940' },
			}}
			nextButton={
				<Button size='small' onClick={handleNext} disabled={index === maxIndex} style={{ color: '#001940', marginLeft: 10 }}>
					Next
					<RightIcon style={{ color: '#001940' }} />
				</Button>
			}
			backButton={
				<Button size='small' onClick={handleBack} disabled={index === 1} style={{ color: '#001940', marginRight: 10 }}>
					<LeftIcon style={{ color: '#001940' }} />
					Back
				</Button>
			}
		/>
	)
}
