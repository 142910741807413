import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'

import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { Card } from '@mui/material'

function TestTable() {
	const testList = useSelector((state) => state.testList)
	const codeList = useSelector((state) => state.accessCodes)
	const applicantLists = useSelector((state) => state.applicantLists)

	const [testTotals, setTestTotals] = useState([])

	useEffect(() => {
		const newTestTotals = []

		for (const key in applicantLists) {
			const currTestTotal = {
				testName: '',
				numCodes: 0,
				total: 0,
				monthly: 0,
				weekly: 0,
				shortlisted: 0,
			}

			for (const test of testList) {
				if (test.id === key) currTestTotal.testName = test.testName
			}

			// Couldn't find the test, don't count any applicants
			if (currTestTotal.testName === '') continue

			// Counts number of access codes for this test
			for (const codeKey in codeList) {
				if (codeList[codeKey].testID === key) currTestTotal.numCodes++
			}

			// Counts total, monthly, weekly, shortlisted applicants in total and for this test
			for (const applicant of applicantLists[key]) {
				currTestTotal.total++

				if (applicant.shortlisted) {
					currTestTotal.shortlisted++
				}

				if (new Date(applicant.createdAt).getMonth() === new Date().getMonth()) {
					currTestTotal.monthly++
				}

				if (new Date(applicant.createdAt).getWeekNumber() === new Date().getWeekNumber()) {
					currTestTotal.weekly++
				}
			}

			newTestTotals.push(currTestTotal)
			setTestTotals(newTestTotals)
		}
		// eslint-disable-next-line
	}, [])

	return (
		<Card style={{ width: '100%', height: '45%', background: 'white', overflowY: 'auto' }}>
			<TableContainer>
				<Table aria-label='simple table'>
					<TableHead>
						<TableRow>
							<TableCell align='center'>
								<span style={{ fontWeight: 'var(--bold)' }}>Test</span>
							</TableCell>
							<TableCell align='center'>
								<span style={{ fontWeight: 'var(--bold)' }}>Codes</span>
							</TableCell>
							<TableCell align='center'>
								<span style={{ fontWeight: 'var(--bold)' }}>Total Applicants</span>
							</TableCell>
							<TableCell align='center'>
								<span style={{ fontWeight: 'var(--bold)' }}>Monthly Applicants</span>
							</TableCell>
							<TableCell align='center' width={120}>
								<span style={{ fontWeight: 'var(--bold)' }}>Weekly Applicants</span>
							</TableCell>
							<TableCell align='center' width={120}>
								<span style={{ fontWeight: 'var(--bold)' }}>Shortlisted</span>
							</TableCell>
						</TableRow>
					</TableHead>

					<TableBody>
						{testTotals.map((row, index) => {
							return (
								<TableRow key={index}>
									<TableCell align='center' style={{ padding: '10px 16px' }}>
										<span style={{ fontWeight: 'var(--bold)' }}>{row.testName}</span>
									</TableCell>
									<TableCell align='center' style={{ padding: '10px 16px' }}>
										{row.numCodes}
									</TableCell>
									<TableCell align='center' style={{ padding: '10px 16px' }}>
										{row.total}
									</TableCell>
									<TableCell align='center' style={{ padding: '10px 16px' }}>
										{row.monthly}
									</TableCell>
									<TableCell align='center' style={{ padding: '10px 16px' }}>
										{row.weekly}
									</TableCell>
									<TableCell align='center' style={{ padding: '10px 16px' }}>
										{row.shortlisted}
									</TableCell>
								</TableRow>
							)
						})}
					</TableBody>
				</Table>
			</TableContainer>
		</Card>
	)
}

export default TestTable
