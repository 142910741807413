import React, { useContext, useEffect, useState } from 'react'
import { ScatterChart, CartesianGrid, XAxis, YAxis, ZAxis, Tooltip, Scatter, ReferenceArea, ResponsiveContainer } from 'recharts'
import { getAllCandidateTableData } from '../../../lib/candidates'
import { convertToHMSTimeString, convertToHMTimeString } from '../../../lib/date-time'
import { TestContext } from '../TestContext'
import styles from './Overview.module.css'
import { Card, Paper } from '@mui/material'

// const data = [
// 	{ score: 62, time: 12704390, name: 'Hammad Zafar' },
// 	{ score: 64, time: 4806846, name: 'Ethan Dancer' },
// 	{ score: 58, time: 6291520, name: 'Philippe Michalik' },
// 	{ score: 69, time: 912098, name: 'Susan Min' },
// ]

const CustomTooltip = ({ active, payload }) => {
	if (active && payload && payload.length) {
		return (
			<Paper
				style={{
					background: 'white',
					padding: '20px 30px',
					textAlign: 'left',
					display: 'grid',
					gridTemplateColumns: '60px 1fr',
					gap: 5,
				}}
				elevation={5}
			>
				<b>Name: </b>
				<span>{`${payload[0].payload.name}`}</span>
				<b>Score: </b>
				<span className='label'>{`${payload[0].value}%`}</span>
				<b>Time: </b>
				<span className='label'>{`${convertToHMSTimeString(payload[1].value)}`}</span>
			</Paper>
		)
	}

	return null
}

/**
 * @param {[Object]} candidateList - List of candidates for the current test
 * @param {Integer} maxTime - The time in MS that the candidate must have completed the test in
 * @param {Integer} minScore - The percentage score value that the candidate must be above
 * @param {Integer} candidate.autoScore - The percentage score value for the candidate (using weighted score if present)
 * @param {Integer} candidate.testTimeMS - The time in milliseconds the candidate took to complete the test
 *
 * @returns {[Object]} An array of candidates that finished in leq the maxTime and have a score geq than minScore
 */
function getQualifiedCandidates(candidateList, minScore, maxTime) {
	const qualified = []
	for (const candidate of candidateList) {
		if (candidate.shortlisted || (candidate.autoScore >= minScore && candidate.testTimeMS <= maxTime)) {
			qualified.push({
				name: candidate.name,
				score: candidate.autoScore,
				time: candidate.testTimeMS,
				shortlisted: candidate.shortlisted,
			})
		}
	}

	return qualified
}

function splitData(candidates, wantShortlisted) {
	const newData = []

	for (const candidate of candidates) {
		if (candidate.shortlisted) {
			if (wantShortlisted) {
				newData.push(candidate)
			}
		} else {
			if (!wantShortlisted) {
				newData.push(candidate)
			}
		}
	}

	return newData
}

function QualifiedCandidatesGraph() {
	const { candidateList, test } = useContext(TestContext)

	const [data, setData] = useState([])

	useEffect(() => {
		setData(getQualifiedCandidates(getAllCandidateTableData(candidateList, test.totalScore), 65, 5400000))
	}, [candidateList, test])

	return (
		<Card className={styles['container']} style={{ height: 'calc(100% - 40px)', minWidth: 0, width: '100%' }}>
			<span className={styles['label']} style={{ marginBottom: 2 }}>
				Qualified Candidates
			</span>
			<span className={styles['container-description']}>
				Your shortlisted candidates in <span style={{ color: '#32a846' }}>stars</span>, and any other candidates that fall within
				the qualified zone in <span style={{ color: '#662d91' }}>circles</span>.
			</span>
			<ResponsiveContainer height={300}>
				<ScatterChart margin={{ top: 20, right: 20, bottom: 10, left: 10 }}>
					<CartesianGrid strokeDasharray='3 3' />
					<ReferenceArea x1={65} x2={100} y1={0} y2={5400000} fill='lightblue' />
					<XAxis
						dataKey='score'
						name='Score'
						unit='%'
						type='number'
						ticks={[40, 60, 80, 100]}
						tickLine={false}
						domain={[40, 100]}
						allowDataOverflow={true}
					/>
					<YAxis
						dataKey='time'
						name='Time'
						tickFormatter={convertToHMTimeString}
						ticks={[0, 3600000, 7200000]}
						domain={[0, 7800000]}
						allowDataOverflow={true}
						reversed
						tickLine={false}
					/>
					<ZAxis range={[200, 200]} />
					<Tooltip content={<CustomTooltip />} />
					<Scatter data={splitData(data, true)} fill='#32a846' shape='star' name='Shortlisted' />
					<Scatter data={splitData(data, false)} fill='#662d91' shape='circle' name='Qualified' />
				</ScatterChart>
			</ResponsiveContainer>
		</Card>
	)
}

export default QualifiedCandidatesGraph
