import React, { useContext } from 'react'
import { countListItemsWithObjectAttribute } from '../../lib/array'
import styles from './Test.module.css'
import { TestContext } from './TestContext'
import { Card } from '@mui/material'

const tabs = ['Overview', 'Candidates', 'Manage Access', 'Executive Report']

function Navigation() {
	const { tab, setTab, codeList, candidateList } = useContext(TestContext)

	// Todo: Create Jest functions
	function getTabMetric(name) {
		switch (name) {
			case 'Candidates':
				if (candidateList?.length) {
					return candidateList.length + ' Total'
				}

				return '0 Total'

			case 'Manage Access':
				const numActive = countListItemsWithObjectAttribute(codeList, 'status', 'open')

				return numActive + ' Active'

			case 'Executive Report':
				const numShortlisted = countListItemsWithObjectAttribute(candidateList, 'shortlisted', true)
				return numShortlisted + ' Shortlisted'

			default:
				return ''
		}
	}

	return (
		<Card component='nav' className={styles['nav-bar']}>
			{tabs.map((currTab) => {
				return (
					<button className={styles['nav-button']} key={currTab} onClick={() => setTab(currTab)}>
						<span style={{ opacity: currTab === tab ? 1 : 0.6 }}>{currTab}</span>
						<span style={{ fontWeight: currTab === tab ? 'var(--bold)' : 'normal' }}>{getTabMetric(currTab)}</span>
					</button>
				)
			})}
		</Card>
	)
}

export default Navigation
