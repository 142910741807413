export function getAccessCodesForTest(codes, testID) {
	if (!codes) return []

	const list = []

	for (const key in codes) {
		if (codes[key].testID === testID) list.push(codes[key])
	}

	return list
}
