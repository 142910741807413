import { Paper, TextField } from '@mui/material'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { updateDBAccessCode } from '../../../lib/database'
import FormButtons from '../../FormButtons'

function EditUsesMenu({ code, onClose }) {
	const [value, setValue] = useState(code.numAllowed)
	const [errorMessage, setErrorMessage] = useState('')

	const dispatch = useDispatch()

	function handleChange(e) {
		if (e.target.value === '' || /^[0-9]+$/.test(e.target.value)) {
			setValue(e.target.value)
			setErrorMessage('')
		}
	}

	async function handleSubmit() {
		const newNumAllowed = parseInt(value)

		if (newNumAllowed < code.timesUsed) {
			setErrorMessage('Number of allowed uses must be greater than the times the code has been used.')
			return
		}

		if (isNaN(newNumAllowed)) {
			setErrorMessage('New allowed uses must be a number.')
		}

		const updatedCode = {
			id: code.id,
			numAllowed: newNumAllowed,
		}
		await updateDBAccessCode(updatedCode, dispatch)
		onClose()
	}

	return (
		<Paper className='form-container' elevation={16}>
			<span style={{ fontWeight: 'var(--bold)', color: '#001940', fontSize: 18, marginRight: 'auto', marginBottom: 20 }}>
				{code.codeName}
			</span>
			<TextField
				value={value}
				style={{ width: 300 }}
				onChange={(e) => {
					handleChange(e)
				}}
				helperText='New Value'
			/>
			{errorMessage.length > 0 && (
				<span style={{ color: 'var(--error-red)', fontSize: 14, maxWidth: 300, textAlign: 'left', marginTop: 10 }}>
					{errorMessage}
				</span>
			)}
			<FormButtons onCancel={onClose} onSubmit={handleSubmit} />
		</Paper>
	)
}

export default EditUsesMenu
