import React, { useEffect } from 'react'
import { getDateString } from '../../Data'

// Returns an html date input form where the max date is the current date.
function DateInput({ newFilterValue, setNewFilterValue }) {
	const currDate = new Date()

	useEffect(() => {
		setNewFilterValue(getDateString(currDate))

		// eslint-disable-next-line
	}, [])

	return (
		<input
			type='date'
			value={newFilterValue}
			onChange={(e) => {
				setNewFilterValue(e.target.value)
			}}
			min='2018-01-01'
			max={getDateString(currDate)}
		/>
	)
}

export default DateInput
