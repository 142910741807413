import React, { createContext, useState } from 'react'
import { CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js'
import Pool from '../../UserPool'
import { useDispatch } from 'react-redux'
import { resetStore } from '../../functions/ReduxFunctions'

const AccountContext = createContext()

function Account(props) {
	const [loginStage, setLoginStage] = useState('LoggedOut')
	const dispatch = useDispatch()
	//test

	const getSession = async () => {
		return await new Promise((resolve, reject) => {
			const user = Pool.getCurrentUser()
			if (user) {
				user.getSession(async (err, session) => {
					if (err) {
						console.error(err)
						reject()
					} else {
						const attributes = await new Promise((resolve, reject) => {
							user.getUserAttributes((err, attributes) => {
								if (err) {
									reject(err)
								} else {
									const results = {}

									for (let attribute of attributes) {
										const { Name, Value } = attribute
										results[Name] = Value
									}

									resolve(results)
								}
							})
						})

						resolve({ user, ...session, ...attributes })
					}
				})
			}
		})
	}

	const authenticate = async (Username, Password, setUser) => {
		await new Promise((resolve, reject) => {
			const user = new CognitoUser({ Username, Pool })
			const authDetails = new AuthenticationDetails({ Username, Password })

			user.authenticateUser(authDetails, {
				newPasswordRequired: () => {
					setUser(user)
					setLoginStage('SetUpPassword')
				},
				onSuccess: (data) => {
					// So a user doesn't get logged out as soon as they login
					localStorage.setItem('lastMovement', new Date().getTime())

					resolve(data)
				},
				onFailure: (err) => {
					setUser(user)
					console.error('onFailure: ', err)
					if (err.code === 'PasswordResetRequiredException') {
						user.forgotPassword({
							onSuccess: () => {},
							onFailure: (err) => {
								console.error(err)
							},
						})
						setLoginStage('ForceResetPassword')
					}
					reject(err)
				},
			})
		})
	}

	const getClientID = async () => {
		return await new Promise((resolve, reject) => {
			const user = Pool.getCurrentUser()
			if (user) {
				user.getSession(async (err) => {
					if (err) {
						console.error('Error getting user session', err)
						reject(err)
					} else {
						user.getUserAttributes((err, attributes) => {
							if (err) {
								console.error('Error getting user attributes.', err)
								reject(err)
							} else {
								for (const attribute of attributes) {
									if (attribute.Name === 'custom:ClientID') {
										resolve(attribute.Value)
									}
								}
								reject()
							}
						})
					}
				})
			}
		})
	}

	const logout = () => {
		const user = Pool.getCurrentUser()
		resetStore(dispatch)
		localStorage.clear()
		setLoginStage('LoggedOut')
		if (user) {
			user.signOut()
		}
	}

	return (
		<AccountContext.Provider value={{ authenticate, getSession, logout, getClientID, loginStage, setLoginStage }}>
			{props.children}
		</AccountContext.Provider>
	)
}

export { Account, AccountContext }
