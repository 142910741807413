/**
 * Counts the number of items in a list that have a specific object attribute with a given value
 * @param {[Object]} list - The list of objects to search
 * @param {string} attribute - The name of the attribute to search for
 * @param {*} value - The value to search for
 * @returns {Integer} The number of items in the list that have the specified attribute with the specified value
 */
export function countListItemsWithObjectAttribute(list, attribute, value) {
	if (!list || !list[0]) return 0

	let num = 0

	for (const item of list) {
		if (item[attribute] === value) num++
	}

	return num
}

// Searches 'list', which is a list of objects, for the object where comparisonAttribute = comparisonValue, and returns desiredAttribute
export function getListAttributeFromObjectList(list, comparisonAttribute, comparisonValue, desiredAttribute) {
	if (!list) return null

	for (const item of list) {
		if (item[comparisonAttribute] === comparisonValue) return item[desiredAttribute]
	}

	return null
}
