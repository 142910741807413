import React from 'react'
import DateInput from './DateInput'
import TextInput from './TextInput'
import TimeDurationInput from './TimeDurationInput'

/*
 * Handles getting the correct value input field based on the current filter condition.
 * For example, for 'Score' and 'greater than', it will return a text field where you can
 * input a percentage.
 */
function TypeInputHandler({ condition, newFilterValue, setNewFilterValue }) {
	switch (condition.type) {
		case 'text':
			return <TextInput condition={condition} newFilterValue={newFilterValue} setNewFilterValue={setNewFilterValue} />

		case 'time':
			return <TimeDurationInput setNewFilterValue={setNewFilterValue} />

		case 'date':
			return <DateInput newFilterValue={newFilterValue} setNewFilterValue={setNewFilterValue} />
		default:
			return <div />
	}
}

export default TypeInputHandler
