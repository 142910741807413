import { convertToHMTimeString } from '../../lib/date-time'

/* ########################################################################################################################################## */
/* ########################################################################################################################################## */
/* ########################################################################################################################################## */

// Stores the options for filters to add
export const possibleFilters = [
	{
		type: 'Score',
		conditions: [
			{
				label: 'greater than',
				type: 'text',
				typeLabel: 'Percent Score',
				checkIfValidValue: isNumberBetweenZeroAndHundred,
				errorMessage: 'Value must be a number between 0 and 100.',
			},
			{
				label: 'less than',
				type: 'text',
				typeLabel: 'Percent Score',
				checkIfValidValue: isNumberBetweenZeroAndHundred,
				errorMessage: 'Value must be a number between 0 and 100.',
			},
		],
	},
	{
		type: 'Test Time',
		conditions: [
			{ label: 'greater than', type: 'time' },
			{ label: 'less than', type: 'time' },
		],
	},
	{
		type: 'Case Study Time',
		conditions: [
			{ label: 'greater than', type: 'time' },
			{ label: 'less than', type: 'time' },
		],
	},
	{
		type: 'City',
		conditions: [
			{
				label: 'matches',
				type: 'text',
				typeLabel: 'City Name',
			},
		],
	},
	{
		type: 'Date Completed',
		conditions: [
			{ label: 'before', type: 'date' },
			{ label: 'after', type: 'date' },
		],
	},
]

/* ########################################################################################################################################## */
/* ########################################################################################################################################## */
/* ########################################################################################################################################## */

/**
 * @param {String} category - Category of the filter (Score, Test Time, etc)
 * @param {String} condition - Condition of the filter (greater than, less than, etc)
 * @param {String} value - Value of the filter (65, 2020-03-04, etc)
 * @returns Formatted label for a filter
 */
export function getFilterLabel(category, condition, value) {
	switch (category) {
		case 'Score':
			return (
				<span style={{ fontSize: 14 }}>
					{category}
					<span style={{ color: 'rgb(40, 40, 40, 0.8)', padding: '0px 5px 0px 5px' }}> {condition} </span>
					{parseInt(value) + '%'}
				</span>
			)
		case 'Case Study Time':
		case 'Test Time':
			return (
				<span style={{ fontSize: 14 }}>
					{category}
					<span style={{ color: 'rgb(40, 40, 40, 0.8)', padding: '0px 5px 0px 5px' }}> {condition} </span>
					{convertToHMTimeString(value)}
				</span>
			)
		case 'City':
			return (
				<span style={{ fontSize: 14 }}>
					{category}
					<span style={{ color: 'rgb(40, 40, 40, 0.8)', padding: '0px 5px 0px 5px' }}> {condition} </span>
					{value}
				</span>
			)
		case 'Date Completed':
			return (
				<span style={{ fontSize: 14 }}>
					{category}
					<span style={{ color: 'rgb(40, 40, 40, 0.8)', padding: '0px 5px 0px 5px' }}> {condition} </span>
					{value}
				</span>
			)
		default:
			return <div />
	}
}

/* ########################################################################################################################################## */
/* ########################################################################################################################################## */
/* ########################################################################################################################################## */

/**
 * 	Applies a list of filters to a list of candidates, and returns only the candidates that are valid.
 *
 * @param {[Object]} filterList - The list of filters to apply to the rows
 * @param {[Object]} candidates - The list of candidates that will be filtered
 * @returns The list of candidates that passed all filters
 */
export function applyFilters(filterList, candidates) {
	const filteredCandidates = [...candidates]

	for (const filter of filterList) {
		const { category, condition, value } = filter

		for (let i = filteredCandidates.length - 1; i >= 0; i--) {
			switch (category) {
				case 'Score':
					if (!compareValue(condition, parseInt(value), filteredCandidates[i].autoScore)) filteredCandidates.splice(i, 1)
					break
				case 'Case Study Time':
					if (!compareValue(condition, value, filteredCandidates[i].caseStudyTimeMS)) filteredCandidates.splice(i, 1)
					break
				case 'Test Time':
					if (!compareValue(condition, value, filteredCandidates[i].testTimeMS)) filteredCandidates.splice(i, 1)
					break
				case 'City':
					if (!containsSubstring(filteredCandidates[i].location, value)) filteredCandidates.splice(i, 1)
					break
				case 'Date Completed':
					if (!compareDates(condition, value, new Date(filteredCandidates[i].dateSubmitted))) filteredCandidates.splice(i, 1)
					break
				default:
					break
			}
		}
	}

	return filteredCandidates
}

function compareValue(condition, value, candidateValue) {
	if (condition === 'greater than') {
		return candidateValue >= value
	} else if (condition === 'less than') {
		return candidateValue < value
	} else {
		return true
	}
}

// Adds a leading zero to a month or day to ensure mm or dd format
// Adds 1 to month because new Date().getMonth() is 0 indexed
function ensureLeadingZero(number, isMonth) {
	if (isMonth) {
		number += 1
	}

	return ('0' + number).slice(-2)
}

// Gets the date string in format yyyy-mm-dd from a Date object
export function getDateString(date) {
	return date.getFullYear() + '-' + ensureLeadingZero(date.getMonth(), true) + '-' + ensureLeadingZero(date.getDate())
}

function compareDates(condition, value, candidateValue) {
	if (condition === 'before') {
		return getDateString(candidateValue) <= value
	} else if (condition === 'after') {
		return getDateString(candidateValue) >= value
	}
	return true
}

function containsSubstring(str, substring) {
	return str.toLowerCase().includes(substring.toLowerCase())
}

/* ########################################################################################################################################## */
/* ########################################################################################################################################## */
/* ########################################################################################################################################## */

function isNumberBetweenZeroAndHundred(input) {
	const number = parseInt(input)

	console.log(number)

	if (isNaN(number) || number < 0 || number > 100) {
		return false
	}

	return true
}
