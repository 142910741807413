import ChevronLeft from '@mui/icons-material/ChevronLeft'
import ChevronRight from '@mui/icons-material/ChevronRight'
import React, { useState, useEffect } from 'react'

const maxRows = 25

function Pagination({ rows, setPageRows }) {
	const [page, setPage] = useState(0)
	const numPages = Math.ceil(rows.length / maxRows)

	useEffect(() => {
		const newRows = []

		for (let i = page * maxRows; i < Math.min((page + 1) * maxRows, rows.length); i++) {
			newRows.push([rows[i], i])
		}
		setPageRows(newRows)

		// eslint-disable-next-line
	}, [page, rows])

	if (rows.length < maxRows) return <div />

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'row',
				gap: 40,
				color: 'var(--faded-black)',
				fontSize: 14,
				justifyContent: 'flex-end',
				marginRight: 10,
			}}
		>
			{/* <span>Showing {maxRows} Candidates per Page</span> */}
			<div style={{ display: 'flex', gap: 5, color: 'var(--faded-black)', fontSize: 14 }}>
				{page * maxRows + 1} - {Math.min((page + 1) * maxRows, rows.length)} of {rows.length}
				<button onClick={() => setPage(Math.max(page - 1, 0))}>
					<ChevronLeft style={{ height: 20, width: 20, color: 'var(--faded-black)' }} />
				</button>
				<button onClick={() => setPage(Math.min(page + 1, numPages - 1))}>
					<ChevronRight style={{ height: 20, width: 20, color: 'var(--faded-black)' }} />
				</button>
			</div>
		</div>
	)
}

export default Pagination
