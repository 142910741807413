import React, { useContext, useState } from 'react'
import BannerMenuButton from '../../../MenuButton/BannerMenuButton'
import styles from '../Candidates.module.css'
import { CandidatesContext } from '../CandidatesContext'
import VoiceRecordingView from './VoiceRecordingView'
import CandidatePDF from '../PDFBreakdown/CandidatePDF'
import { Card } from '@mui/material'

function Menu() {
	const { candidate, setView } = useContext(CandidatesContext)

	const [showVoice, setShowVoice] = useState(false)

	return (
		<Card className={styles['candidate-menu']}>
			<span className={styles['name']}>{candidate.name}</span>
			<div style={{ display: 'flex', gap: 30 }}>
				<BannerMenuButton
					customclasses={styles['candidate-section-button']}
					onClick={() => {
						setShowVoice(true)
					}}
				>
					Voice Recording
				</BannerMenuButton>
				<BannerMenuButton customclasses={styles['candidate-section-button']} onClick={() => setView('responses')}>
					Responses
				</BannerMenuButton>
				<CandidatePDF />
			</div>
			{showVoice && (
				<div
					style={{ position: 'fixed', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', zIndex: 20, borderRadius: 10 }}
				>
					<VoiceRecordingView setShowVoice={setShowVoice} userID={candidate.userID} />
				</div>
			)}
		</Card>
	)
}

export default Menu
