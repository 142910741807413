import './App.css'
import React, { useEffect, useState } from 'react'
import LoginPage from './pages/LoginPage'
import { Account } from './components/Account/Account'

import Amplify from 'aws-amplify'
import awsconfig from './aws-exports'

import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles'
import { createTheme } from '@mui/material/styles'

import './index.css'
import InvalidSizeError from './components/InvalidSizeError'

Amplify.configure(awsconfig)

const theme = createTheme({
	typography: { fontFamily: "'Poppins', sans-serif" },
	components: {
		MuiButton: {
			text: {
				fontSize: 16,
			},
		},
		MuiFormHelperText: {
			root: { fontSize: 14 },
		},
		MuiRadio: {
			root: {
				'& .MuiSvgIcon-root': {
					color: 'var(--primary-color-one)',
					height: 20,
					width: 20,
				},
			},
		},
		MuiList: {
			root: {
				maxHeight: 'min(300px, 50vh)',
			},
		},
		MuiTextField: {
			defaultProps: {
				variant: 'standard',
			},
		},
		MuiSelect: {
			defaultProps: {
				variant: 'standard',
			},
		},
		MuiCard: {
			styleOverrides: {
				root: {
					borderRadius: '6px',
				},
			},
		},
		MuiPaper: {
			styleOverrides: {
				root: {
					borderRadius: '6px',
				},
			},
		},
	},
})

function App() {
	const [validWindowSize, setValidWindowSize] = useState(window.innerWidth >= 1000 && window.innerHeight >= 550)

	function checkWindowSize() {
		if (window.innerWidth < 940 || window.innerHeight < 550) {
			setValidWindowSize(false)
		} else {
			setValidWindowSize(true)
		}
	}

	useEffect(() => {
		window.addEventListener('resize', checkWindowSize)
		return () => {
			window.removeEventListener('resize', checkWindowSize)
		}
	}, [])

	return (
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={theme}>
				<div className='App'>
					<Account>
						{!validWindowSize && <InvalidSizeError />}
						<LoginPage />
					</Account>
				</div>
			</ThemeProvider>
		</StyledEngineProvider>
	)
}

export default App
