
import { useEffect, useState } from "react"
import { FormControlLabel, Checkbox } from "@mui/material"
import { deleteScoringTag, addScoringTag } from "../Functions"

function createParagraphBreaksTag() {

    return {
        tag: "ParagraphBreaks",
        maxScore: 0.5,
        dataMapping: "AttentionToDetail",
        data: "none" 
    }
    
}

function ParagraphBreaks(props) {

    const [paragraphBreaks, setParagraphBreaks] = useState(false)

    useEffect(() => {

        // Search questions
        for (const question of props.testInfo.questions) {

            if (question.id === props.questionID) {

                // Set the default values for the state based off existing scoring tag
                for (const scoringTag of question.scoringTags) {
                    if (scoringTag.tag === "ParagraphBreaks") {
                        setParagraphBreaks(true)
                    }
                }

                return
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div>
            <FormControlLabel 
                control={
                    <Checkbox 
                        color="primary"
                        checked={paragraphBreaks}
                        onChange={(e) => {

                            if (e.target.checked === false) { // delete scoring tag
                                deleteScoringTag(props.questionID, "ParagraphBreaks", props.testInfo, props.setTestInfo)
                            } else { // add scoring tag
                                const paragraphBreaksTag = createParagraphBreaksTag()
                                addScoringTag(props.questionID, paragraphBreaksTag, props.testInfo, props.setTestInfo)
                            }
                            setParagraphBreaks(e.target.checked)

                        }} 
                    />
                } 
                label="Score for response being two paragraphs (0.5 points)"
            />
        </div>
    )

}

export default ParagraphBreaks