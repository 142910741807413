export const caseStudies = [
	// {
	// 	name: 'Retail - Orchard Plaza',
	// 	files: [
	// 		'Budget:CaseStudyFiles/OrchardPlazaBudget.pdf',
	// 		'Rent Roll:CaseStudyFiles/OrchardPlazaRentRoll.pdf',
	// 		'Cash Flow:CaseStudyFiles/OrchardPlazaCashFlowReport.pdf',
	// 	],
	// 	questions: [
	// 		{
	// 			id: 'OrchardPlazaCaseStudyDescriptions',
	// 			format: 'none',
	// 			questionText:
	// 				"You've been asked to review Orchard Plaza for a potential acquisition in July of 2019. It is anticipated that there will be an 8-year hold period." +
	// 				'It is a small strip plaza located in a small municipality outside of Oshawa, Ontario. It has been a successful property with low turnover given the ' +
	// 				'longer-term leases in place. The property features a National branded pharmacy as the anchor tenant that has drawn steady traffic to the plaza. ' +
	// 				"The building is noted to be in good condition, the roof is original from 2011. \n\n We've provided you with a Budget, 10 Year Cash Flow Projection " +
	// 				'and Rent Roll in order to review the acquisition. You will need access to a calculator in order to complete this assignment.',
	// 			section: 'CaseStudyDescription',
	// 			hasNotes: false,
	// 			helperText: '',
	// 			maxScore: 0,
	// 			order: 1,
	// 			referenceID: '',
	// 		},
	// 		{
	// 			id: 'OrchardPlazaCaseStudyInstructions',
	// 			format: 'none',
	// 			questionText:
	// 				'Answer the following five questions as they relate to reviewing this asset for acquisition and the associated underwriting/financial ' +
	// 				'modeling of the asset. The details on the property are provided, please click on each link below. We are looking for not only your answer to ' +
	// 				'each question but also a short explanation of why you selected your response.',
	// 			section: 'CaseStudyInstructions',
	// 			hasNotes: false,
	// 			helperText: '',
	// 			maxScore: 0,
	// 			order: 1,
	// 			referenceID: '',
	// 		},
	// 		{
	// 			id: 'OrchardPlazaCaseStudy1',
	// 			format: 'TimedMultipleChoice',
	// 			questionText:
	// 				'In setting up a financial model for the above asset, how would you calculate/enter the property management fee expense?',
	// 			section: 'CaseStudy',
	// 			hasNotes: true,
	// 			helperText: '',
	// 			maxScore: 15,
	// 			order: 1,
	// 			referenceID: 'RetailOrchardPlaza1',
	// 			questionAnswers: [
	// 				{
	// 					answerText: '3.0% of Effective Gross Revenue',
	// 					score: 5,
	// 					isCorrect: false,
	// 				},
	// 				{
	// 					answerText: '4.13% of Rental Revenue',
	// 					score: 0,
	// 					isCorrect: false,
	// 				},
	// 				{
	// 					answerText: '$1.19 / Rentable Area',
	// 					score: 0,
	// 					isCorrect: false,
	// 				},
	// 				{
	// 					answerText: '$41,224 per annum',
	// 					score: 0,
	// 					isCorrect: false,
	// 				},
	// 			],
	// 			scoringTags: [
	// 				{
	// 					tag: 'FleschKincaid',
	// 					data: 'none',
	// 					dataMapping: 'CommunicationSkills',
	// 					maxScore: 10,
	// 				},
	// 				{
	// 					tag: 'CaseStudyTMCMapRE',
	// 					data: 'none',
	// 					dataMapping: 'REKnowledge',
	// 					maxScore: 5,
	// 				},
	// 			],
	// 		},
	// 		{
	// 			id: 'OrchardPlazaCaseStudy2',
	// 			format: 'TimedMultipleChoice',
	// 			questionText:
	// 				'Based on the information provided, what management fee/administration fee would you model to recover from the tenants?',
	// 			section: 'CaseStudy',
	// 			hasNotes: true,
	// 			helperText: '',
	// 			maxScore: 15,
	// 			order: 2,
	// 			referenceID: 'RetailOrchardPlaza2',
	// 			questionAnswers: [
	// 				{
	// 					answerText: '3.0% of Effective Gross Revenue',
	// 					score: 0,
	// 					isCorrect: false,
	// 				},
	// 				{
	// 					answerText: '15% of all Property Expenses',
	// 					score: 0,
	// 					isCorrect: false,
	// 				},
	// 				{
	// 					answerText: '15% of Recoverable Operating Expenses',
	// 					score: 0,
	// 					isCorrect: false,
	// 				},
	// 				{
	// 					answerText: 'Insufficient information to determine',
	// 					score: 5,
	// 					isCorrect: false,
	// 				},
	// 			],
	// 			scoringTags: [
	// 				{
	// 					tag: 'FleschKincaid',
	// 					data: 'none',
	// 					dataMapping: 'CommunicationSkills',
	// 					maxScore: 10,
	// 				},
	// 				{
	// 					tag: 'CaseStudyTMCMapRE',
	// 					data: 'none',
	// 					dataMapping: 'REKnowledge',
	// 					maxScore: 5,
	// 				},
	// 			],
	// 		},
	// 		{
	// 			id: 'OrchardPlazaCaseStudy3',
	// 			format: 'TimedMultipleChoice',
	// 			questionText:
	// 				'From the budget expenses, which expenses are excluded from the cash flow report when completing a discounted cash flow model of a property?',
	// 			section: 'CaseStudy',
	// 			hasNotes: true,
	// 			helperText: '',
	// 			maxScore: 15,
	// 			order: 3,
	// 			referenceID: 'RetailOrchardPlaza3',
	// 			questionAnswers: [
	// 				{
	// 					answerText: 'Professional Fees',
	// 					score: 0,
	// 					isCorrect: false,
	// 				},
	// 				{
	// 					answerText: 'Wages & Benefits',
	// 					score: 0,
	// 					isCorrect: false,
	// 				},
	// 				{
	// 					answerText: 'Deferred Amortization (P&I)',
	// 					score: 5,
	// 					isCorrect: false,
	// 				},
	// 				{
	// 					answerText: 'Professional Fees & Deferred Amortization (P&I)',
	// 					score: 0,
	// 					isCorrect: false,
	// 				},
	// 			],
	// 			scoringTags: [
	// 				{
	// 					tag: 'FleschKincaid',
	// 					data: 'none',
	// 					dataMapping: 'CommunicationSkills',
	// 					maxScore: 10,
	// 				},
	// 				{
	// 					tag: 'CaseStudyTMCMapRE',
	// 					data: 'none',
	// 					dataMapping: 'REKnowledge',
	// 					maxScore: 5,
	// 				},
	// 			],
	// 		},
	// 		{
	// 			id: 'OrchardPlazaCaseStudy4',
	// 			format: 'TimedMultipleChoice',
	// 			questionText:
	// 				'If the model has a market leasing assumption that carries a 75% renewal probability with 6 months of lag time for a new deal, when would the first leasing related costs appear in the cash flow?',
	// 			section: 'CaseStudy',
	// 			hasNotes: true,
	// 			helperText: '',
	// 			maxScore: 15,
	// 			order: 4,
	// 			referenceID: 'RetailOrchardPlaza4',
	// 			questionAnswers: [
	// 				{
	// 					answerText: 'January 2022',
	// 					score: 0,
	// 					isCorrect: false,
	// 				},
	// 				{
	// 					answerText: 'February 2022',
	// 					score: 5,
	// 					isCorrect: false,
	// 				},
	// 				{
	// 					answerText: 'March 2022',
	// 					score: 0,
	// 					isCorrect: false,
	// 				},
	// 				{
	// 					answerText: 'July 2022',
	// 					score: 0,
	// 					isCorrect: false,
	// 				},
	// 			],
	// 			scoringTags: [
	// 				{
	// 					tag: 'FleschKincaid',
	// 					data: 'none',
	// 					dataMapping: 'CommunicationSkills',
	// 					maxScore: 10,
	// 				},
	// 				{
	// 					tag: 'CaseStudyTMCMapRE',
	// 					data: 'none',
	// 					dataMapping: 'REKnowledge',
	// 					maxScore: 5,
	// 				},
	// 			],
	// 		},
	// 		{
	// 			id: 'OrchardPlazaCaseStudy5',
	// 			format: 'Memo',
	// 			questionText:
	// 				'Prepare a four-paragraph memo to your Manager outlining, based on your initial review of Orchard Plaza and market knowledge, what you ' +
	// 				'think the strengths and weaknesses are of acquiring the plaza.',
	// 			section: 'CaseStudy',
	// 			hasNotes: false,
	// 			helperText: '',
	// 			maxScore: 10,
	// 			order: 5,
	// 			referenceID: 'RetailOrchardPlaza5',
	// 			scoringTags: [
	// 				{
	// 					tag: 'FleschKincaid',
	// 					data: 'none',
	// 					dataMapping: 'CommunicationSkills',
	// 					maxScore: 10,
	// 				},
	// 			],
	// 		},
	// 	],
	// },

	/* 
    ########################################################################################################################################################################
    ########################################################################################################################################################################
    ########################################################################################################################################################################
    ########################################################################################################################################################################
    ########################################################################################################################################################################
    */

	{
		name: 'Multi Res - Primrose Heights',
		files: [
			'Budget:CaseStudyFiles/PrimroseHeightsBudget.pdf',
			'Rent Roll:CaseStudyFiles/PrimroseHeightsRentRoll.pdf',
			'Income Statements:CaseStudyFiles/PrimroseHeightsIS.pdf',
		],
		questions: [
			{
				id: 'PrimroseHeightsCaseStudyDescription',
				format: 'none',
				questionText:
					"You've been asked to review Primrose Heights for a potential acquisition in July of 2020. It is anticipated that there will be a 5-year hold " +
					'period, it is a 9-unit multi-tenant residential building in an affluent area of a large metropolitan city. It has been a successful property with ' +
					'low turnover, there has actually been no turnover in the last three years. They are not anticipating any turnover in the current budget year. ' +
					'The market turnover rate is 20%. The average rental rates are below market rates. The three-story building constructed in 1956 appears to be well ' +
					'maintained. \n\n We have provided you with a Rent Roll, Budget, and 3 years of historical income statements (I/S) in order to review the acquisition. ' +
					'Note: You will need a calculator in order to complete this assignment.',
				section: 'CaseStudyDescription',
				hasNotes: false,
				helperText: '',
				maxScore: 0,
				order: 1,
				referenceID: '',
			},
			{
				id: 'PrimroseHeightsCaseStudyInstructions',
				format: 'none',
				questionText:
					'Answer the following questions as they relate to reviewing this asset for acquisiton and the associated underwriting/financial modeling of ' +
					'the asset. The details on the property are provided, please click the button below. We are looking for not only your answer to ' +
					'each question but also a short explanation of why you selected your response.',
				section: 'CaseStudyInstructions',
				hasNotes: false,
				helperText: '',
				maxScore: 0,
				order: 1,
				referenceID: '',
			},
			{
				id: 'PrimroseHeightsCaseStudy1',
				format: 'TimedMultipleChoice',
				questionText:
					'In setting up a multi year financial model for the above asset, how would you calculate/enter the property management fee expense?',
				section: 'CaseStudy',
				hasNotes: true,
				helperText: '',
				maxScore: 15,
				order: 1,
				referenceID: 'MultiResPrimroseHeights1',
				questionAnswers: [
					{
						answerText: '3.0% of Effective Gross Revenue',
						score: 5,
						isCorrect: false,
					},
					{
						answerText: '8.25% of expenses',
						score: 0,
						isCorrect: false,
					},
					{
						answerText: '$4,786 per annum',
						score: 0,
						isCorrect: false,
					},
					{
						answerText: '$532 per unit',
						score: 0,
						isCorrect: false,
					},
				],
				scoringTags: [
					{
						tag: 'FleschKincaid',
						data: 'none',
						dataMapping: 'CommunicationSkills',
						maxScore: 10,
					},
					{
						tag: 'CaseStudyTMCMapRE',
						data: 'none',
						dataMapping: 'REKnowledge',
						maxScore: 5,
					},
				],
			},
			{
				id: 'PrimroseHeightsCaseStudy2',
				format: 'TimedMultipleChoice',
				questionText:
					'Based on the information provided, what rental rates would you include in your forecast/proforma model from the tenants?',
				section: 'CaseStudy',
				hasNotes: true,
				helperText: '',
				maxScore: 15,
				order: 2,
				referenceID: 'MultiResPrimroseHeights2',
				questionAnswers: [
					{
						answerText: 'Contract rental rates',
						score: 3,
						isCorrect: false,
					},
					{
						answerText: 'A blend of contract and market rental rates',
						score: 5,
						isCorrect: false,
					},
					{
						answerText: 'Insufficient information to determine',
						score: 2,
						isCorrect: false,
					},
					{
						answerText: 'Market rental rates',
						score: 0,
						isCorrect: false,
					},
				],
				scoringTags: [
					{
						tag: 'FleschKincaid',
						data: 'none',
						dataMapping: 'CommunicationSkills',
						maxScore: 10,
					},
					{
						tag: 'CaseStudyTMCMapRE',
						data: 'none',
						dataMapping: 'REKnowledge',
						maxScore: 5,
					},
				],
			},
			{
				id: 'PrimroseHeightsCaseStudy3',
				format: 'TimedMultipleChoice',
				questionText:
					'Which operating expenses would you exclude when completing for forecast/proforma income forecast for the property?',
				section: 'CaseStudy',
				hasNotes: true,
				helperText: '',
				maxScore: 15,
				order: 3,
				referenceID: 'MultiResPrimroseHeights3',
				questionAnswers: [
					{
						answerText: 'Bad Debt',
						score: 3,
						isCorrect: false,
					},
					{
						answerText: 'Interest on Rent Deposits',
						score: 3,
						isCorrect: false,
					},
					{
						answerText: 'Professional Fees',
						score: 0,
						isCorrect: false,
					},
					{
						answerText: 'Depreciation',
						score: 5,
						isCorrect: false,
					},
				],
				scoringTags: [
					{
						tag: 'FleschKincaid',
						data: 'none',
						dataMapping: 'CommunicationSkills',
						maxScore: 10,
					},
					{
						tag: 'CaseStudyTMCMapRE',
						data: 'none',
						dataMapping: 'REKnowledge',
						maxScore: 5,
					},
				],
			},
			{
				id: 'PrimroseHeightsCaseStudy4',
				format: 'TimedMultipleChoice',
				questionText:
					'In completing a forecast/proforma model for the hold/analysis period, what renewal probability would you utilize on the units?',
				section: 'CaseStudy',
				hasNotes: true,
				helperText: '',
				maxScore: 15,
				order: 4,
				referenceID: 'MultiResPrimroseHeights4',
				questionAnswers: [
					{
						answerText: '20%',
						score: 0,
						isCorrect: false,
					},
					{
						answerText: '80%',
						score: 5,
						isCorrect: false,
					},
					{
						answerText: '90%',
						score: 3,
						isCorrect: false,
					},
					{
						answerText: '100%',
						score: 0,
						isCorrect: false,
					},
				],
				scoringTags: [
					{
						tag: 'FleschKincaid',
						data: 'none',
						dataMapping: 'CommunicationSkills',
						maxScore: 10,
					},
					{
						tag: 'CaseStudyTMCMapRE',
						data: 'none',
						dataMapping: 'REKnowledge',
						maxScore: 5,
					},
				],
			},
			{
				id: 'PrimroseHeightsCaseStudy5',
				format: 'Memo',
				questionText: 'In a brief memo, explain why you feel the subject property is a good or bad acquisition.',
				section: 'CaseStudy',
				hasNotes: false,
				helperText: '',
				maxScore: 10,
				order: 5,
				referenceID: 'MultiResPrimroseHeights5',
				scoringTags: [
					{
						tag: 'FleschKincaid',
						data: 'none',
						dataMapping: 'CommunicationSkills',
						maxScore: 10,
					},
					{
						tag: 'CaseStudyTMCMapRE',
						data: 'none',
						dataMapping: 'REKnowledge',
						maxScore: 5,
					},
				],
			},
		],
	},

	/* 
    ########################################################################################################################################################################
    ########################################################################################################################################################################
    ########################################################################################################################################################################
    ########################################################################################################################################################################
    ########################################################################################################################################################################
    */

	{
		name: '2022 Multi Res - Primrose Heights',
		files: [
			'Budget:CaseStudyFiles/PrimroseHeightsBudget.pdf',
			'Rent Roll:CaseStudyFiles/PrimroseHeightsRentRoll.pdf',
			'Income Statements:CaseStudyFiles/PrimroseHeightsIS.pdf',
		],
		questions: [
			{
				id: 'PrimroseHeightsCaseStudyDescription',
				format: 'none',
				questionText:
					"You've been asked to review Primrose Heights for a potential acquisition in January of 2023. It is anticipated that there will be a 5-year hold " +
					'period, it is a 9-unit multi-tenant residential building in an affluent area of a large metropolitan city. It has been a successful property with ' +
					'low turnover, there has actually been no turnover in the last three years. They are not anticipating any turnover in the current budget year. ' +
					'The market turnover rate is 20%. The average rental rates are significantly below market rates. The three-story building constructed in 1970 appears to be well ' +
					'maintained. \n\n We have provided you with a Rent Roll, Budget, and 3 years of historical income statements (I/S) in order to review the acquisition. ' +
					'Note: You will need a calculator in order to complete this assignment.',
				section: 'CaseStudyDescription',
				hasNotes: false,
				helperText: '',
				maxScore: 0,
				order: 1,
				referenceID: '',
			},
			{
				id: 'PrimroseHeightsCaseStudyInstructions',
				format: 'none',
				questionText:
					'Answer the following five questions as they relate to reviewing this asset for acquisiton and the associated underwriting/financial modeling of ' +
					'the asset. The details on the property are provided, please click the button below. We are looking for not only your answer to each question but also a short ' +
					'explanation of why you selected your response.',
				section: 'CaseStudyInstructions',
				hasNotes: false,
				helperText: '',
				maxScore: 0,
				order: 1,
				referenceID: '',
			},
			{
				id: 'PrimroseHeightsCaseStudy1',
				format: 'TimedMultipleChoice',
				questionText:
					'In setting up a multi year financial model for the above asset, how would you calculate/enter the property management fee expense?',
				section: 'CaseStudy',
				hasNotes: true,
				helperText: '',
				maxScore: 16,
				order: 1,
				referenceID: 'MultiResPrimroseHeights1',
				questionAnswers: [
					{
						answerText: '3.0% of Effective Gross Revenue',
						score: 5,
						isCorrect: false,
					},
					{
						answerText: '$4,914 per annum',
						score: 0,
						isCorrect: false,
					},
					{
						answerText: '3.0% of Potential Gross Revenue',
						score: 0,
						isCorrect: false,
					},
					{
						answerText: '$546 per unit',
						score: 0,
						isCorrect: false,
					},
				],
				scoringTags: [
					{
						tag: 'FleschKincaid',
						data: 'none',
						dataMapping: 'CommunicationSkills',
						maxScore: 10,
					},
					{
						tag: 'HitWords',
						maxScore: 1.0,
						dataMapping: 'PositionRequirements',
						data: 'analysis period,dynamic',
					},
					{
						tag: 'CaseStudyTMCMapRE',
						data: 'none',
						dataMapping: 'REKnowledge',
						maxScore: 5,
					},
				],
			},
			{
				id: 'PrimroseHeightsCaseStudy2',
				format: 'TimedMultipleChoice',
				questionText:
					'Based on the information provided, what rental rates would you include in your forecast/proforma model from the tenants?',
				section: 'CaseStudy',
				hasNotes: true,
				helperText: '',
				maxScore: 16.5,
				order: 2,
				referenceID: 'MultiResPrimroseHeights2',
				questionAnswers: [
					{
						answerText: 'Contract rental rates',
						score: 3,
						isCorrect: false,
					},
					{
						answerText: 'A blend of contract and market rental rates',
						score: 5,
						isCorrect: false,
					},
					{
						answerText: 'Insufficient information to determine',
						score: 2,
						isCorrect: false,
					},
					{
						answerText: 'Market rental rates',
						score: 0,
						isCorrect: false,
					},
				],
				scoringTags: [
					{
						tag: 'FleschKincaid',
						data: 'none',
						dataMapping: 'CommunicationSkills',
						maxScore: 10,
					},
					{
						tag: 'HitWords',
						maxScore: 1.5,
						dataMapping: 'PositionRequirements',
						data: 'stabilized,contract,market',
					},
					{
						tag: 'CaseStudyTMCMapRE',
						data: 'none',
						dataMapping: 'REKnowledge',
						maxScore: 5,
					},
				],
			},
			{
				id: 'PrimroseHeightsCaseStudy3',
				format: 'TimedMultipleChoice',
				questionText:
					'Which operating expenses would you exclude when completing for forecast/proforma income forecast for the property?',
				section: 'CaseStudy',
				hasNotes: true,
				helperText: '',
				maxScore: 17.0,
				order: 3,
				referenceID: 'MultiResPrimroseHeights3',
				questionAnswers: [
					{
						answerText: 'Bad Debt',
						score: 3,
						isCorrect: false,
					},
					{
						answerText: 'Interest on Rent Deposits',
						score: 3,
						isCorrect: false,
					},
					{
						answerText: 'Professional Fees',
						score: 0,
						isCorrect: false,
					},
					{
						answerText: 'Depreciation',
						score: 5,
						isCorrect: false,
					},
				],
				scoringTags: [
					{
						tag: 'FleschKincaid',
						data: 'none',
						dataMapping: 'CommunicationSkills',
						maxScore: 10,
					},
					{
						tag: 'HitWords',
						maxScore: 2.0,
						dataMapping: 'PositionRequirements',
						data: 'non-cash,accounting,tax,personalized',
					},
					{
						tag: 'CaseStudyTMCMapRE',
						data: 'none',
						dataMapping: 'REKnowledge',
						maxScore: 5,
					},
				],
			},
			{
				id: 'PrimroseHeightsCaseStudy4',
				format: 'TimedMultipleChoice',
				questionText:
					'In completing a forecast/proforma model for the hold/analysis period, what renewal probability would you utilize on the units?',
				section: 'CaseStudy',
				hasNotes: true,
				helperText: '',
				maxScore: 16,
				order: 4,
				referenceID: 'MultiResPrimroseHeights4',
				questionAnswers: [
					{
						answerText: '20%',
						score: 0,
						isCorrect: false,
					},
					{
						answerText: '80%',
						score: 5,
						isCorrect: false,
					},
					{
						answerText: '90%',
						score: 3,
						isCorrect: false,
					},
					{
						answerText: '100%',
						score: 0,
						isCorrect: false,
					},
				],
				scoringTags: [
					{
						tag: 'FleschKincaid',
						data: 'none',
						dataMapping: 'CommunicationSkills',
						maxScore: 10,
					},
					{
						tag: 'HitWords',
						maxScore: 1.0,
						dataMapping: 'PositionRequirements',
						data: 'market,turnover',
					},
					{
						tag: 'CaseStudyTMCMapRE',
						data: 'none',
						dataMapping: 'REKnowledge',
						maxScore: 5,
					},
				],
			},
			{
				id: 'PrimroseHeightsCaseStudy5',
				format: 'Memo',
				questionText:
					'Prepare a four-paragraph memo to your Manager outlining, based on your initial review of Primrose Heights and market knowledge, ' +
					'what you think the strengths and weaknesses of acquiring the property are.',
				section: 'CaseStudy',
				hasNotes: false,
				helperText: '',
				maxScore: 12,
				order: 5,
				referenceID: 'MultiResPrimroseHeights5',
				scoringTags: [
					{
						tag: 'FleschKincaid',
						data: 'none',
						dataMapping: 'CommunicationSkills',
						maxScore: 10,
					},
					{
						tag: 'HitWords',
						maxScore: 2.0,
						dataMapping: 'PositionRequirements',
						data: 'increase,opportunity,low rents,below market',
					},
				],
			},
		],
	},

	/* 
    ########################################################################################################################################################################
    ########################################################################################################################################################################
    ########################################################################################################################################################################
    ########################################################################################################################################################################
    ########################################################################################################################################################################
    */

	{
		name: 'Development - Toronto',
		files: ['Proforma:CaseStudyFiles/2023MixedUseToronto.pdf'],
		questions: [
			{
				id: '2023MixedUseTorontoDescription',
				format: 'none',
				questionText:
					'The subject site is an 0.85-acre site located in the City of Toronto. The site is being considered for a mixed-use development that would include an income producing commercial asset on the ground level.  You have been asked to review the broker information provided for the development and determine the overall feasibility of the project. The project will require an acquisition of the site, a construction period of 36 months as well as a minimum 20 months for presale and site plan applications.',
				section: 'CaseStudyDescription',
				hasNotes: false,
				helperText: '',
				maxScore: 0,
				order: 1,
				referenceID: '',
			},
			{
				id: '2023MixedUseTorontoInstructions',
				format: 'none',
				questionText:
					'The attached spreadsheet provided includes the project budget, timeline, and projected returns on a high-level basis. Please review the spreadsheet and answer the following questions. Please provide explanation below for each of your responses.',
				section: 'CaseStudyInstructions',
				hasNotes: false,
				helperText: '',
				maxScore: 0,
				order: 1,
				referenceID: '',
			},
			{
				id: '2023MixedUseToronto1',
				format: 'TimedMultipleChoice',
				questionText: 'Based on the information provided in the spreadsheet, what is the approximate FSI of the site?',
				section: 'CaseStudy',
				hasNotes: true,
				helperText: '',
				maxScore: 15,
				order: 1,
				referenceID: 'MultiResPrimroseHeights1',
				questionAnswers: [
					{
						answerText: '6.8x',
						score: 5,
						isCorrect: false,
					},
					{
						answerText: '5.3x',
						score: 0,
						isCorrect: false,
					},
					{
						answerText: '6.5x',
						score: 0,
						isCorrect: false,
					},
					{
						answerText: 'None of the above',
						score: 0,
						isCorrect: false,
					},
				],
				scoringTags: [
					{
						tag: 'FleschKincaid',
						data: 'none',
						dataMapping: 'CommunicationSkills',
						maxScore: 10,
					},
					{
						tag: 'CaseStudyTMCMapRE',
						data: 'none',
						dataMapping: 'REKnowledge',
						maxScore: 5,
					},
				],
			},
			{
				id: '2023MixedUseToronto2',
				format: 'TimedMultipleChoice',
				questionText: 'Based on the information provided, what are the GP Fee conditions?',
				section: 'CaseStudy',
				hasNotes: true,
				helperText: '',
				maxScore: 15,
				order: 2,
				referenceID: 'MultiResPrimroseHeights2',
				questionAnswers: [
					{
						answerText: 'The investor will have a preferred return of 8% and the remainder of the proceeds are the GP Fees',
						score: 0,
						isCorrect: false,
					},
					{
						answerText:
							'The investor will have a preferred return of 8%, their equity payback and the remaining proceeds are the GP Fees',
						score: 0,
						isCorrect: false,
					},
					{
						answerText:
							'The investor will have a preferred return of 8%, their equity payback, and satisfy a minimum IRR of 27% and the remaining proceeds are the GP Fees',
						score: 0,
						isCorrect: false,
					},
					{
						answerText: 'None of the above',
						score: 5,
						isCorrect: false,
					},
				],
				scoringTags: [
					{
						tag: 'FleschKincaid',
						data: 'none',
						dataMapping: 'CommunicationSkills',
						maxScore: 10,
					},
					{
						tag: 'CaseStudyTMCMapRE',
						data: 'none',
						dataMapping: 'REKnowledge',
						maxScore: 5,
					},
				],
			},
			{
				id: '2023MixedUseToronto3',
				format: 'TimedMultipleChoice',
				questionText: 'Given the current assumptions, what is the minimum selling price required for the GP to make a fee on exit.',
				section: 'CaseStudy',
				hasNotes: true,
				helperText: '',
				maxScore: 15,
				order: 3,
				referenceID: 'MultiResPrimroseHeights3',
				questionAnswers: [
					{
						answerText: '$1,300',
						score: 0,
						isCorrect: false,
					},
					{
						answerText: '$1,275',
						score: 0,
						isCorrect: false,
					},
					{
						answerText: '$1,250',
						score: 5,
						isCorrect: false,
					},
					{
						answerText: 'Not enough information to determine',
						score: 0,
						isCorrect: false,
					},
				],
				scoringTags: [
					{
						tag: 'FleschKincaid',
						data: 'none',
						dataMapping: 'CommunicationSkills',
						maxScore: 10,
					},
					{
						tag: 'CaseStudyTMCMapRE',
						data: 'none',
						dataMapping: 'REKnowledge',
						maxScore: 5,
					},
				],
			},
			{
				id: '2023MixedUseToronto4',
				format: 'TimedMultipleChoice',
				questionText: 'What is the assumed efficiency of the total residential site?',
				section: 'CaseStudy',
				hasNotes: true,
				helperText: '',
				maxScore: 15,
				order: 4,
				referenceID: 'MultiResPrimroseHeights4',
				questionAnswers: [
					{
						answerText: '80%',
						score: 0,
						isCorrect: false,
					},
					{
						answerText: '82%',
						score: 5,
						isCorrect: false,
					},
					{
						answerText: '85%',
						score: 0,
						isCorrect: false,
					},
					{
						answerText: 'Not enough information to determine',
						score: 0,
						isCorrect: false,
					},
				],
				scoringTags: [
					{
						tag: 'FleschKincaid',
						data: 'none',
						dataMapping: 'CommunicationSkills',
						maxScore: 10,
					},
					{
						tag: 'CaseStudyTMCMapRE',
						data: 'none',
						dataMapping: 'REKnowledge',
						maxScore: 5,
					},
				],
			},
			{
				id: '2023MixedUseToronto5',
				format: 'TimedMultipleChoice',
				questionText: 'What is the assumed acquisition LTV in the current information provided?',
				section: 'CaseStudy',
				hasNotes: true,
				helperText: '',
				maxScore: 15,
				order: 5,
				referenceID: 'MultiResPrimroseHeights4',
				questionAnswers: [
					{
						answerText: '50%',
						score: 5,
						isCorrect: false,
					},
					{
						answerText: '60%',
						score: 0,
						isCorrect: false,
					},
					{
						answerText: '70%',
						score: 0,
						isCorrect: false,
					},
					{
						answerText: 'Not enough information to determine',
						score: 0,
						isCorrect: false,
					},
				],
				scoringTags: [
					{
						tag: 'FleschKincaid',
						data: 'none',
						dataMapping: 'CommunicationSkills',
						maxScore: 10,
					},
					{
						tag: 'CaseStudyTMCMapRE',
						data: 'none',
						dataMapping: 'REKnowledge',
						maxScore: 5,
					},
				],
			},
			{
				id: '2023MixedUseToronto6',
				format: 'Memo',
				questionText:
					'Prepare a small summary of the information provided in memo form highlighting the risks and strength of this development project.',
				section: 'CaseStudy',
				hasNotes: false,
				helperText: '',
				maxScore: 10,
				order: 6,
				referenceID: 'MultiResPrimroseHeights5',
				scoringTags: [
					{
						tag: 'FleschKincaid',
						data: 'none',
						dataMapping: 'CommunicationSkills',
						maxScore: 10,
					},
				],
			},
		],
	},

	/* 
    ########################################################################################################################################################################
    ########################################################################################################################################################################
    ########################################################################################################################################################################
    ########################################################################################################################################################################
    ########################################################################################################################################################################
    */

	{
		name: 'Retail - Orchard Plaza 2023',
		files: [
			'Budget:CaseStudyFiles/OrchardPlazaBudget2023.pdf',
			'Rent Roll:CaseStudyFiles/OrchardPlazaRentRoll2023.pdf',
			'Cash Flow:CaseStudyFiles/OrchardPlazaCashFlowReport2023.pdf',
		],
		questions: [
			{
				id: 'OrchardPlaza2023CaseStudyDescriptions',
				format: 'none',
				questionText:
					"You've been asked to review Orchard Plaza for a potential acquisition in July of 2023. It is anticipated that there will be a 10-year hold period. It is a small strip plaza located in a small municipality outside of Oshawa, Ontario. It has been a successful property with low turnover given the longer-term leases in place. The property features a National branded pharmacy as the anchor tenant that has drawn steady traffic to the plaza. The building is noted to be in good condition, the roof is original from 2011. " +
					'We’ve provided you with a Budget, 11 Year Cash Flow Projection and Rent Roll in order to review the acquisition. You will need access to a calculator in order to complete this assignment.',

				section: 'CaseStudyDescription',
				hasNotes: false,
				helperText: '',
				maxScore: 0,
				order: 1,
				referenceID: '',
			},
			{
				id: 'OrchardPlaza2023CaseStudyInstructions',
				format: 'none',
				questionText:
					'Answer the following five questions as they relate to reviewing this asset for acquisition and the associated underwriting/financial modeling of the asset. The details on the property are provided, please click on each link below. We are looking for not only your answer to each question but a short explanation of why you selected your response. Please provide your responses in the Notes sections provided with each question.',
				section: 'CaseStudyInstructions',
				hasNotes: false,
				helperText: '',
				maxScore: 0,
				order: 1,
				referenceID: '',
			},
			{
				id: 'OrchardPlaza2023CaseStudy1',
				format: 'TimedMultipleChoice',
				questionText:
					'In setting up a financial model for the above asset, how would you calculate/enter the property management fee expense?',
				section: 'CaseStudy',
				hasNotes: true,
				helperText: '',
				maxScore: 15,
				order: 1,
				referenceID: 'RetailOrchardPlaza20231',
				questionAnswers: [
					{
						answerText: '4.0% of Effective Gross Revenue',
						score: 5,
						isCorrect: false,
					},
					{
						answerText: '5.51% of Rental Revenue',
						score: 0,
						isCorrect: false,
					},
					{
						answerText: '$1.58 / Rentable Area',
						score: 0,
						isCorrect: false,
					},
					{
						answerText: '$54,831 per annum',
						score: 0,
						isCorrect: false,
					},
				],
				scoringTags: [
					{
						tag: 'FleschKincaid',
						data: 'none',
						dataMapping: 'CommunicationSkills',
						maxScore: 10,
					},
					{
						tag: 'CaseStudyTMCMapRE',
						data: 'none',
						dataMapping: 'REKnowledge',
						maxScore: 5,
					},
				],
			},
			{
				id: 'OrchardPlaza2023CaseStudy2',
				format: 'TimedMultipleChoice',
				questionText:
					'Based on the information provided, what management fee/administration fee would you model to recover from the tenants?',
				section: 'CaseStudy',
				hasNotes: true,
				helperText: '',
				maxScore: 15,
				order: 2,
				referenceID: 'RetailOrchardPlaza20232',
				questionAnswers: [
					{
						answerText: '4.0% of Effective Gross Revenue',
						score: 0,
						isCorrect: false,
					},
					{
						answerText: '15% of all Property Expenses',
						score: 0,
						isCorrect: false,
					},
					{
						answerText: '15% of Recoverable Operating Expenses',
						score: 0,
						isCorrect: false,
					},
					{
						answerText: 'Insufficient information to determine',
						score: 5,
						isCorrect: false,
					},
				],
				scoringTags: [
					{
						tag: 'FleschKincaid',
						data: 'none',
						dataMapping: 'CommunicationSkills',
						maxScore: 10,
					},
					{
						tag: 'CaseStudyTMCMapRE',
						data: 'none',
						dataMapping: 'REKnowledge',
						maxScore: 5,
					},
				],
			},
			{
				id: 'OrchardPlaza2023CaseStudy3',
				format: 'TimedMultipleChoice',
				questionText:
					'From the budget expenses, which expenses are excluded from the cash flow report when completing a discounted cash flow model of a property?',
				section: 'CaseStudy',
				hasNotes: true,
				helperText: '',
				maxScore: 15,
				order: 3,
				referenceID: 'RetailOrchardPlaza20233',
				questionAnswers: [
					{
						answerText: 'Professional Fees',
						score: 0,
						isCorrect: false,
					},
					{
						answerText: 'Wages & Benefits',
						score: 0,
						isCorrect: false,
					},
					{
						answerText: 'Deferred Amortization (P&I)',
						score: 5,
						isCorrect: false,
					},
					{
						answerText: 'Professional Fees & Deferred Amortization (P&I)',
						score: 0,
						isCorrect: false,
					},
				],
				scoringTags: [
					{
						tag: 'FleschKincaid',
						data: 'none',
						dataMapping: 'CommunicationSkills',
						maxScore: 10,
					},
					{
						tag: 'CaseStudyTMCMapRE',
						data: 'none',
						dataMapping: 'REKnowledge',
						maxScore: 5,
					},
				],
			},
			{
				id: 'OrchardPlaza2023CaseStudy4',
				format: 'TimedMultipleChoice',
				questionText:
					'If the model has a market leasing assumption that carries a 75% renewal probability with 6 months of lag time for a new deal, when would the first leasing related costs appear in the cash flow?',
				section: 'CaseStudy',
				hasNotes: true,
				helperText: '',
				maxScore: 15,
				order: 4,
				referenceID: 'RetailOrchardPlaza20234',
				questionAnswers: [
					{
						answerText: 'January 2025',
						score: 0,
						isCorrect: false,
					},
					{
						answerText: 'February 2025',
						score: 0,
						isCorrect: false,
					},
					{
						answerText: 'March 2025',
						score: 5,
						isCorrect: false,
					},
					{
						answerText: 'July 2025',
						score: 0,
						isCorrect: false,
					},
				],
				scoringTags: [
					{
						tag: 'FleschKincaid',
						data: 'none',
						dataMapping: 'CommunicationSkills',
						maxScore: 10,
					},
					{
						tag: 'CaseStudyTMCMapRE',
						data: 'none',
						dataMapping: 'REKnowledge',
						maxScore: 5,
					},
				],
			},
			{
				id: 'OrchardPlaza2023CaseStudy5',
				format: 'Memo',
				questionText:
					'Prepare a four-paragraph memo to your Manager outlining, based on your initial review of Orchard Plaza and market knowledge, what you ' +
					'think the strengths and weaknesses are of acquiring the plaza.',
				section: 'CaseStudy',
				hasNotes: false,
				helperText: '',
				maxScore: 10,
				order: 5,
				referenceID: 'RetailOrchardPlaza20235',
				scoringTags: [
					{
						tag: 'FleschKincaid',
						data: 'none',
						dataMapping: 'CommunicationSkills',
						maxScore: 10,
					},
				],
			},
		],
	},
]
