import React, { useState, useContext } from 'react'
import { AccountContext } from './Account'
import { TextField, Typography } from '@mui/material'
import styles from './Account.module.css'
import BlueButton from '../Button/BlueButton'

function SetUpPassword({ user }) {
	const { setLoginStage } = useContext(AccountContext)
	const [newPassword, setNewPassword] = useState('')
	const [confirmNewPassword, setConfirmNewPassword] = useState('')
	const [noMatchError, setNoMatchError] = useState(false)

	const onSubmit = (e) => {
		if (newPassword !== confirmNewPassword) {
			setNoMatchError(true)
			return
		}

		user.completeNewPasswordChallenge(newPassword, undefined, {
			onSuccess: (result) => {
				setLoginStage('LoggedIn')
				console.log(result)
			},
			onFailure: (err) => {
				console.error(err)
			},
		})
	}

	return (
		<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
			<Typography className={styles['instruction-text']}>
				It seems this is your first time logging into your account. Please create a new password.
			</Typography>
			<TextField
				className={styles['text-input']}
				value={newPassword}
				type='password'
				error={noMatchError}
				onChange={(e) => {
					setNewPassword(e.target.value)
					if (noMatchError) {
						setNoMatchError(false)
					}
				}}
				helperText='New Password'
			/>

			<TextField
				className={styles['text-input']}
				value={confirmNewPassword}
				type='password'
				error={noMatchError}
				onChange={(e) => {
					setConfirmNewPassword(e.target.value)
					if (noMatchError) {
						setNoMatchError(false)
					}
				}}
				helperText='Confirm New Password'
			/>

			{noMatchError && <Typography style={{ color: 'red', fontSize: 14, paddingBottom: 20 }}>Passwords don't match.</Typography>}

			<div style={{ padding: 20 }}>
				<BlueButton className={styles['submit-button']} onClick={onSubmit}>
					Login
				</BlueButton>
			</div>
		</div>
	)
}

export default SetUpPassword
