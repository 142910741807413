import React, { useState, useEffect, useContext } from 'react'
import styles from '../Candidates.module.css'
import testStyles from '../../Test.module.css'
import { useSelector } from 'react-redux'

import { ResponsiveContainer, BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Bar, Legend } from 'recharts'
import { TestContext } from '../../TestContext'
import { CandidatesContext } from '../CandidatesContext'
import { Card, Paper } from '@mui/material'

function getCSQuestions(questions) {
	const csQuestions = []

	for (const question of questions) {
		if (question.section === 'CaseStudy') {
			csQuestions.push(question)
		}
	}

	csQuestions.sort(function (a, b) {
		return a.order - b.order
	})

	return csQuestions
}

function getUserCSData(responses, csQuestions, averageCSScores) {
	const data = []
	let questionIndex = 1

	if (!csQuestions) return

	for (const question of csQuestions) {
		const dataPoint = {
			name: 'Q' + questionIndex.toString(),
			score: 0,
			averageScore: averageCSScores[question.id],
		}
		questionIndex += 1

		if (responses) {
			for (const response of responses) {
				if (response.questionID === question.id) {
					dataPoint.score = response.score
					break
				}
			}
		}

		data.push(dataPoint)
	}

	return data
}

const CustomTooltip = ({ active, payload }) => {
	if (active && payload && payload.length) {
		return (
			<Paper
				style={{
					background: 'white',
					padding: '15px 25px',
					textAlign: 'left',
				}}
				elevation={5}
			>
				<b>Score: </b>
				<span style={{ marginLeft: 5 }}>{`${payload[0].payload['score']}`}</span> <br />
				<b>Average Score: </b>
				<span style={{ marginLeft: 5 }}>{`${Math.round(payload[0].payload['averageScore'] * 100) / 100}`}</span>
			</Paper>
		)
	}

	return null
}

function CaseStudyGraph({ usePDFVersion }) {
	const { test, averageCSScores } = useContext(TestContext)
	const { candidate } = useContext(CandidatesContext)

	const questionLists = useSelector((state) => state.questionLists)
	const allResponses = useSelector((state) => state.allUserResponses)
	const [data, setData] = useState()

	useEffect(() => {
		setData(getUserCSData(allResponses[candidate.userID], getCSQuestions(questionLists[test.id]), averageCSScores))

		// eslint-disable-next-line
	}, [test, candidate])

	return (
		<Card
			className={`${styles['graph-container']} ${usePDFVersion ? 'pdf-item' : ''}`}
			style={usePDFVersion ? { width: 'calc(80% - 80px)', boxShadow: 'none' } : {}}
		>
			<span className={styles['container-label']} style={{ paddingBottom: 0 }}>
				Case Study
			</span>
			<span className={testStyles['container-description']} style={{ marginTop: 0 }}>
				The candidates score and average score for each case study question.
			</span>
			<ResponsiveContainer height={250} width={'100%'}>
				<BarChart
					data={data}
					margin={{
						top: 5,
						right: 30,
						left: 0,
						bottom: 5,
					}}
				>
					<CartesianGrid strokeDasharray='3 3' />
					<XAxis dataKey='name' />
					<YAxis type='number' domain={[0, 15]} />
					<Tooltip content={<CustomTooltip />} />
					<Bar name='Candidate' dataKey='score' fill='#32a846' opacity={0.9} />
					<Bar name='Average' dataKey='averageScore' fill='var(--btr-blue)' opacity={0.9} />
					<Legend />
				</BarChart>
			</ResponsiveContainer>
		</Card>
	)
}

export default CaseStudyGraph
