import React, { useContext, useState } from 'react'
import { Button, TextField, Typography } from '@mui/material'
import { AccountContext } from './Account'
import styles from './Account.module.css'
import BlueButton from '../Button/BlueButton'

function ResetPassword({ user, statusMessage }) {
	const [code, setCode] = useState('')
	const [newPassword, setNewPassword] = useState('')
	const [confirmNewPassword, setConfirmNewPassword] = useState('')
	const [error, setError] = useState('')
	const [success, setSuccess] = useState(false)
	const { setLoginStage } = useContext(AccountContext)

	const onSubmit = () => {
		if (newPassword !== confirmNewPassword) {
			setError('NoMatch')
			return
		}

		user.confirmPassword(code, newPassword, {
			onSuccess: () => {
				setSuccess(true)
				setError('')
				setTimeout(() => setLoginStage('LoggedOut'), 750)
			},
			onFailure: (err) => {
				console.error('failure', err)
				if (err.code === 'CodeMismatchException') {
					setError('CodeMismatch')
				} else if (err.code === 'InvalidPasswordException') {
					setError('InvalidPassword')
				} else if (err.code === 'LimitExceededException') {
					setError('LimitExceeded')
				}
			},
		})
	}

	return (
		<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
			<Typography className={styles['instruction-text']}>{statusMessage}</Typography>
			<TextField
				className={styles['text-input']}
				value={code}
				error={error === 'CodeMismatch'}
				onChange={(e) => {
					setCode(e.target.value)
					if (error) {
						setError('')
					}
				}}
				helperText='Verification Code'
			/>

			<TextField
				className={styles['text-input']}
				value={newPassword}
				type='password'
				error={error === 'NoMatch'}
				onChange={(e) => {
					setNewPassword(e.target.value)
					if (error) {
						setError('')
					}
				}}
				helperText='New Password'
			/>

			<TextField
				className={styles['text-input']}
				value={confirmNewPassword}
				type='password'
				error={error === 'NoMatch'}
				onChange={(e) => {
					setConfirmNewPassword(e.target.value)
					if (error) {
						setError('')
					}
				}}
				helperText='Confirm New Password'
			/>

			{error === 'NoMatch' && <Typography className={styles['error-message']}>Passwords don't match.</Typography>}
			{error === 'CodeMismatch' && <Typography className={styles['error-message']}> Invalid verification code. </Typography>}
			{error === 'InvalidPassword' && (
				<Typography className={styles['error-message']}>
					Invalid password. A password must be at least 8 characters long, have one number, and have at least one uppercase and
					lowercase letter.
				</Typography>
			)}
			{error === 'LimitExceeded' && (
				<Typography className={styles['error-message']}>Too many attempts. Please wait before trying again.</Typography>
			)}

			{success && <Typography className={styles['success-message']}>Success! Redirecting...</Typography>}

			<div style={{ display: 'flex', flexDirection: 'column' }}>
				<BlueButton className={styles['submit-button']} onClick={onSubmit}>
					Change Password
				</BlueButton>
				<Button variant='text' className={styles['small-button']} onClick={() => setLoginStage('LoggedOut')}>
					Cancel
				</Button>
			</div>
		</div>
	)
}

export default ResetPassword
