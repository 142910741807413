import { useEffect, useState } from 'react'
import { BarChart, Bar, XAxis, CartesianGrid, Tooltip, YAxis, ResponsiveContainer } from 'recharts'
import styles from './Dashboard.module.css'
import { Card, Paper } from '@mui/material'

function getLastXDays(x) {
	let time = new Date().getTime()
	const candidatesByDate = []

	for (let i = 0; i < x; i++) {
		const dateString = new Date(time).getMonth() + 1 + '/' + new Date(time).getDate()
		candidatesByDate[dateString] = 0

		time -= 86400000 // milliseconds in a day
	}

	return candidatesByDate
}

function getDateStringOfApplicant(createdAt) {
	return new Date(createdAt).getMonth() + 1 + '/' + new Date(createdAt).getDate()
}

const CustomTooltip = ({ active, payload }) => {
	if (active && payload && payload.length) {
		return (
			<Paper
				style={{
					background: 'white',
					padding: '15px 25px',
					display: 'grid',
					gridTemplateColumns: '80px 1fr',
					textAlign: 'left',
				}}
				elevation={5}
			>
				<b>Date: </b>
				<span>{`${payload[0].payload['date']}`}</span>
				<b>Amount: </b>
				<span>{`${payload[0].payload['numApplicants']}`}</span>
			</Paper>
		)
	}

	return null
}

function convertDateFormat(dateString) {
	const monthDaySplit = dateString.split('/')

	const translations = {
		1: 'Jan',
		2: 'Feb',
		3: 'March',
		4: 'April',
		5: 'May',
		6: 'June',
		7: 'July',
		8: 'Aug',
		9: 'Sept',
		10: 'Oct',
		11: 'Nov',
		12: 'Dec',
	}

	if (translations[monthDaySplit[0]] && monthDaySplit[1]) {
		return translations[monthDaySplit[0]] + ' ' + monthDaySplit[1]
	} else {
		return dateString
	}
}

function CandidateNumbersGraph({ candidates, isDashboard }) {
	const [timeMode, setTimeMode] = useState(2) // 0 = 1 year, 1 = 30 days, 2 = 7 days
	const [data, setData] = useState([])

	useEffect(() => {
		let candidatesByDate

		const buttons = document.getElementById(styles['graph-button-group']).childNodes

		for (let i = 0; i < buttons.length; i++) {
			buttons[i].className = buttons[i].className.replace(' active', '')
		}

		if (timeMode === 0) {
			document.getElementById('year').className += ' active'
			candidatesByDate = getLastXDays(365)
		} else if (timeMode === 1) {
			document.getElementById('month').className += ' active'
			candidatesByDate = getLastXDays(30)
		} else if (timeMode === 2) {
			document.getElementById('week').className += ' active'
			candidatesByDate = getLastXDays(7)
		}

		// for (const key in applicantLists) {
		// 	for (const applicant of applicantLists[key]) {
		// 		const dateString = getDateStringOfApplicant(applicant.createdAt)

		// 		if (candidatesByDate[dateString] >= 0) candidatesByDate[dateString]++
		// 	}
		// }

		for (const candidate of candidates) {
			const dateString = getDateStringOfApplicant(candidate.createdAt)
			if (candidatesByDate[dateString] >= 0) candidatesByDate[dateString]++
		}

		const newData = []

		for (const key in candidatesByDate) {
			convertDateFormat(key)
			newData.push({
				date: convertDateFormat(key),
				numApplicants: candidatesByDate[key],
			})
		}

		newData.reverse()

		setData(newData)

		// eslint-disable-next-line
	}, [timeMode, candidates])

	return (
		<Card id={styles['graph-section']} elevation={isDashboard ? 1 : 0}>
			<div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
				<div id={styles['graph-button-group']}>
					<button id='week' className='graph-button' onClick={() => setTimeMode(2)} style={timeMode === 2 ? { opacity: 1 } : {}}>
						7 Days
					</button>

					<button id='month' className='graph-button' onClick={() => setTimeMode(1)} style={timeMode === 1 ? { opacity: 1 } : {}}>
						30 Days
					</button>

					<button id='year' className='graph-button' onClick={() => setTimeMode(0)} style={timeMode === 0 ? { opacity: 1 } : {}}>
						Year
					</button>
				</div>

				<ResponsiveContainer height={'90%'} width={'100%'}>
					<BarChart
						data={data}
						margin={{
							top: 5,
							right: 30,
							left: 0,
							bottom: 5,
						}}
					>
						<CartesianGrid strokeDasharray='3 3' />
						<XAxis dataKey='date' angle={-15} textAnchor='end' />
						<YAxis type='number' tickCount={3} tickLine={false} />
						<Tooltip content={<CustomTooltip />} />
						{/* <Legend /> */}
						<Bar dataKey='numApplicants' fill='#32a846' />
					</BarChart>
				</ResponsiveContainer>
			</div>
		</Card>
	)
}

export default CandidateNumbersGraph
