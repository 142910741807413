export function calculateMaxScoringTags(testTags) {
	let maxTags = {}

	for (const tag of testTags) {
		if (tag.dataMapping === 'TechnologySkills') continue
		if (maxTags[tag.dataMapping] === undefined) {
			maxTags[tag.dataMapping] = 0.0
		}
		maxTags[tag.dataMapping] += tag.maxScore
	}

	return maxTags
}
