import React, { useContext } from 'react'
import styles from './Overview.module.css'
import dataStyles from '../../Dashboard/Dashboard.module.css'
import { TestContext } from '../TestContext'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import { ExpandMore } from '@mui/icons-material'
import { Paper } from '@mui/material'

function SelfIdentificationResponses() {
	const { test, candidateList } = useContext(TestContext)

	if (!test.selfIdentificationQuestions || !test.selfIdentificationResponses || !test.selfIdentificationResponses.items) {
		return null
	}

	const responsesBreakdown = countResponses()

	// Iterates through each survey response and determines how many people responded with each answer
	// ie how many people responded "Gender Fluid" to the question on Gender
	function countResponses() {
		const responsesBreakdown = {}

		// Initiate the object for each question/answer
		for (const question of test.selfIdentificationQuestions) {
			responsesBreakdown[question.id] = {}

			for (const answer of question.questionAnswers) {
				if (answer.startsWith('Other')) {
					responsesBreakdown[question.id]['Other'] = 0
				} else {
					responsesBreakdown[question.id][answer] = 0
				}
			}
		}

		// Iterates over all the survey responses and determines which category they fall under
		for (const surveyResponse of test.selfIdentificationResponses.items) {
			for (const response of surveyResponse.responses) {
				const { questionID, responses: questionResponses } = response

				for (const questionResponse of questionResponses) {
					// If responsesBreakdown[questionID][questionResponse] is undefined, then it must be an 'Other' response, since it would have been initiated otherwise.
					if (responsesBreakdown[questionID][questionResponse] !== undefined) {
						responsesBreakdown[questionID][questionResponse] += 1
					} else {
						responsesBreakdown[questionID]['Other'] += 1
					}
				}
			}
		}

		return responsesBreakdown
	}

	return (
		<Paper className={styles['container']} style={{ width: 'fit-content', maxWidth: '50%', marginBottom: 20 }}>
			<span className={styles['label']}>Self Identification</span>
			<span className={styles['container-description']} style={{ marginBottom: 20 }}>
				{test.selfIdentificationResponses.items.length} out of {candidateList.length} candidates (
				{Math.round((test.selfIdentificationResponses.items.length / candidateList.length) * 100)}%) chose to complete the survey.
			</span>

			<div>
				{test.selfIdentificationQuestions.map((question, index) => {
					return (
						<Accordion>
							<AccordionSummary expandIcon={<ExpandMore />}>
								<span className={dataStyles['candidates-metric-title']} style={{ textAlign: 'start' }}>
									{question.questionText}
								</span>
							</AccordionSummary>
							<AccordionDetails sx={{ paddingTop: 0 }}>
								<div style={{ display: 'grid', gridTemplateColumns: '40px 60px 1fr', fontSize: 14 }}>
									{question.questionAnswers.map((answer) => {
										const numberOfReplies = answer.startsWith('Other')
											? responsesBreakdown[question.id]['Other']
											: responsesBreakdown[question.id][answer]

										return (
											<>
												<span style={{ textAlign: 'start' }}>{numberOfReplies}</span>
												<span style={{ textAlign: 'start', opacity: 0.9 }}>
													({Math.round((numberOfReplies / test.selfIdentificationResponses.items.length) * 100)}%)
												</span>
												<span style={{ textAlign: 'start' }}>{answer}</span>
											</>
										)
									})}
								</div>
							</AccordionDetails>
						</Accordion>
					)
				})}
			</div>
		</Paper>
	)
}

export default SelfIdentificationResponses
