import { FormControl, MenuItem, Select, Typography, Divider } from '@mui/material'
import { caseStudies } from '../CaseStudies'
import { useState } from 'react'
import Keywords from '../ScoringTags/Keywords'

const styles = {
	container: {
		display: 'flex',
		height: '100%',
		width: '100%',
		alignItems: 'center',
		flexDirection: 'column',
		paddingTop: 30,
		boxSizing: 'border-box',
	},
}

function findCaseStudyByName(name) {
	for (const caseStudy of caseStudies) {
		if (caseStudy.name === name) return caseStudy
	}

	return null
}

function replaceCaseStudyQuestions(questions, newCaseStudyName) {
	for (let i = 0; i < questions.length; i++) {
		if (questions[i].section.includes('CaseStudy')) {
			questions.splice(i, 1)
			i--
		}
	}

	const caseStudy = findCaseStudyByName(newCaseStudyName)

	if (!caseStudy || !caseStudy.questions) {
		alert('Error: Could not find new case study. The questions were not added to the test.')
		return
	}

	questions.push(...caseStudy.questions)

	// console.log('Length: ', questions.length)
}

function CaseStudyPage(props) {
	const [currCaseStudy, setCurrCaseStudy] = useState(findCaseStudyByName(props.testInfo.caseStudyName))

	return (
		<div style={styles.container}>
			<Typography textAlign='left' maxWidth='600px'>
				Select one of the following case studies. While this section accounts for the bulk of the candidates' overall score, you
				have the option to calibrate this weighting later on. Each question includes a notes section where a candidate is instructed
				to provide their answer rationale. You can add keywords to each question for additional scoring.
			</Typography>

			<Divider sx={{ width: '600px', margin: '30px 0' }}></Divider>

			<div style={{ width: '600px', display: 'flex' }}>
				<FormControl>
					<Select
						value={props.testInfo.caseStudyName}
						onChange={(e) => {
							let newState = Object.assign({}, props.testInfo)

							newState.caseStudyName = e.target.value
							replaceCaseStudyQuestions(newState.questions, e.target.value)

							props.setTestInfo(newState)
							setCurrCaseStudy(findCaseStudyByName(e.target.value))
						}}
						style={{ width: 300, textAlign: 'start', fontSize: 16 }}
					>
						{caseStudies.map((caseStudy) => {
							return (
								<MenuItem value={caseStudy.name} key={caseStudy.name}>
									{caseStudy.name}
								</MenuItem>
							)
						})}
					</Select>
				</FormControl>
			</div>

			<div style={{ width: 600, padding: 50 }}>
				<Typography style={{ fontSize: 18, textAlign: 'start', fontWeight: 'bold' }}>
					Now we'd like you to complete a short assignment
				</Typography>

				<Typography style={{ fontSize: 14, textAlign: 'start', margin: '15px 0 15px 0', whiteSpace: 'pre-line' }}>
					{currCaseStudy.questions[0].questionText}
				</Typography>

				<Typography style={{ fontSize: 18, textAlign: 'start', fontWeight: 'bold' }}>Instructions</Typography>

				<Typography style={{ fontSize: 14, textAlign: 'start', paddingBottom: 15, paddingTop: 15, whiteSpace: 'pre-line' }}>
					{currCaseStudy.questions[1].questionText}
				</Typography>

				{currCaseStudy.questions.map((question, index) => {
					if (index === 0 || index === 1) return <div key={index} />
					return (
						<div key={index}>
							<Typography style={{ marginTop: 30, fontSize: 16, textAlign: 'start' }}>Question {index - 1}</Typography>
							<Typography style={{ fontSize: 14, textAlign: 'start', marginTop: 10, marginBottom: 20 }}>
								{question.questionText}
							</Typography>
							{question.questionAnswers &&
								question.questionAnswers.map((questionAnswer) => (
									<Typography style={{ fontSize: 14, fontWeight: 200, textAlign: 'start', marginTop: 6 }}>
										{`(${questionAnswer.score} pts)`} - {questionAnswer.answerText}
									</Typography>
								))}
							<Keywords testInfo={props.testInfo} setTestInfo={props.setTestInfo} questionID={question.id} />
						</div>
					)
				})}
			</div>
		</div>
	)
}

export default CaseStudyPage
