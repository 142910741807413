import React, { useContext, useEffect, useState } from 'react'

import { CandidatesContext } from '../CandidatesContext'
import { TestContext } from '../../TestContext'

import { useSelector } from 'react-redux'

import { createSortedResponses } from '../../../../lib/candidates'
import { getDetailedSectionName } from '../../../../lib/utility'

function ResponsesPDF() {
	const { candidate } = useContext(CandidatesContext)
	const { test } = useContext(TestContext)
	const [tables, setTables] = useState([])

	const questionLists = useSelector((state) => state.questionLists)
	const allUserResponses = useSelector((state) => state.allUserResponses)

	useEffect(() => {
		setTables(createSortedResponses(allUserResponses[candidate.userID], questionLists, test.id))

		// eslint-disable-next-line
	}, [])

	return (
		<>
			{Object.keys(tables).map((key) => {
				return (
					<div
						style={{
							width: 'calc(100% - 80px)',
							display: 'flex',
							background: 'white',
							flexDirection: 'column',
							marginBottom: 10,
							padding: '10px 20px 0px 20px',
							borderRadius: 10,
							textAlign: 'left',
						}}
						key={key}
					>
						{tables[key].map((row, index) => {
							return (
								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										gap: 5,
										fontSize: 14,
										padding: '20px 20px 25px 20px',
									}}
									className='pdf-item'
									key={index}
								>
									{index === 0 && <b style={{ fontSize: 18, padding: '20px 0' }}>{getDetailedSectionName(key)}</b>}
									<span style={{ fontStyle: 'italic', fontWeight: 'var(--bold)', color: 'rgb(60, 60, 60)' }}>
										{row.question}
									</span>
									<span
										style={{
											fontWeight: 'var(--semi-bold)',
											fontSize: 14,
											color: 'rgb(60, 60, 60)',
											marginLeft: 'auto',
										}}
									>{`${row.score} / ${row.maxScore}`}</span>
									<span style={{ marginLeft: 10 }}>{row.response}</span>
									{row.notes && (
										<span style={{ marginLeft: 10 }}>
											<em style={{ color: 'rgb(60, 60, 60)' }}>Notes:</em>
											<br /> {row.notes}
										</span>
									)}
								</div>
							)
						})}
					</div>
				)
			})}
		</>
	)
}

export default ResponsesPDF
