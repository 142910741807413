import React from 'react'
import styles from './Slider.module.css'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined'
import { Slider as MuiSlider } from '@mui/material'

import { convertDataMappingName } from '../../../../functions/CalculationFunctions'

// function getColor(label) {
// 	switch (label) {
// 		case 'AttentionToDetail':
// 			return '#A52A2A'
// 		case 'CommunicationSkills':
// 			return '#E3963E'
// 		case 'PositionRequirements':
// 			return '#256D1B'
// 		case 'REKnowledge':
// 			return '#2B4570'
// 		default:
// 			return '#001940'
// 	}
// }

function Slider({ item, setValue, setLocked }) {
	return (
		<li>
			<span
				style={{
					display: 'flex',
					width: '300px',
					justifyContent: 'space-between',
					marginBottom: 5,
					paddingRight: 50,
				}}
			>
				<label htmlFor={item.label} style={{ fontWeight: 'bold' }}>
					{convertDataMappingName(item.label)}
				</label>
				<span style={{ fontWeight: 'bold' }}>{item.value}%</span>
			</span>
			<span style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
				<MuiSlider
					id={item.label}
					min={0}
					max={100}
					step={1}
					value={item.value}
					onChange={(e) => setValue(parseInt(e.target.value))}
					disabled={item.locked}
				/>
				<button onClick={setLocked} className={styles['lock-button']}>
					{item.locked ? (
						<LockOutlinedIcon style={{ height: 30, width: 30 }} />
					) : (
						<LockOpenOutlinedIcon style={{ height: 30, width: 30 }} />
					)}
				</button>
			</span>
		</li>
	)
}

export default Slider
