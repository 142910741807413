import { Typography } from '@mui/material'
import { useEffect, useRef } from 'react'
import { onTabPress, findQuestionText, createJobInfoQuestions } from '../Functions'

const styles = {
	container: {
		display: 'flex',
		height: '100%',
		width: '100%',
		alignItems: 'center',
		flexDirection: 'column',
	},
	inputContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'start',
		margin: '20px 0px 20px 0px',
	},
	inputBox: {
		minHeight: 150,
		width: 500,
		borderRadius: '0.5rem',
		padding: '5px',
		fontSize: 14,
		resize: 'vertical',
	},
	emptyDiv: {
		height: 50,
		color: 'white',
	},
}

function JobDescriptionPage(props) {
	const refs = {
		AboutThisOpportunity: useRef(),
		Overview: useRef(),
		Responsibilities: useRef(),
		KeySkills: useRef(),
	}

	function updateQuestion(id, value) {
		let newState = Object.assign({}, props.testInfo)

		for (let question of newState.questions) {
			if (question.id === id) {
				question.questionText = value
				props.setTestInfo(newState)
				return
			}
		}
	}

	useEffect(() => {
		const ids = ['AboutThisOpportunity', 'Overview', 'Responsibilities', 'KeySkills']

		// Search to see we have already created the job info questions
		for (const question of props.testInfo.questions) {
			if (ids.includes(question.id)) return
		}

		// Create the job info questions if we have not done so already
		createJobInfoQuestions(props.testInfo, props.setTestInfo)

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<div style={styles.container}>
			<div style={styles.inputContainer}>
				<Typography textAlign='left' mb={1} fontWeight='600'>
					About This Opportunity
				</Typography>
				<textarea
					style={styles.inputBox}
					defaultValue={findQuestionText('AboutThisOpportunity', props.testInfo.questions)}
					onBlur={(e) => {
						updateQuestion('AboutThisOpportunity', e.target.value)
					}}
					ref={refs['AboutThisOpportunity']}
					onKeyDown={(e) => {
						if (e.key === 'Tab') {
							onTabPress(refs['AboutThisOpportunity'], e)
						}
					}}
				/>
			</div>

			<div style={styles.inputContainer}>
				<Typography textAlign='left' mb={1} fontWeight='600'>
					Overview
				</Typography>
				<textarea
					style={styles.inputBox}
					defaultValue={findQuestionText('Overview', props.testInfo.questions)}
					onBlur={(e) => {
						updateQuestion('Overview', e.target.value)
					}}
					ref={refs['Overview']}
					onKeyDown={(e) => {
						if (e.key === 'Tab') {
							onTabPress(refs['Overview'], e)
						}
					}}
				/>
			</div>

			<div style={styles.inputContainer}>
				<Typography textAlign='left' mb={1} fontWeight='600'>
					Responsibilities
				</Typography>
				<textarea
					style={styles.inputBox}
					defaultValue={findQuestionText('Responsibilities', props.testInfo.questions)}
					onBlur={(e) => {
						updateQuestion('Responsibilities', e.target.value)
					}}
					ref={refs['Responsibilities']}
					onKeyDown={(e) => {
						if (e.key === 'Tab') {
							onTabPress(refs['Responsibilities'], e)
						}
					}}
				/>
			</div>

			<div style={styles.inputContainer}>
				<Typography textAlign='left' mb={1} fontWeight='600'>
					Key Required Skills and Experience
				</Typography>
				<textarea
					style={styles.inputBox}
					defaultValue={findQuestionText('KeySkills', props.testInfo.questions)}
					onBlur={(e) => {
						updateQuestion('KeySkills', e.target.value)
					}}
					ref={refs['KeySkills']}
					onKeyDown={(e) => {
						if (e.key === 'Tab') {
							onTabPress(refs['KeySkills'], e)
						}
					}}
				/>
			</div>

			<div style={styles.emptyDiv}>I must be bad at CSS</div>
		</div>
	)
}

export default JobDescriptionPage
