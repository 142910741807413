import React from 'react'
import AddFilter from './AddFilter/AddFilter'
import Filter from './Filter'

/**
 * This component handles all filters for a table.
 *
 * @param {*} filterList - The list of filters. This is passed in so that the filters stay after navigating away from the component
 * @param {*} setFilterList - setState function to update the list of filters
 */
function FilterBar({ filterList, setFilterList }) {
	// Adds a filter to the filterList
	function addFilter(newFilter) {
		setFilterList((filterList) => {
			return [...filterList, newFilter]
		})
	}

	// Removes a filter from the list
	function removeFilter(category, condition, value) {
		const newFilterList = [...filterList]

		for (let i = 0; i < newFilterList.length; i++) {
			const currFilter = newFilterList[i]
			if (currFilter.category === category && currFilter.condition === condition && currFilter.value === value) {
				newFilterList.splice(i, 1)
				break
			}
		}

		setFilterList(newFilterList)
	}

	return (
		<div
			style={{
				display: 'flex',
				gap: 20,
				alignItems: 'center',
				paddingRight: 20,
				justifyContent: 'flex-start',
				fontWeight: 'var(--bold)',
				flexWrap: 'wrap',
			}}
		>
			<AddFilter addFilter={addFilter} />
			{filterList.map((filter, index) => {
				return <Filter {...filter} key={index} removeFilter={removeFilter} />
			})}
		</div>
	)
}

export default FilterBar
