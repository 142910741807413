import React, { useEffect, useState } from 'react'
import { Select, InputLabel, FormControl, MenuItem } from '@mui/material'

// Allows a user to select a time between 00:00 and 04:59 (hours:minutes)
function TimeDurationInput({ setNewFilterValue }) {
	const [hours, setHours] = useState(0)
	const [minutes, setMinutes] = useState(0)

	useEffect(() => {
		setNewFilterValue(hours * 3600000 + minutes * 60000)

		// eslint-disable-next-line
	}, [hours, minutes])

	return (
		<div style={{ display: 'flex', gap: 20 }}>
			<FormControl style={{ width: 70 }}>
				<InputLabel id='select-hours-label'>Hours</InputLabel>
				<Select
					labelId='select-hours-label'
					label='Age'
					value={hours}
					onChange={(e) => setHours(e.target.value)}
					data-cy='hour-selector'
				>
					<MenuItem value={0}>0</MenuItem>
					<MenuItem value={1}>1</MenuItem>
					<MenuItem value={2}>2</MenuItem>
					<MenuItem value={3}>3</MenuItem>
					<MenuItem value={4}>4</MenuItem>
				</Select>
			</FormControl>
			<FormControl style={{ width: 90 }}>
				<InputLabel id='select-minutes-label'>Minutes</InputLabel>
				<Select
					labelId='select-minutes-label'
					label='Age'
					value={minutes}
					onChange={(e) => setMinutes(e.target.value)}
					data-cy='minute-selector'
				>
					{Array.from({ length: 60 }, (_, i) => i).map((index) => {
						return (
							<MenuItem value={index} key={index}>
								{index}
							</MenuItem>
						)
					})}
				</Select>
			</FormControl>
		</div>
	)
}

export default TimeDurationInput
