import React, { useContext, useEffect, useState } from 'react'
import BannerMenuButton from '../../../../MenuButton/BannerMenuButton'
import styles from '../../Candidates.module.css'
import AddIcon from '@mui/icons-material/Add'
import { CandidatesContext } from '../../CandidatesContext'
import { useSelector } from 'react-redux'
import AddCommentMenu from './AddCommentMenu'
import { parseAWSDateTimeWithYear } from '../../../../../functions/CalculationFunctions'
import { Paper } from '@mui/material'

function Comments() {
	const { candidate } = useContext(CandidatesContext)
	const client = useSelector((state) => state.client)

	const [showAddMenu, setShowAddMenu] = useState(false)
	const [comments, setComments] = useState([])

	useEffect(() => {
		if (!candidate || !candidate.clientComments) {
			setComments([])
			return
		}
		setComments(
			candidate.clientComments.sort((a, b) => {
				return new Date(b.date).getTime() - new Date(a.date).getTime()
			})
		)
	}, [candidate])

	return (
		<Paper
			style={{
				display: 'flex',
				flexDirection: 'column',
				background: 'white',
				padding: 20,
				gap: 20,
				width: '45%',
				paddingBottom: 30,
			}}
		>
			<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
				<span className={styles['container-label']} style={{ paddingBottom: 0 }}>
					Comments
				</span>

				<BannerMenuButton
					customclasses={styles['candidate-section-button']}
					onClick={() => {
						setShowAddMenu(true)
					}}
				>
					Add Comment <AddIcon style={{ width: 25, height: 25, opacity: 0.8, color: 'black', marginLeft: 5 }} />
				</BannerMenuButton>
			</div>

			{comments.length > 0 ? (
				<div className={styles['comments-container']}>
					{comments.map((comment) => {
						return (
							<div className={styles['comment']}>
								<div style={{ display: 'flex', gap: 40 }}>
									<span style={{ opacity: 0.9, fontWeight: 'var(--bold)', fontSize: 14, color: '#001940' }}>
										{client.id === comment.createdBy.id ? 'You' : comment.createdBy.name}
									</span>
									<span style={{ fontSize: 14, color: '#001940' }}>{parseAWSDateTimeWithYear(comment.date)}</span>
								</div>
								<p>{comment.comment}</p>
							</div>
						)
					})}
				</div>
			) : (
				<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '20px 0 50px', width: '100%' }}>
					No comments.
				</div>
			)}

			{showAddMenu && <AddCommentMenu onClose={() => setShowAddMenu(false)} />}
		</Paper>
	)
}

export default Comments
