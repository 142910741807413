import styles from './Search.module.css'
import SearchIcon from '@mui/icons-material/Search'
import { TextField } from '@mui/material'

// Simple component to provide a search field. Filtering based on the searchValue is handled in the parent component.
function SearchBar({ searchValue, setSearchValue }) {
	return (
		<div className={styles['search-container']} style={{ flexShrink: 0 }}>
			<SearchIcon style={{ color: 'var(--faded-black)' }} />
			<span style={{ color: 'var(--faded-black)', fontWeight: 'var(--semi-bold)' }}>Search by name:</span>
			<TextField
				style={{ fontSize: 14 }}
				value={searchValue}
				onChange={(e) => {
					setSearchValue(e.target.value)
				}}
			/>
		</div>
	)
}

export default SearchBar
