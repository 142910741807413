import React from 'react'
import { Box, ToggleButtonGroup, ToggleButton } from '@mui/material'

export default function YesNoToggle({ toggleValue, handleToggle, label }) {
	return (
		<Box display='flex' flexDirection='row' gap={2} alignItems='center'>
			{label}
			<ToggleButtonGroup value={toggleValue} exclusive onChange={handleToggle} aria-label='text alignment' size='small'>
				<ToggleButton
					value={true}
					aria-label='left aligned'
					sx={{ textTransform: 'none', color: 'rgb(40, 40, 40)' }}
					style={{ backgroundColor: toggleValue === true ? '#32a84666' : 'transparent' }}
					disableRipple
				>
					Yes
				</ToggleButton>
				<ToggleButton
					value={false}
					aria-label='right aligned'
					sx={{ textTransform: 'none', color: 'rgb(40, 40, 40)' }}
					style={{ backgroundColor: toggleValue === false ? '#d1331366' : 'transparent' }}
					disableRipple
				>
					No
				</ToggleButton>
			</ToggleButtonGroup>
		</Box>
	)
}
