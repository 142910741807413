import React from 'react'

import { Button } from '@mui/material'

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import SortIcon from '@mui/icons-material/ImportExport'

function updateOtherSorting(sortedTag, sortingData) {
	for (const key in sortingData) {
		if (key !== sortedTag) {
			sortingData[key] = 'none'
		}
	}
}

function SortableColumn({ columnTitle, sortAscFunc, sortDscFunc, origRows, setRows, sortingData, sortingKey }) {
	return (
		<Button
			style={{ textTransform: 'none', color: 'black', position: 'relative', fontWeight: 'var(--semi-bold)' }}
			onClick={() => {
				const newRows = [...origRows]

				if (sortingData[sortingKey] === 'ascending') {
					sortingData[sortingKey] = 'none'
				} else if (sortingData[sortingKey] === 'descending') {
					sortingData[sortingKey] = 'ascending'
					newRows.sort(sortAscFunc)
				} else {
					newRows.sort(sortDscFunc)
					sortingData[sortingKey] = 'descending'
				}
				updateOtherSorting(sortingKey, sortingData)
				setRows(newRows)
			}}
		>
			{columnTitle}
			{sortingData[sortingKey] === 'ascending' && <ArrowUpwardIcon style={{ height: 20, width: 20, marginLeft: 5 }} />}
			{sortingData[sortingKey] === 'descending' && <ArrowDownwardIcon style={{ height: 20, width: 20, marginLeft: 5 }} />}
			{sortingData[sortingKey] === 'none' && <SortIcon style={{ height: 20, width: 20, marginLeft: 5, opacity: 0.75 }} />}
		</Button>
	)
}

export default SortableColumn
