export function convertToHMSTimeString(time) {
	let numHours = parseInt(time / 3600000)

	time = time - numHours * 3600000

	let numMinutes = parseInt(time / 60000)

	time = time - numMinutes * 60000

	let numSeconds = parseInt(time / 1000)

	if (numHours > 0) return numHours.toString() + 'h ' + numMinutes.toString() + 'm ' + numSeconds.toString() + 's'
	else return numMinutes.toString() + 'm ' + numSeconds.toString() + 's'
	// console.log(timeString)
}

export function convertToHMTimeString(time) {
	let numHours = parseInt(time / 3600000)

	time = time - numHours * 3600000

	let numMinutes = parseInt(time / 60000)

	time = time - numMinutes * 60000

	// Rounds up
	if (time > 30000) numMinutes += 1

	// Fix hours and minutes if we round up when we are at 59m. For example, rounding up from '1hr 59m', we want '2h 0m' not '1hr 60m'
	if (numMinutes === 60) {
		numHours++
		numMinutes = 0
	}

	if (numHours > 0) return numHours.toString() + 'h ' + numMinutes.toString() + 'm'
	else return numMinutes.toString() + 'm'
}

/**
 *
 * @param {[Object]} candidates - A list of the candidates for the test
 * @param {AWSDate} candidate.createdAt - The AWS Date string of when the candidate applied
 * @returns {Object} AN object containing the number of applications this month, this week, and today
 */
export function countCandidatesInDayWeekMonth(candidates) {
	const numbers = { today: 0, week: 0, month: 0 }
	const currDate = new Date()

	for (const candidate of candidates) {
		const candidateDate = new Date(candidate.createdAt)

		if (currDate.getFullYear() === candidateDate.getFullYear() && currDate.getMonth() === candidateDate.getMonth()) {
			numbers.month++

			if (currDate.getDate() === candidateDate.getDate()) numbers.today++
		}

		if (isThisWeek(currDate, candidateDate)) numbers.week++
	}
	return numbers
}

/**
 *
 * @param {Date} currDate - The current date
 * @param {Date} compareDate - The date we want to check whether it is in this week. Always earlier in time than currDate
 * @returns {boolean} True if the dates are in the same week, false otherwise
 */
export function isThisWeek(currDate, compareDate) {
	// First check to make sure the compareDate is within 7 days of the current date.
	// If it is, then we check days of the week. Since compareDate must be before the currentDate, it has to be before it
	// when we check getDay(). If it is not before it, then it must be in the previous week.
	if (currDate.getTime() - compareDate.getTime() < 604800000 && currDate.getDay() >= compareDate.getDay()) {
		return true
	}
	return false
}
